<template>
  <v-parallax dark src="@/assets/images/buildingpermit.jpg" style="height: 1120px; width: 100%">
    <v-row align="center" justify="center">
      <v-col class="text-center" cols="12">
        <v-card :loading="loading" class="mx-auto my-12" width="600px">
          <template slot="progress">
            <v-progress-linear color="deep-purple" height="10" indeterminate></v-progress-linear>
          </template>

          <v-img
            width="300px"
            style="margin: 150px 150px 0px; margin-top: -200px"
            :src="require('@/assets/images/nepal-govt.png')"
          ></v-img>

          <h4 class="subheading">Building & Infrastructure Inventory</h4>

          <v-card-text>
            <div>
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="username"
                  label="Username"
                  prepend-icon="mdi-account"
                  placeholder="Please enter the username"
                  outlined
                ></v-text-field>
                <v-text-field
                  :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                  :rules="[rules.required, rules.min]"
                  :type="show3 ? 'text' : 'password'"
                  name="password"
                  label="Password"
                  placeholder="Please enter the password"
                  v-model="password"
                  prepend-icon="mdi-lock"
                  class="input-group--focused"
                  @click:append="show3 = !show3"
                  v-on:keydown.enter="authenticate"
                  outlined
                ></v-text-field>

                <v-checkbox v-model="checkbox" label="Remember me" required></v-checkbox>
                <v-row wrap justify="center" style="padding-bottom: 10px">
                  <v-btn color="primary" class="mr-4" v-on:click="authenticate" large>
                    Login
                  </v-btn>
                  <v-btn color="error" class="mr-4" @click="reset" large> Cancel </v-btn>
                </v-row>

                <v-row align="center" justify="center">
                  <v-col cols="12" sm="12" md="8">
                    <span v-if="message">
                      <v-alert dense outlined type="error">{{ message }}</v-alert>
                    </span>
                  </v-col>
                </v-row>
              </v-form>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-parallax>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Login",
  components: {},
  data() {
    return {
      username: "",
      password: "",
      token: "",
      message: "",
      user: window.localStorage.getItem("user"),
      is_municipal_staff: window.localStorage.getItem("is_municipal_staff"),
      ward_no: window.localStorage.getItem("ward_no"),
      is_bps_access: window.localStorage.getItem("is_bps_access"),
      is_bids_access: window.localStorage.getItem("is_bids_access"),
      isLoading: false,
      loading: false,
      selection: 1,
      show3: false,
      rules: {
        required: (value) => !!value || "Required.",
        min: (v) => v.length >= 8 || "Min 8 characters",
        emailMatch: () => `The email and password you entered don't match`,
      },
    };
  },

  mounted() {},

  methods: {
    reserve() {
      this.loading = true;

      setTimeout(() => (this.loading = false), 2000);
    },
    authenticate() {
      // debugger;
      this.isLoading = true;
      const url = "api/login";
      VueAxios.post(url, { username: this.username, password: this.password, remember: true })
        .then((response) => {
          this.isLoading = false;
          if (response.status === 200) {
            this.token = response.data.access_token;
            window.localStorage.clear();
            window.localStorage.setItem("auth_user_role_id", response.data.data.user_role_id);
            window.localStorage.setItem("is_bids_access", response.data.data.is_bids_access);
            window.localStorage.setItem("is_bps_access", response.data.data.is_bps_access);
            window.localStorage.setItem("ward_no", response.data.data.ward_no);
            window.localStorage.setItem(
              "is_municipal_staff",
              response.data.data.is_municipal_staff
            );
            // window.localStorage.setItem('auth_user_role_id', response.data.data.user_role_id);
            window.localStorage.setItem("token", response.data.access_token);
            window.localStorage.setItem("user", JSON.stringify(response.data.data));

            const obj = this;

            VueAxios.defaults.headers = {
              // "X-Requested-With": "XMLHttpRequest",
              "Access-Control-Allow-Origin": "*",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };

            const currentuserUrl = "api/profile";
            VueAxios.get(
              currentuserUrl,
              {
                headers: {
                  "X-Requested-With": "XMLHttpRequest",
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              },
              {
                timeout: 100000,
              }
            ).then((responseUser) => {
              this.user = responseUser.data;
              window.localStorage.setItem("user", this.user);
              if (
                (this.user.is_bids_access == 1 && this.user.is_bps_access == 1) ||
                this.user.is_bids_access == 1
              ) {
                this.$router.push({ name: "dashboard" });
              } else {
                this.$router.push({ name: "home" });
              }
            });
            //             if(this.user.is_bids_access == 1 && this.user.is_bps_access == 1) {
            //               this.$router.push({ name: "dashboard"});
            //             }
            //             else{
            //  this.$router.push({ name: "home" });
            //             }
          }
        })
        .catch(() => {
          this.isLoading = false;
          this.message = "Either the username or password is incorrect. Please try again!";
        });
      // .catch(error => this.error = error.response.data.errors);
    },
  },
};
</script>
