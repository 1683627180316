import ax from "axios";
import axiosRetry from "axios-retry";
// import router from '@/router';

const httpAxios = ax.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}`,
  headers: {
    //"X-Requested-With": "XMLHttpRequest",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Headers": "Origin, Methods, Content-Type",
    "Access-Control-Allow-Credentials": true,
    Authorization: `Bearer ${localStorage.getItem("token")}`
  }
});
httpAxios.defaults.timeout = 200000;
axiosRetry(httpAxios, {
  retries: 3,
  // retry on Network Error & 5xx responses
  retryCondition: axiosRetry.isRetryableError
}); // retry on 500 error from server. file_put_contents error appearing occasionally

export const VueAxios = httpAxios;

export default {
  install(Vue) {
    Vue.prototype.$axios = httpAxios;
  }
};

/* eslint-enable */
