<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}
.document td,
.document th {
  padding: 8px;
}
.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
.forward_button {
  background-color: #008cba;
}
</style>
<template>
  <div id="app">
    <GeneralInfo :regId="this.$route.params.id" />
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import GeneralInfo from "../subComponents/GeneralInfo.vue";

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function,
    },
    id: {
      type: Number,
    },
  },
  components: {
    GeneralInfo,
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
    };
  },
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
.registrationLevel {
  height: 100% !important;
}
</style>
