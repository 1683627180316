<template>
  <v-card class="prabhidhikBibaran elevation-12 my-2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        प्राविधिक विवरणहरु
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <th class="text-left ">Actions</th>
          <th class="text-left">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr class="">
          <td class="">RCC</td>
          <td class="">
            <router-link :to="{ name: 'rcc', params: { regId: regId } }">{{
              actionButtonText
            }}</router-link>
          </td>
          <td>{{ rccStatus }}</td>
        </tr>
        <tr class="">
          <td class="">Masonry</td>
          <td class="">
            <router-link :to="{ name: 'masonry', params: { regId: regId } }">{{
              actionButtonText
            }}</router-link>
          </td>
          <td>{{ masonryStatus }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "PrabhidhikBibaran",
  props: {
    regId: {
      type: Number
    },
    isAddEditAuthorized: {
      type: Boolean
    }
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    }
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      rccStatus: "",
      masonryStatus: ""
    };
  },
  mounted() {
    const rccStatusUrl = `api/registration/${this.$route.params.id}/rcc/status`;
    this.$axios
      .get(rccStatusUrl)
      .then(response => {
        this.rccStatus = response.data.status;
        console.log(this.rccStatus);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });

    const masonryStatusUrl = `api/registration/${this.$route.params.id}/masonry/status`;
    this.$axios
      .get(masonryStatusUrl)
      .then(response => {
        this.masonryStatus = response.data.status;
        console.log(this.rccStatus);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  }
};
</script>

<style scoped></style>
