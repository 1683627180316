import Vue from "vue";
import JQuery from "jquery";
import VueHtmlToPaper from "vue-html-to-paper";
import VueGoogleCharts from "vue-google-charts";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import VueSession from "vue-session";
// Import component
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import VueAxios from '@/plugins/axios'; /*eslint-disable-line*/
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import { MapsComponent, MapsPlugin } from "@syncfusion/ej2-vue-maps";
import L from "leaflet";
import VueApexCharts from "vue-apexcharts";
import "./global-components";

export const EventBus = new Vue();
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
Vue.component(MapsPlugin.name, MapsComponent);

// import { VueAxios } from "@/plugins/axios";

// import "material-design-icons-iconfont/dist/material-design-icons.css";

Vue.use(VueSession);

// Install BootstrapVue
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(VueGoogleCharts);

// Vue.http.headers.common.Authorization = 'Basic YXBpOnBhc3N3b3Jk';// axios.defaults.headers.common.Authorization = 'Basic YXBpOnBhc3N3b3Jk';

// Register the loading component globally
Vue.component("loading", Loading);

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [
    "https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css",
    "https://unpkg.com/kidlat-css/css/kidlat.css"
  ]
};

Vue.use(VueHtmlToPaper, options);

// or, using the defaults with no stylesheet
Vue.use(VueHtmlToPaper);
window.$ = JQuery;
window.jQuery = JQuery;
Vue.use(MapsPlugin);

Vue.use(VueAxios);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");
