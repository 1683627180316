<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <div>
          <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
        </div>
        <v-container>
          <v-form>
            <v-row>
              <v-col cols="2">
                Municipality name (English)
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="municipality.name" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="2">
                Municipality name (Nepali)
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.name_np"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Municipality Short Name
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.short_name"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                Municipality longitude
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.longitude"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Municipality Latitude
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.latitude"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                Province
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.province"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                Municipality address(English)
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="municipality.address"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Municipality Type
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="municipality.municipality_type"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
              <v-col md="2">
                Aarea metric system
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="municipality.area_metric_system"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Area mf system
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="municipality.area_mf_system"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
              <v-col md="2">
                Number of wards
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="municipality.no_of_wards"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Fiscal Year
              </v-col>
              <v-col md="4">
                <v-select
                  v-model="municipality.fiscal_year_id"
                  :items="fiscalyear"
                  item-value="id"
                  item-text="f_year"
                  :item-key="fiscalyear"
                  solo
                ></v-select>
              </v-col>
            </v-row>
            <v-btn v-if="userRoleId == 1" primary v-on:click="save" class="primary">Submit</v-btn>
          </v-form>
          <v-snackbar
            v-model="snackbar.show"
            :bottom="y === 'bottom'"
            :color="snackbar.color"
            :left="x === 'left'"
            :multi-line="mode === 'multi-line'"
            :right="x === 'right'"
            :timeout="3000"
            :top="y === 'top'"
            :vertical="mode === 'vertical'"
          >
            {{ successfulMessage }}
          </v-snackbar>
        </v-container>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
// import * as nepaliDatePicker from '../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min'; /*eslint-disable-line*/
// import '../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css';

export default {
  name: "Application",

  data() {
    return {
      userRoleId: localStorage.getItem("auth_user_role_id"),
      fiscalyear: [],
      // countData: ['a','2','3','4'],
      municipality: {
        id: 0,
        // reg_id: 0,
        name: "",
        name_np: "",
        short_name: "",
        no_of_wards: "",
        latitude: "",
        longitude: "",
        province: "",
        address: "",
        municipality_type: "",
        area_metric_system: "",
        area_mf_system: ""
      },
      consultancy: [],
      districts: [],
      newconsultancy: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        name: "",
        location: "",
        district_id: "",
        registration_date_np: "",
        registration_date: "2020-04-04"
      },
      // municipalityList: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      }
    };
  },
  mounted() {
    this.getFiscalyear();
    this.getMunicipality();
    //                          const fiscalUrl = 'api/fiscal-year';
    //             VueAxios.get(fiscalUrl,
    //             {
    //     headers: {
    //                         'X-Requested-With': 'XMLHttpRequest',
    //                         Authorization: `Bearer ${localStorage.getItem('token')}`,
    //                     },
    // }).then((response) => {
    //   this.fiscalyear = response.data.data;
    //   console.log(response.data);
    // })
    // .catch((error) => {
    //    console.log(`api error:${error}`);
    // });
    //          const url = 'api/municipality';
    // VueAxios.get(url, {
    //         headers: {
    //             'X-Requested-With': 'XMLHttpRequest',
    //             Authorization: `Bearer ${localStorage.getItem('token')}`,
    //         },
    //     }, {
    //     })
    //     .then((response) => {
    //         this.municipality = response.data.data.pop();
    //         console.log(this.municipality);
    //     })
    //     .catch((error) => {
    //         console.log(`api error:${error}`);
    //     });
    // this.getMunicipality();
    //  const {
    // dateField,
    // } = this.$refs;
    // if (dateField) {
    //     $(dateField.$el).nepaliDatePicker({
    //         dateFormat: '%y-%m-%d',
    //         closeOnDateSelect: true,
    //     });
    //     $(dateField.$el).on('dateSelect', (event) => {
    //         this.newconsultancy.registration_date_np = event.datePickerData.formattedDate;
    //     });
    // }
    this.getDate();
  },
  methods: {
    getDate() {
      const { dateField } = this.$refs;
      if (dateField) {
        $(dateField.$el).nepaliDatePicker({
          dateFormat: "%y-%m-%d",
          closeOnDateSelect: true
        });
        $(dateField.$el).on("dateSelect", event => {
          this.newconsultancy.registration_date_np = event.datePickerData.formattedDate;
        });
      }
    },
    getFiscalyear() {
      const fiscalUrl = "api/fiscal-year";
      VueAxios.get(fiscalUrl, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      })
        .then(response => {
          this.fiscalyear = response.data.data;
          console.log(response.data);
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      // debugger;
      if (
        this.municipality.id === null ||
        this.municipality.id === 0 ||
        this.municipality.id === undefined
      ) {
        const url = "/api/municipality";
        VueAxios.post(
          url,
          this.municipality,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.municipality = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/municipality/${this.municipality.id}`;
        VueAxios.put(
          eurl,
          this.municipality,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    getMunicipality() {
      const url = "api/municipality";
      VueAxios.get(url)
        .then(response => {
          this.municipality = response.data.data.pop();
          console.log(this.municipality);
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    edit(id) {
      // debugger;
      //    const url = `api/municipality/${this.$route.params.id}`;
      const url = `api/municipality/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        this.municipality = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/municipality/${id}`;
      VueAxios.delete(deleteurl)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  clearAllData() {
    this.newconsultancy.id = 0;
    this.newconsultancy.reg_id = this.registrationId;
    this.newconsultancy.is_consulting_firm = "";
    this.newconsultancy.registration_number = "";
    this.newconsultancy.name = "";
    this.newconsultancy.location = "";
    this.newconsultancy.district_id = "";
    this.newconsultancy.registration_date_np = "";
    this.newconsultancy.registration_date = "2043-3-3";
  },
  save() {
    //   debugger;
    if (
      this.newconsultancy.id === "null" ||
      this.newconsultancy.id === 0 ||
      this.newconsultancy.id === undefined
    ) {
      const saveUrl = "/api/consultancy";
      VueAxios.post(saveUrl, this.newconsultancy)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.consultancy.push(response.data.data);
            this.snackbar.show = true;
            this.successfulMessage = "Successful.";
            this.clearAllData();
          }
        })
        .catch(error => {
          // alert(2);
          console.log(`api error:${error}`);
        });
    } else {
      const eurl = `/api/consultancy/${this.newconsultancy.id}`;
      VueAxios.put(
        eurl,
        this.newconsultancy,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${window.localStorage.getItem("token")}`
          },
          _method: "patch"
        },
        {}
      )
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data updated successfully";
            this.clearAllData();
          }
        })
        .catch(error => {
          console.log(error);
        });
    }
  },
  edit(id) {
    //   debugger;
    //    const url = `api/registration/${this.$route.params.regId}/consultancy/${this.$route.params.id}`;
    const url = `api/consultancy/${id}`;
    VueAxios.get(url).then(response => {
      // debugger;
      this.newconsultancy = response.data.data;
      console.log(response.data.data);
    });
  },
  deleterecord(id) {
    const deleteurl = `api/registration/${this.$route.params.regId}/consultancy/${id}`;
    VueAxios.delete(deleteurl)
      .then(response => {
        // debugger;
        if (response.status === 200 || response.status === 201) {
          this.snackbar.show = true;
          this.successfulMessage = "Data deleted successfully";
          const i = this.consultancy.map(item => item.id).indexOf(id); // find index of your object
          this.consultancy.splice(i, 1);
        }
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}
.v-text-field__details {
  margin: 0;
  padding: 0;
}
</style>
