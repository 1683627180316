<template>
  <div>
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        Gallery
      </v-toolbar-title>
    </v-toolbar>

    <div class="images-preview" v-show="photoList.length">
      <div class="img-wrapper uploader" v-for="(image, index) in photoList" :key="index">
        <div class="details file-input">
          <img :src="basePath + image.photo" height="150px" width="150px" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    photoList: [Array]
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL
    };
  },
  created() {
    this.apiCall();
  },
  methods: {
    apiCall() {
      const link = this.apiUrl + imageId;
      console.log(link);
      // debugger;
      console.log(link);
      const obj = this;
      VueAxios.get(link)
        .then(response => {
          obj.records = response.data.data;

          obj.images = obj.values(obj.records);
          // debugger;
          obj.title = Object.keys(obj.records[0]);
        })
        .catch(error => {
          // alert(3)
          console.log(`api error:${error}`);
        });
    }
  }
};
</script>
