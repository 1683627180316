<style>
.houseownerinfo {
  font-weight: normal;
  width: 250px;
}

.registrationtables {
  font-size: 1.2rem !important;
}

</style>
<template>
  <v-layout class="mb-9">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-flex>
      <div>
        <v-btn class="primary mx-5" :to="{ name: 'inventorydashboard' }">Back</v-btn>
        <v-btn
          class="primary my-3 mx-3"
          :href="basePath + `/api/bids/report/pdf/${this.$route.params.id}`"
        >
          Download houseowner data in pdf
        </v-btn>
      </div>
      <v-row class="mx-1 my-1">
        <v-col>
          <v-toolbar dense dark flat color="primary">
            <v-toolbar-title>General Information</v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober elevation-2">
            <template v-slot:default>
              <tbody >
                <tr class="">
                  <td class="registrationtables">
                    सर्वेक्षकको नाम: {{ results.surveyor_name }}
                  </td>
                  <td class="registrationtables">
                    घरधनीको नाम: {{ results.houseowner_name }}
                  </td>
                  <td class="registrationtables">
                    घरधनीको सम्पर्क नं: {{ results.phone_no }}
                  </td>
                  <td class="registrationtables">
                    सडकको नाम: {{ results.street_name }}
                  </td>
                  <td></td>
                </tr>
                <tr class="registrationtables">
                  <td class="registrationtables">
                    घरधनीको नागरिकता न: {{ results.citizenship_no }}
                  </td>
                  <td class="registrationtables">
                    वडा नं: {{ results.ward_no }}
                  </td>
                  <td class="registrationtables">
                    ठेगाना (टोल): {{ results.address }}
                  </td>
                  <td class="registrationtables">
                    वडा नं: {{ results.ward_no }}
                  </td>
                  <td class="registrationtables">
                    घर नं.: {{ results.house_no }}
                  </td>
                </tr>
                <tr class="registrationtables">
                  <td class="registrationtables">
                    नक्शा नं.: {{ results.map_no }}
                  </td>
                  <td class="registrationtables">
                    नक्शा पास गरेको मिति: {{ results.map_verified_date_np }}
                  </td>
                  <td class="registrationtables">
                    निर्माण सम्पन्न मिति: {{ results.construction_completion_date_np }}
                  </td>
                  <td class="registrationtables">
                    निर्माणको लागत: {{ results.construction_cost }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="registrationtables">
            <template v-slot:default> </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-stepper v-model="el">
        <v-stepper-items>
          <v-stepper-content
            v-for="n in steps"
            :key="`${n.sn}-content`"
            :step="n.sn"
          >
            <component
              :is="getComponent(n.sn)"
              :id="results.id"
              v-if="n.sn === el"
              :next-step="nextStep"
            />
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-flex>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import bidLevel from "./components/bidLevel.vue";

const COMPONENT_MAP = {
  1: "bidLevel",
};
export default {
  name: "InventoryTab",
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      el: 1,
      steps: [
        {
          text: "Inventory System",
          sn: 1,
        },
      ],
      results: [],
      log: "",
    };
  },
  components: {
    bidLevel,
  },
  computed: {
    isLoading() {
      if (this.results === undefined || this.results.length === 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    steps(val) {
      if (this.el > val) {
        this.el = val;
      }
    },
  },
  mounted() {
    const url = `api/bids/infrastructure/building/${this.$route.params.id}`;
    VueAxios.get(url).then((response) => {
      this.results = response.data.data;
    });
  },
  methods: {
    getComponent(n) {
      return COMPONENT_MAP[n];
    },
    nextStep(n) {
      if (n === this.steps.sn) {
        this.el = 1;
      } else {
        this.el = n + 1;
      }
    },
  },
};
</script>
