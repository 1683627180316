<template>
  <v-card class="mb-12" height="auto">
    <v-app id="inspire">
      <div>
        <v-btn class="primary back ma-1" :to="{ name: 'home' }">Back</v-btn>
      </div>
      <v-row>
        <v-col cols="12">
          <GChart class="piechart" type="PieChart" @ready="onChartReady" />
        </v-col>
        <v-col cols="12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              Building stages at the end of current Quarter
            </v-toolbar-title>
          </v-toolbar>
          <a href=""></a>
          <v-simple-table class="sober">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable" v-for="p in applicationHeader" :key="p">
                    {{ p }}
                  </th>
                  <th class="text-left registrationtable">Issued</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="registrationtable"
                  v-for="applicationStatusList in applicationStatus"
                  :key="applicationStatusList.id"
                >
                  <td class="registrationtable">{{ applicationStatusList["Level"] }}</td>
                  <td class="registrationtable">
                    <p v-html="applicationStatusList['Registration Desk']"></p>
                  </td>
                  <td>{{ applicationStatusList["Technical Desk"] }}</td>
                  <td class="registrationtable">{{ applicationStatusList["Executive Desk"] }}</td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GChart class="piechart" type="PieChart" @ready="buildingCategory" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GChart class="piechart" type="PieChart" @ready="buildingStorey" />
        </v-col>
        <v-col cols="12">
          <GChart class="columnchart" type="ColumnChart" @ready="buildingFootprintArea" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GChart class="piechart" type="PieChart" @ready="buildingStructure" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <GChart class="piechart" type="PieChart" @ready="buildingUse" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <table hidden>
            <thead>
              <tr class="registrationtable">
                <th class="text-left registrationtable">S.N</th>
                <th class="text-left registrationtable">Construction Typology</th>
                <th class="text-left registrationtable">Revenue</th>
                <th class="text-left registrationtable">Revenue(%)</th>
              </tr>
            </thead>
            <tbody>
              <tr class="registrationtable">
                <td class="registrationtable">1</td>
                <td class="registrationtable">Rcc</td>
                <td></td>
                <td class="registrationtable"></td>
              </tr>
              <tr class="registrationtable">
                <td class="registrationtable">2</td>
                <td class="registrationtable">Masonry</td>
                <td>Compliance</td>
                <td class="registrationtable"></td>
              </tr>
              <tr class="registrationtable">
                <td class="registrationtable">3</td>
                <td class="registrationtable">Total</td>
                <td>Compliance</td>
                <td class="registrationtable"></td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              Revenue
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable" v-for="p in revenueHeader" :key="p">
                    {{ p }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="registrationtable"
                  v-for="revenueStatusList in revenueStatus"
                  :key="revenueStatusList.id"
                >
                  <td class="registrationtable">{{ revenueStatusList["Building Use"] }}</td>
                  <td class="registrationtable">
                    {{ revenueStatusList["Revenue"] }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <!-- <v-col cols="6">
               <h3>Building Typology</h3>
               <table  >
                  <thead>
                     <tr class="registrationtable">
                        <th class="text-left registrationtable">S.N</th>
                        <th class="text-left registrationtable">Construction  Typology</th>
                        <th class="text-left registrationtable">Revenue</th>
                        <th class="text-left registrationtable">Revenue(%)</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr class="registrationtable">
                        <td class="registrationtable">1</td>
                        <td class="registrationtable" >Rcc</td>
                        <td></td>
                        <td class="registrationtable">
                        </td>
                     </tr>
                     <tr class="registrationtable">
                        <td class="registrationtable">2</td>
                        <td class="registrationtable" >Masonry</td>
                        <td>Compliance</td>
                        <td class="registrationtable">
                        </td>
                     </tr>
                     <tr class="registrationtable">
                        <td class="registrationtable">3</td>
                        <td class="registrationtable" >Total</td>
                        <td>Compliance</td>
                        <td class="registrationtable">
                        </td>
                     </tr>
                  </tbody>
               </table>
            </v-col> -->
      </v-row>
      <!-- <v-row>
        <v-col cols="12">
          <h3>Consultancy</h3>
          <v-simple-table class="sober">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable">Consultancy</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="registrationtable"
                  v-for="consultancyList in consultancy"
                  :key="consultancyList.id"
                >
                  <td class="registrationtable">{{ consultancyList.name }}</td>
                  <td class="registrationtable"></td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row> -->
    </v-app>
  </v-card>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  data() {
    return {
      i: 0,
      buildingStage: [],
      applicationStatus: [],
      applicationHeader: [],
      revenueStatus: [],
      revenueHeader: [],
      consultancy: []
    };
  },
  mounted() {
    const url = `api/report/application-status`;
    VueAxios.get(url)
      .then(response => {
        this.applicationStatus = response.data.data;
        console.log(this.applicationStatus);
        // debugger;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
        console.log(this.applicationHeader);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const revenueurl = `api/report/revenue`;
    VueAxios.get(revenueurl)
      .then(response => {
        this.revenueStatus = response.data.data;
        console.log(this.revenueStatus);
        this.revenueHeader = Object.keys(this.revenueStatus[0]);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const consultancyUrl = `api/report/consultancy`;
    VueAxios.get(consultancyUrl)
      .then(response => {
        this.consultancy = response.data.data;
        // console.log(this.revenueStatus);
        // this.masonHeader = Object.keys(this.revenueStatus[0]);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    onChartReady(chart, google) {
      VueAxios.get(`api/report/construction-type`).then(response => {
        const options = {
          title: response.data.Title,
          is3D: true
        };
        // console.log(response.data.data);
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingCategory(chart, google) {
      VueAxios.get(`api/report/building-category`).then(response => {
        console.log(response.data.data);
        //  debugger;
        const options = {
          title: response.data.Title,
          is3D: true
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingStorey(chart, google) {
      VueAxios.get(`api/report/building-storey`).then(response => {
        this.buildingStoreyTitle = response.data.Title;
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingFootprintArea(chart, google) {
      VueAxios.get(`api/report/building-area`).then(response => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingStructure(chart, google) {
      VueAxios.get(`api/report/building-structure`).then(response => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    },
    buildingUse(chart, google) {
      VueAxios.get(`api/report/building-use`).then(response => {
        //  console.log(response.data.data);
        const options = {
          title: response.data.Title,
          is3D: true
        };
        chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
      });
    }
  }
};
</script>

<style scoped>
.piechart {
  width: 900px;
  height: 500px;
}
</style>
