<style>
#styled-input {
  height: 20px;
  font-size: 30pt;
  width: 10px;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.btn {
  margin: 5px;
  text-align: center;
}
</style>
<template>
  <div>
      <v-row class="mx-2">
          <v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                large
                depressed=""
                class="primary right m-2 subtitle-1 my-5"
                v-bind="attrs"
                v-on="on"
              >
                Add New Infrastructure 
              </v-btn>
            </template>

            <v-list>
              <v-list-item v-for="(menu, i) in menus" :key="i" :to="{ path: menu.route }">
                <v-list-item-title>{{ menu.title }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <!-- <v-btn
            large
            depressed=""
            color="primary"
            class=" m-2 subtitle-1 my-5"
            :to="{ name: 'infrastructureResultAnalysis' }"
          >
            Result and Analysis
          </v-btn> -->

        <v-spacer></v-spacer>
      </v-row>

      <div>
        <!-- <loading :active.sync="isDataLoading" :can-cancel="true" :is-full-page="false"></loading> -->
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title>
            Infrastructure List
          </v-toolbar-title>
        </v-toolbar>
        <!-- <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :hide-default-header="false"
          :loading="isLoading"
          hide-default-footer
          item-key="name"
          class="sober elevation-12"
        > -->
        <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :items-per-page="10"
          :hide-default-header="false"
          :loading="isLoading"
          item-key="name"
          class="sober elevation-12"
        >
          <template v-slot:body.prepend>
            <tr>
              <td>
                <v-text-field
                  label="Search"
                  type="text"
                  v-model="searchKeys.houseowner_name_np"
                ></v-text-field>
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.registration_date_np"
                  >></v-text-field
                >
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.internal_code"
                  >></v-text-field
                >
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.file_code"
                  >></v-text-field
                >
              </td>
              <td>
                <v-text-field label="Search" type="text" v-model="searchKeys.phase">></v-text-field>
              </td>

              <td colspan="2"></td>
            </tr>
          </template>

         <template v-slot:item.status="{ item }">
            <v-btn v-if="item.infrastructure_typology===typology.bridge" color="primary" small :to="{ name: 'infrastructureBridge', params: { id: 'road', regId: item.id }  }"
              >Open
            </v-btn>
            <v-btn v-if="item.infrastructure_typology===typology.road" color="primary" small :to="{ name: 'infrastructureRoad', params: { id: 'road', regId: item.id } }"
              >Open
            </v-btn>
            <v-btn v-if="item.infrastructure_typology===typology.electrical" color="primary" small :to="{ name: 'infrastructureElectricity', params: { id: 'electricity', regId: item.id } }"
              >Open
            </v-btn>
            <v-btn v-if="item.infrastructure_typology===typology.retaining" color="primary" small :to="{ name: 'infrastructureRetainingStructure', params: { id: 'retaining', regId: item.id } }"
              >Open
            </v-btn>
            <v-btn v-if="item.infrastructure_typology===typology.water_supply" color="primary" small :to="{ name: 'infrastructureWater', params: { id: 'water', regId: item.id } }"
              >Open
            </v-btn>
            <v-btn v-if="item.infrastructure_typology===typology.other" color="primary" small :to="{ name: 'infrastructureOther', params: { id: 'other', regId: item.id } }"
              >Open
            </v-btn>
          </template>
        </v-data-table>
      </div>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  data() {
    return {
      counter: 0,
      // boolean to start/stop progress spinner
      isDataLoading: true,
      enabled: null,
      DESSERTS: [],
      items: [],
      search: null,

      // sets: [[1, 2, 3, 4, 5],[6, 7, 8, 9, 10]],
      slots: [
        "body",
        "body.append",
        "body.prepend",
        "footer",
        "header.data-table-select",
        "header",
        "progress",
        "item.data-table-select",
        "item.<name>",
        "no-data",
        "no-results",
        "top"
      ],
      menus: [
        { title: "Road", route: "infrastructureRoad/road/0" },
        { title: "Bridge", route: "infrastructureBridge/bridge/0" },
        { title: "Electricity", route: "infrastructureElectricity/electricity/0" },
        { title: "Water", route: "infrastructureWater/water/0" },
        { title: "Retaining Structure", route: "infrastructureRetainingStructure/retaining/0" },
        { title: "Other", route: "infrastructureOther/other/0" }
      ],
      typology: {
        bridge: "bridge",
        electrical: "electrical_infrastructure",
        other: "other_infrastructure",
        retaining: "retaining_works",
        road: "road",
        water_supply: "water_supply_structure"
      },
      headers: [
        {
          text: "Name of Infrastructure",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Ward No.", value: "ward_no" },
        // { text: "Created Date", value: "created_date" },
        { text: "Code No", value: "code" },
        { text: "Infrastructure Typology", value: "infrastructure_typology" },

        { text: "कार्य", value: "status" }
      ],
      searchKeys: {
        name: "",
        code: "",
        ward_no: "",
        infrastructure_typology: "",
      }
    };
  },
  computed: {
    isLoading() {
      return this.isEnabled("progress");
    }
  },

  watch: {
    enabled(slot) {
      if (slot === "no-data") {
        this.items = [];
      } else if (slot === "no-results") {
        this.search = "...";
      } else {
        this.search = null;
      }
    }
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    searchFilter() {},
    isEnabled(slot) {
      return this.enabled === slot;
    }
    // searchFilters() {
    //   // alert();
    //   // const houseowner_name_np=this.houseowner_name_np;
    //   //  debugger;
    //   const statusurl = "api/registration/search";
    //   VueAxios.post(
    //     statusurl,
    //     this.searchKeys,
    //     {
    //       headers: {
    //         "X-Requested-With": "XMLHttpRequest",
    //         Authorization: `Bearer ${localStorage.getItem("token")}`
    //       }
    //     },
    //     {
    //       // timeout: 1000000,
    //     }
    //   ).then(response => {
    //     // debugger;
    //     // this.searchKeys.houseowner_name_np = response.data.data.searchKeys;
    //     // console.log(this.DESSERTS);
    //     this.items = response.data.data;
    //   });
    // }
  },
  mounted() {
    this.scrollToTop();
    const obj = this;
    console.log(localStorage.getItem("token"));
    const url = "api/bids/infrastructure/list";
    VueAxios.get(url).then(response => {
      // debugger;
      obj.DESSERTS = response.data;
      obj.items = response.data;
      // console.log(obj.items);debugger;
      // Stop the progress bar
      obj.isDataLoading = false;
    });
  }
};
</script>

<style scoped>
.v-data-table::v-deep th {
  font-size: 1.2rem !important;
}
.v-data-table::v-deep td {
  font-size: 20px !important;
}
</style>
