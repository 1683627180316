var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('v-card',{staticClass:"d-flex flex-row md-3"},[_c('v-card',{staticClass:"d-flex flex-column col-6 md-3 "},[_c('NibedanBibaran',{staticClass:"order-1",attrs:{"regId":this.$route.params.id,"isAddEditAuthorized":_vm.user_role_id == 2 && _vm.results.desk_user_role_id == _vm.user_role_id && _vm.results.phase_id != 5}}),_c('PrabhidhikBibaran',{staticClass:"order-2",attrs:{"regId":this.$route.params.id,"isAddEditAuthorized":_vm.user_role_id == 4 && _vm.results.desk_user_role_id == _vm.user_role_id && _vm.results.phase_id == 1}}),_c('ReceivedCertificates',{staticClass:"order-3",attrs:{"regId":this.$route.params.id}})],1),_c('v-card',{staticClass:"d-flex flex-column col-6"},[_c('v-card',{staticClass:"documents plinthDocuments elevation-12 my-2"},[_c('v-toolbar',{attrs:{"color":"primary","dark":"","flat":""}},[_c('v-toolbar-title',[_vm._v(" कागजातहरु ")])],1),_c('v-simple-table',{staticClass:"sober elevation-2"},[_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Actions")])])]),_c('tbody',_vm._l((_vm.documents),function(item){return _c('tr',{key:item.name},[_c('td',[_vm._v(_vm._s(item.name))]),_c('td',[(
                    _vm.user_role_id == 2 &&
                      _vm.results.desk_user_role_id == _vm.user_role_id &&
                      _vm.results.phase_id == 3
                  )?_c('span',[_c('router-link',{attrs:{"to":{
                      name: 'plinthdocumentupload',
                      params: { id: item.id, regId: _vm.id, sn: item.sn }
                    }}},[_vm._v("Upload")])],1):_c('span',[_c('router-link',{attrs:{"to":{
                      name: 'plinthdocumentupload',
                      params: { id: item.id, regId: _vm.id, sn: item.sn }
                    }}},[_vm._v("View")])],1)])])}),0)]),(_vm.user_role_id == _vm.results.desk_user_role_id && _vm.results.phase_id !== 5)?_c('RegistrationAction',{attrs:{"regPhaseId":this.results.phase_id,"regDeskId":this.results.desk_user_role_id}}):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }