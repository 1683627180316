<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

.font {
  font-size: 20px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form>
          <v-container height="auto">
            <!-- <v-row height="auto" class="yellow"> -->
            <v-col md="12">
              <v-card class="yellow">
                <center>
                  <h2>भीमेश्वर नगरपालिका <br />नक्सा पास दरखास्त रसिद</h2>
                </center>

                <br />
                <!-- <v-btn primary v-on:click="save" class="primary">Print</v-btn>o -->
                <table id="customers">
                  <tr></tr>
                  <tr>
                    <td>
                      <table id="customers">
                        <tr></tr>
                        <tr>
                          <td class="font">आर्थिक बर्ष:</td>
                          <td class="font">{{ results[0].fiscal_year }}</td>
                        </tr>
                        <tr>
                          <td class="font">दर्ता मिति:</td>
                          <td class="font">{{ results[0].registration_date_np }}</td>
                        </tr>
                        <tr>
                          <td class="font">निर्माणको विवरण:</td>
                          <td class="font">{{ results[0].construction_type_name }}</td>
                        </tr>
                        <tr>
                          <td class="font">आन्तरिक संकेत नं:</td>
                          <td class="font">{{ results[0].internal_code }}</td>
                        </tr>
                        <tr>
                          <td class="font">करदाता संकेत नं:</td>
                          <td class="font">{{ results[0].houseowner_tax_no }}</td>
                        </tr>
                        <tr>
                          <td class="font">घरधनीको नाम:</td>
                          <td class="font">{{ results[0].houseowner_name_np }}</td>
                        </tr>
                        <tr>
                          <td class="font">वडा नं:</td>
                          <td class="font">{{ results[0].ward_no }}</td>
                        </tr>
                        <tr>
                          <td class="font">सम्पर्क नं:</td>
                          <td class="font">{{ results[0].contact_no }}</td>
                        </tr>
                        <tr>
                          <td class="font">फाईल कोड:</td>
                          <td class="font">{{ results[0].file_code }}</td>
                        </tr>
                        <tr>
                          <td class="font">जग्गा कित्ता नं:</td>
                          <td class="font">{{ results[0].land_plot_no }}</td>
                        </tr>

                        <tr>
                          <td></td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table id="customers">
                        <tr>
                          <h2>प्राप्त कागजहरु (✓)</h2>
                          <h6>नोट: निम्न लिखित कागजातहरु सबै अविबार्य रुपमा पेश गर्नु पर्दछ</h6>
                        </tr>
                        <tr>
                          <td class="font">१। जग्गा धनी प्रमाणपत्र प्रतिलिपि</td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].landowner_certificate_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">२। चालु आर्थिक बर्षको मालपोत तिरेको रसिदको प्रतिलिपि</td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].revenue_tax_slip_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">३। ज.ध दर्ता प्रमाण पुर्जा</td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0]
                                  .landowner_registration_certificate_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">४। नागरिकता प्रमाणपत्रको प्रतिलिपि</td>
                          <td>
                            <v-checkbox
                              v-model="results[0].registration_checklist[0].citizenship_submitted"
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">
                            ५। कि.नं.स्पष्ट भएको नापी प्रमाणित नक्शा (ब्लु प्रिन्ट)
                          </td>
                          <td>
                            <v-checkbox
                              v-model="results[0].registration_checklist[0].blueprint_submitted"
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">
                            ६। डिजाईनरको इजाजतपत्रको नबिकरण सहितको फोटोकपी (सरोकारवालाबाट प्रमाणित)
                          </td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].designer_certificate_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">
                            ७। मञ्जुरी लिई बनाउने भएमा नक्शा वालाले कानुन शाखाको रोहवरमा भएको
                            मञ्जुरीनामाको सक्कल
                          </td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].authority_letter_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">
                            ८। वारेश राखि नक्शा पास गर्ने भए वारिसको प्रमाणितको प्रतिलिपि
                          </td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].nominee_certificate_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">
                            ९। नक्सा बनाउने वा भवन डिजाईन गर्ने प्राविधिकद्धारा मन्जुरी पत्र
                          </td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0]
                                  .designer_authority_letter_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">१०। भवनको नक्स</td>
                          <td>
                            <v-checkbox
                              v-model="results[0].registration_checklist[0].house_map_submitted"
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <tr>
                          <td class="font">११। घरधनिको फोटो</td>
                          <td>
                            <v-checkbox
                              v-model="
                                results[0].registration_checklist[0].houseowner_photo_submitted
                              "
                              dense
                            ></v-checkbox>
                          </td>
                        </tr>
                        <br />
                        <tr>
                          <td class="font"></td>
                          <td>
                            <p class="font">Checked by</p>
                          </td>
                          <td>
                            <p class="font">फाराम बुझिलिने</p>
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>

                <br />
              </v-card>
            </v-col>
            <!-- </v-row> -->
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>

<style scoped>
/* #styled-input {
  height: 15px;
  font-size: 15pt;
}

.styled-input label[for] {
  height: 15px;
  font-size: 15pt;
} */

/* .row,
.col {
  height: 38px;
} */

.font {
  font-size: 20px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "ApplicationTab",
  data() {
    return {
      results: [],
      documents: []
    };
  },
  mounted() {
    const url1 = `api/registration/${this.$route.params.regId}/yellow-slip`;
    VueAxios.get(url1).then(responseJaggadhani => {
      // debugger;
      this.results = responseJaggadhani.data.data;
      // console.log(responseJaggadhani.data.data);
      // console.log(this.results[0].registration_checklist[0].houseowner_photo_submitted);
    });
  }
};
</script>
