<style scoped>
/* @media (min-width: 1264px) and (max-width: 1903px) {
  .flex.lg5-custom {
    width: 20%;
    max-width: 20%;
    flex-basis: 20%;
    border: 0px;
  }
} */
</style>
<template>
  <v-app>
    <Navbar />
    <v-card class=" mx-10 mt-3 mb-10 pb-10 pd-3">
      <router-view></router-view>
    </v-card>
    <Footer />
  </v-app>
</template>
<script>
import Navbar from "./Navbar";
import Footer from "./Footer";
export default {
  name: "App",
  components: {
    Navbar,
    Footer
  }
};
</script>

<style scoped>
/* p.a {
   word-spacing: 1cm;
   } */
</style>
