<template>
  <div>
    <v-card class="mb-12" color="grey lighten-1" height="auto">
      <v-app id="inspire">
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="2">
                Mason Name
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="newmason.name" label="" height="10" solo></v-text-field>
              </v-col>
              <v-col cols="2">
                Registration Number
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newmason.registration_number"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                District
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="newmason.district_id"
                  :items="districts"
                  item-value="id"
                  item-text="district_name"
                  :key="id"
                  solo
                ></v-select>
              </v-col>
              <v-col cols="2">
                Location
              </v-col>
              <v-col cols="4">
                <v-text-field v-model="newmason.location" label="" height="10" solo></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="2">
                Registration Date
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newmason.registration_date_np"
                  label=""
                  class="custom-text-field"
                  ref="dateField"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-btn primary v-on:click="save" class="primary" :disabled="!valid">Submit</v-btn>
        </v-form>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <v-simple-table class="sober">
          <template v-slot:default>
            <thead>
              <tr class="registrationtable">
                <th class="text-left registrationtable">
                  Mason Name
                </th>
                <th class="text-left registrationtable">
                  Registration Number
                </th>
                <th class="text-left registrationtable">Address</th>
                <th class="text-left registrationtable">Registration Date</th>
                <th class="text-left registrationtable">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr class="registrationtable" v-for="masonList in mason" :key="masonList.id">
                <td class="registrationtable">{{ masonList.name }}</td>
                <td class="registrationtable">
                  {{ masonList.registration_number }}
                </td>
                <td>{{ masonList.location }}</td>
                <td>{{ masonList.registration_date_np }}</td>
                <td>
                  <v-btn small class="primary" v-on:click="edit(masonList.id)">Edit</v-btn>
                  <v-btn small color="primary" v-on:click="deleterecord(masonList.id)"
                    >Delete</v-btn
                  >
                  <br />
                  <br />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-app>
    </v-card>
    <v-btn color="primary" @click="nextStep(2)">
      Continues
    </v-btn>
    <v-btn text>Cancel</v-btn>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
//   import * as nepaliDatePicker from '../../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min'; /*eslint-disable-line*/
//  import '../../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css';

export default {
  name: "Mason",

  data() {
    return {
      valid: false,
      date: "",
      options: [
        { id: "1", value: "Mr" },
        { id: "2", value: "Mrs" }
      ],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      districts: [],
      mason: [],
      newmason: {
        id: 0,
        reg_id: this.registrationId,
        name: "",
        location: "",
        registration_date_np: "",
        registration_number: "",
        district_id: "",
        registration_date: "2020-04-4"
      }
    };
  },
  created() {
    const url = "api/mason";
    VueAxios.get(url)
      .then(response => {
        // debugger;
        this.mason = response.data.data;
        //   this.newmason.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    this.getDistricts();
  },
  mounted() {
    const { dateField } = this.$refs;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true
      });
      $(dateField.$el).on("dateSelect", event => {
        this.newmason.registration_date_np = event.datePickerData.formattedDate;
      });
    }
  },
  methods: {
    getDistricts() {
      const districtUrl = "api/districts";
      VueAxios.get(districtUrl)
        .then(response => {
          // debugger;
          this.districts = response.data.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    clearAllData() {
      this.newmason.id = 0;
      this.newmason.reg_id = this.registrationId;
      this.newmason.name = "";
      this.newmason.location = "";
      this.newmason.registration_date = "";
      this.newmason.registration_date_np = "";
      this.newmason.district_id = "";
      this.newmason.registration_number = "";
    },
    save() {
      // debugger;
      if (this.newmason.id === "null" || this.newmason.id === 0 || this.newmason.id === undefined) {
        const saveUrl = "api/mason";
        VueAxios.post(
          saveUrl,
          this.newmason,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            //  debugger;
            if (response.status === 200 || response.status === 201) {
              this.mason.push(response.data.data);
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              this.$refs.form.reset();
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/mason/${this.newmason.id}`;
        VueAxios.put(
          eurl,
          this.newmason,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data added successfully";
              this.$refs.form.reset();
              this.newmason.id = 0; // reset the edit id
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/mason/${this.$route.params.id}`;
      const url = `api/mason/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        this.newmason = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/mason/${id}`;
      VueAxios.delete(deleteurl)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.mason.map(item => item.id).indexOf(id); // find index of your object
            this.designer.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}
.v-text-field__details {
  margin: 0;
  padding: 0;
}
</style>
