<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form>
          <v-container height="auto">
            <v-row height="auto">
              <v-col md="12">
                <v-card>
                  <center>
                    <h3>Application Status</h3>
                  </center>
                  <br />
                  <v-simple-table class="sober">
                    <template v-slot:default>
                      <thead>
                        <tr class="registrationtable">
                          <!-- <th class="text-left registrationtable">Level</th> -->
                          <th
                            class="text-left registrationtable"
                            v-for="p in applicationHeader"
                            :key="p"
                          >
                            {{ p }}
                          </th>
                          <!-- <th class="text-left registrationtable">Technical Desk</th>
                                                         <th class="text-left registrationtable">Senior Technical Desk</th>
                                                    <th class="text-left registrationtable">Executive Desk </th> -->
                          <th class="text-left registrationtable">Issued</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="registrationtable"
                          v-for="applicationStatusList in applicationStatus"
                          :key="applicationStatusList.id"
                        >
                          <td class="registrationtable">{{ applicationStatusList["Level"] }}</td>
                          <td class="registrationtable">
                            <p v-html="applicationStatusList['Registration Desk']"></p>
                          </td>
                          <td>{{ applicationStatusList["Technical Desk"] }}</td>
                          <td class="registrationtable">
                            {{ applicationStatusList["Executive Desk"] }}
                          </td>
                          <td></td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Component1",
  props: {
    id: {
      type: String
    },
    nextStep: {
      type: Function
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  data() {
    return {
      applicationStatus: [],
      applicationHeader: []
    };
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}/application-status`;
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {
        timeout: 100000
      }
    )
      .then(response => {
        this.applicationStatus = response.data;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
        console.log(this.applicationStatus);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  }
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
