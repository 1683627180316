<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-container class="pa-0" dense>
      <v-row class="" dense>
        <v-col xs12 md12 lg12>
          <v-card class="pa-0">
            <v-btn class="primary my-3 mx-3" :to="{ name: 'infrastructuredashboard' }">Back</v-btn>
            <v-btn
              class="primary my-3 mx-3"
              :href="basePath + `/api/bids/road-report/pdf/${this.$route.params.regId}`"
            >
              Download road report data in pdf
            </v-btn>

            <v-toolbar color="primary " dark>
              <v-col class="d-flex justify-space-around">
                <v-toolbar-title class="text-center justify-center">
                  Road Infrastructures Inventory System
                </v-toolbar-title>
              </v-col>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-row class="elevation-12" dense>
        <v-col>
          <v-toolbar color="primary" dark>
            <v-toolbar-title> Information </v-toolbar-title>
          </v-toolbar>
          <v-form ref="form" class="mx-4 my-4" v-model="valid">
            <!-- <v-row>
                <v-col md="4">
                  <label> Name<span> *</span></label>
                </v-col>

                <v-col md="8">
                  <v-text-field
                    v-model="newroadInfo.name"
                    :rules="[required('Name')]"
                    class="custom-text-field"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row> -->
            <v-row>
              <v-col md="4">
                <label> वडा नं.<span> *</span></label>
              </v-col>

              <v-col md="8">
                <v-select
                  v-model="newroadInfo.ward_no"
                  :rules="[requiredDropdown('वडा नं')]"
                  label="Select ward"
                  :items="wardName"
                  item-value="id"
                  item-text="ward_name"
                  :key="id"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label> Road Network Map<span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-radio-group
                  dense
                  v-model="newroadInfo.is_road_network_map_present"
                  :mandatory="true"
                >
                  <v-radio label="Yes" v-bind:value="1"></v-radio>
                  <v-radio label="No" v-bind:value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="newroadInfo.is_road_network_map_present">
              <v-col md="4">
                <label> Road Network Map ID<span> *</span></label>
              </v-col>
              <v-col md="8">
                <v-text-field
                  v-model="newroadInfo.road_network_map_id"
                  :rules="[required('Road Network Map ID')]"
                  class="custom-text-field"
                  label="Road Network Map ID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col md="4">
                <label> Co-ordinate<span> *</span></label>
              </v-col>

              <v-col cols="8"
                >{{ newroadInfo.latlng_coordinates }}
                <!-- {{ newroadInfo.latlng_coordinates.latitude }} ,
                  {{ newroadInfo.latlng_coordinates.longitude }} -->
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label>Name of Road<span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="newroadInfo.name"
                  :rules="[required('Road Name')]"
                  label="Road Name"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label> Road ID <span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="newroadInfo.road_id"
                  :rules="[required('Road ID')]"
                  label="Road ID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="4">
                <label> Type of Road <span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-select
                  v-model="newroadInfo.road_type_id"
                  :rules="[requiredDropdown('Road Type')]"
                  :items="roadType"
                  item-value="id"
                  item-text="road_type"
                  :key="id"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                  <label> Type of Length Unit <span> *</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newroadInfo.length_unit_type_id"
                    :rules="[requiredDropdown('Length Type Unit')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            <v-row>
              <v-col cols="4">
                <label> Length (m)<span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="newroadInfo.length"
                  :rules="[requiredNumber('Length'), maxLength('Length', 5)]"
                  label="Length "
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
                <v-col cols="4">
                  <label> Type of Width Unit <span> *</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newroadInfo.width_unit_type_id"
                    :rules="[requiredDropdown('Width Type Unit')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
            <v-row>
              <v-col cols="4">
                <label> Width (m)<span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  v-model="newroadInfo.width"
                  :rules="[requiredNumber('Width '), maxLength('Width ', 5)]"
                  label="Width"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="4">
                <label> Drainage System<span> *</span></label>
              </v-col>
              <v-col cols="8">
                <v-radio-group
                  dense
                  v-model="newroadInfo.is_drainage_system_present"
                  :mandatory="true"
                >
                  <v-radio label="Yes" v-bind:value="1"></v-radio>
                  <v-radio label="No" v-bind:value="0"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-row v-if="newroadInfo.is_drainage_system_present">
              <v-col cols="4"> Sewer </v-col>
              <v-col cols="8">
                <v-text-field v-model="newroadInfo.sewer" label="Right of Way"></v-text-field>
              </v-col>
            </v-row>
            <v-snackbar
              v-model="snackbar.show"
              :bottom="y === 'bottom'"
              :color="snackbar.color"
              :left="x === 'left'"
              :multi-line="mode === 'multi-line'"
              :right="x === 'right'"
              :timeout="3000"
              :top="y === 'top'"
              :vertical="mode === 'vertical'"
            >
              {{ successfulMessage }}
            </v-snackbar>
            <v-btn
              primary
              v-on:click="save"
              v-if="isPostAuthorized"
              class="primary"
              :disabled="!valid"
              >Submit</v-btn
            >

            <br />
          </v-form>
          <!-- <infrastructure-photo-card :url="apiUrl" :column-header="headerList" :href="href" :actionList="actionList"/> -->
          <card
            :url="apiUrl"
            :column-header="headerList"
            :href="href"
            :action-list="actionList"
            :photoL-list="photoView"
            v-on:editdetail="edit"
          />
          <!-- <photo-card :url="apiUrl" :column-header="headerList" :href="href" /> -->
        </v-col>
        <v-col xs12 md4 lg4>
          <inventory-map
            class="order-2"
            cordinateType="multipleCordinate"
            v-model="newroadInfo.latlng_coordinates"
          />
          <photographs-upload @file-upload="newroadInfo.photo = $event" />
          <gallery :photo-list="PhotoList" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Login",
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      isPostAuthorized: true,
      isFormLoading: false,
      headerList: ["ward_no", "name", "length", "width", "id"],
      href: ["id"],
      actionList: ["Edit"],
      photoView: ["photo"],
      images: [],
      PhotoList: [],
      apiUrl: `api/bids/infrastructure/road`,
      files: [],
      value: [],
      title: [],
      roadInfo: [],
      roadType: [],
      unitType: [],
      roadTypeUrl: [],
      wardName: [],
      formData: new FormData(),
      newroadInfo: {
        id: 0,
        // name: "",
        ward_no: "",
        is_road_network_map_present: "",
        road_network_map_id: "",
        latlng_coordinates: {},
        name: "",
        road_id: "",
        road_type_id: "",
        length_unit_type_id: 1,
        length: "",
        width_unit_type_id: 1,
        width: "",
        is_drainage_system_present: "",
        sewer: "",
        photo: [],
      },
      valid: false,
      maxLength(propertyType, maxLength) {
        return (v) =>
          (v && String(v).length <= maxLength) ||
          `${propertyType} must be less than ${maxLength} digits`;
      },
      required(propertyType) {
        return (v) => (v && String(v).length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return (v) => v || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return (v) => (v && v > 0) || `Only number is allowed`;
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      user_role_id: localStorage.getItem("auth_user_role_id"),

      color: { color: "#3F3C3C" },
    };
  },
  created() {
    this.selectBoxApi();
    const roadDetailUrl = `/api/bids/infrastructure/road/${this.$route.params.regId}`;
    VueAxios.get(roadDetailUrl)
      .then((response) => {
        this.newroadInfo = response.data.data;
        this.PhotoList = response.data.data.photos;
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },
  computed: {
    isLoading() {
      if (this.roadType.length === 0 || this.wardName.length === 0 || this.unitType.length === 0) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    },
  },
  methods: {
    selectBoxApi() {
      const obj = this;

      // this.length_unit_type_id = unitType[0]
      const roadTypeUrl = "api/bids/select-box/road";
      VueAxios.get(roadTypeUrl)
        .then((response) => {
          obj.roadType = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then((response) => {
          obj.wardName = response.data;
          // console.log(obj.buildingPermanent);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
      const unitTypeUrl = "api/bids/select-box/unit-type";
      VueAxios.get(unitTypeUrl)
        .then((response) => {
          obj.unitType = response.data;
          // console.log(obj.buildingPermanent);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      // const self = this;

      const formData = new FormData();
      const files = this.newroadInfo.photo;

      if (files === "null" || files === 0 || files === undefined) {
        //No file selected
        console.log("File is not selected");
      } else {
        if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
          for (let i = 0; i < files.length; i += 1) {
            formData.append("photo[]", files[i], files[i].name);
          }
        }
      }

      // formData.append("name", this.newroadInfo.name);

      formData.append("ward_no", this.newroadInfo.ward_no);

      formData.append("road_network_map_id", this.newroadInfo.road_network_map_id);
      formData.append("latlng_coordinates", JSON.stringify(this.newroadInfo.latlng_coordinates));
      formData.append("name", this.newroadInfo.name);
      formData.append("road_id", this.newroadInfo.road_id);
      formData.append("is_road_network_map_present", this.newroadInfo.is_road_network_map_present);
      formData.append("road_type_id", this.newroadInfo.road_type_id);
      formData.append("length_unit_type_id", this.newroadInfo.length_unit_type_id);
      formData.append("length", this.newroadInfo.length);

      formData.append("width_unit_type_id", this.newroadInfo.width_unit_type_id);
      formData.append("width", this.newroadInfo.width);
      formData.append("is_drainage_system_present", this.newroadInfo.is_drainage_system_present);
      formData.append("road_type_id", this.newroadInfo.road_type_id);
      formData.append("sewer", this.newroadInfo.sewer);
      formData.append("length", this.newroadInfo.length);

      console.log(formData);

      if (
        this.newroadInfo.id === "null" ||
        this.newroadInfo.id === 0 ||
        this.newroadInfo.id === undefined
      ) {
        formData.append("_method", "POST");
        const saveUrl = `api/bids/infrastructure/road`;

        this.isFormLoading = true;
        VueAxios.post(saveUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            this.isFormLoading = false;

            if (response.status === 200 || response.status === 201) {
              this.roadInfo.push(response.data.data);

              this.newroadInfo.photo = [];
              this.newroadInfo.latlng_coordinates = "";
              // this.photo = [];
              this.snackbar.show = true;
              this.successfulMessage = "Record added successfully.";
              // this.$refs.form.reset();
            }
          })
          .catch((error) => {
            this.isFormLoading = false;
            this.snackbar.show = true;
            this.successfulMessage = "Saved Failed.";
            console.log(`api error:${error}`);
          });
      } else {
        // this.isFormLoading = true;
        formData.append("_method", "PUT");
        const eurl = `/api/bids/infrastructure/road/${this.newroadInfo.id}`;
        VueAxios.post(eurl, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
          .then((response) => {
            console.log(response);
            // this.isFormFLoading = false;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data updated successfully";
              // this.$refs.form.reset();
              this.newroadInfo.id = 0;
            }

            console.log(response);
          })
          .catch((error) => {
            this.snackbar.show = true;
            this.successfulMessage = "Save failed.";

            console.log(error);
          });
      }
    },

    edit(id) {
      const obj = this;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/bids/infrastructure/road/${id}`;
      VueAxios.get(url).then((response) => {
        obj.newroadInfo = response.data.data;

        obj.newroadInfo.latlng_coordinates = JSON.parse(response.data.data.latlng_coordinates);
        obj.PhotoList = obj.newroadInfo.photos;
      });
    },
  },
};
</script>
<style scoped>
label span {
  color: red;
}
.card {
  padding: 15px;
}
</style>
