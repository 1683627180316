<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
</style>
<template>
  <div id="app">
    <v-card class="d-flex flex-row md-3">
      <v-card class="d-flex flex-column col-6 md-3">
        <NibedanBibaran
          class="order-1"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 2 && results.desk_user_role_id == user_role_id && results.phase_id != 5
          "
        />
        <PrabhidhikBibaran
          class="order-2"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 4 && results.desk_user_role_id == user_role_id && results.phase_id == 1
          "
        />
        <ReceivedCertificates class="order-3" :regId="this.$route.params.id" />
      </v-card>
      <v-card class="d-flex flex-column col-6">
        <v-card class="documents superStructureDocuments elevation-12 my-2">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              कागजातहरु
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in documents" :key="item.name">
                <td>{{ item.name }}</td>
                <td>
                  <span
                    v-if="
                      user_role_id == 2 &&
                        results.desk_user_role_id == user_role_id &&
                        results.phase_id == 4
                    "
                  >
                    <router-link
                      :to="{
                        name: 'superstructuraldocumentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >Upload</router-link
                    >
                  </span>
                  <span v-else>
                    <router-link
                      :to="{
                        name: 'superstructuraldocumentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >View</router-link
                    >
                  </span>
                </td>
              </tr>
            </tbody>
            <!-- </template> -->
          </v-simple-table>
          <RegistrationAction
            v-if="user_role_id == results.desk_user_role_id && results.phase_id !== 5"
            :regPhaseId="this.results.phase_id"
            :regDeskId="this.results.desk_user_role_id"
          />
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NibedanBibaran from "../subComponents/NibedanBibaran.vue";
import PrabhidhikBibaran from "../subComponents/PrabhidhikBibaran.vue";
import ReceivedCertificates from "../subComponents/ReceivedCertificates.vue";
import RegistrationAction from "../subComponents/RegistrationAction.vue";
//   import Application from '../../../modules/tabbedElements/components/Application.vue';

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function
    },
    id: {
      type: Number
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  components: {
    NibedanBibaran,
    PrabhidhikBibaran,
    ReceivedCertificates,
    RegistrationAction
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(url).then(response => {
      // debugger;
      this.results = response.data.data;
      // console.log(response.data.data);
    });
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      results: [],
      documents: [
        {
          id: 1,
          name: "सुपरस्ट्रक्चर सम्म निर्माणको निर्माणका जाँचको लागि निवेदन",
          value: "superstructure-inspect-application",
          action: "Upload",
          status: "",
          sn: 1
        },
        {
          id: 2,
          name:
            "दोस्रो चरणको कार्य सम्पन्नको (स्थाई निर्माण) सम्पन्नको न.पा. प्राविधिकको प्रतिवेदन",
          value: "second-phase-report",
          action: "Upload",
          status: "",
          sn: 2
        },
        {
          id: 3,
          name: "निर्माण कार्य सम्पन्न प्रमाण पत्रको लागि निवेदन",
          value: "completion-application",
          action: "Upload",
          status: "",
          sn: 3
        },
        {
          id: 4,
          name: "सुपरस्ट्रक्चर इजाजत सम्बन्धमा टिप्पणी र आदेश",
          value: "completion-tippani",
          action: "Upload",
          status: "",
          sn: 4
        },
        {
          id: 5,
          name: "सुपरस्ट्रक्चरसम्म निर्माणको सुपरिभेक्षक प्रतिवेदन",
          value: "superstructure-inspect-report",
          action: "Upload",
          status: "",
          sn: 5
        },
        {
          id: 6,
          name: "भवन निर्माण कार्य सम्पन्न प्रमाणपत्र",
          value: "completion-certificate",
          action: "Upload",
          status: "",
          sn: 6
        }
      ]
    };
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
