<template>
  <v-card class="receivedCertificates elevation-12 my-2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        प्राप्त सर्टिफिकेट
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <thead>
        <tr>
          <th class="text-left">Name</th>
          <!-- <th class="text-left registrationtable">Download</th> -->
          <th class="text-left">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>दर्ता रसिद</td>
          <!-- <td><a :href="basePath + plinthCertificate"> Save as Pdf </a></td> -->
          <td>
            <router-link :to="{ name: 'yellowslip', params: { regId: regId } }" target="_blank">
              Print
            </router-link>
          </td>
        </tr>
        <tr v-if="plinthCertificate">
          <td>प्रथम चरणको निर्माण इजाजत पत्र</td>
          <!-- <td><a :href="basePath + plinthCertificate"> Save as Pdf </a></td> -->
          <td>
            <a :href="basePath + plinthCertificate" target="_blank">
              Print
            </a>
          </td>
        </tr>
        <tr v-if="superstructureCertificate">
          <td>दोस्रो चरणको निर्माण इजाजत पत्र</td>
          <!-- <td><a :href="basePath + superstructureCertificate"> Save as Pdf </a></td> -->
          <td>
            <a :href="basePath + superstructureCertificate" target="_blank">
              Print
            </a>
          </td>
        </tr>
        <tr v-if="completionCertificate">
          <td>भवन निर्माण कार्य सम्पन्न प्रमाणपत्र</td>
          <!-- <td><a :href="basePath + completionCertificate"> Save as Pdf</a></td> -->
          <td>
            <a :href="basePath + completionCertificate" target="_blank">
              Print
            </a>
          </td>
        </tr>
      </tbody>
      <!-- </template> -->
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "ReceivedCertificates",
  props: {
    regId: {
      type: Number
    },
    regPhaseId: {
      type: Number
    },
    regDeskId: {
      type: Number
    }
  },
  computed: {},
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      user_role_id: localStorage.getItem("auth_user_role_id"),
      receivedCertificates: null,
      plinthCertificate: null,
      superstructureCertificate: null,
      completionCertificate: null
    };
  },
  mounted() {
    const certificatesUrl = `api/registration/${this.regId}/certificates`;
    this.$axios.get(certificatesUrl).then(response => {
      this.receivedCertificates = response.data.data;
      this.plinthCertificate = this.receivedCertificates["plinth-level-permit"].document_data;
      this.superstructureCertificate = this.receivedCertificates[
        "superstructures-level-permit"
      ].document_data;
      this.completionCertificate = this.receivedCertificates[
        "completion-certificate"
      ].document_data;
    });
  }
};
</script>

<style scoped></style>
