import { render, staticRenderFns } from "./ApplicationstatusPage.vue?vue&type=template&id=3254c8c6&scoped=true&"
import script from "./ApplicationstatusPage.vue?vue&type=script&lang=js&"
export * from "./ApplicationstatusPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3254c8c6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VForm,VSimpleTable,VToolbar,VToolbarTitle})
