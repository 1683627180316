<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-container class="pa-0" dense>
      <v-row class="" dense>
        <v-col xs12 md12 lg12>
          <v-card class="pa-0">
            <v-btn class="primary my-3 mx-3" :to="{ name: 'infrastructuredashboard' }">Back</v-btn>

            <v-btn
              class="primary my-3 mx-3"
              :href="basePath + `/api/bids/bridge-report/pdf/${this.$route.params.regId}`"
            >
              Download bridge report data in pdf
            </v-btn>

            <v-toolbar color="primary " dark>
              <v-col class="d-flex justify-space-around">
                <v-toolbar-title class="text-center justify-center">
                  Bridge Inventory System
                </v-toolbar-title>
              </v-col>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="elevation-12" dense>
        <v-col xs12 md8 lg8>
          <v-card class="nibedanBibaran elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>
                Information
              </v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" class="mx-4 my-5" v-model="valid">
              <!-- <v-row>
                <v-col md="4">
                  <label>Surveyors Name<span>*</span></label>
                </v-col>

                <v-col md="8">
                <v-text-field
                    v-model="newbridgeInfo.name"
                    :rules="[required('Name')]"
                    class="custom-text-field"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Bridge Name <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.name"
                    :rules="[required('Bridge Name')]"
                    label="Bridge Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> वडा नं.<span>*</span></label>
                </v-col>

                <v-col md="8">
                  <v-select
                    v-model="newbridgeInfo.ward_no"
                    label="Select ward"
                    :items="wardName"
                    item-value="id"
                    item-text="ward_name"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> Age<span>*</span></label>
                </v-col>
                <v-col md="8">
                  <v-text-field
                    class="custom-text-field"
                    label="Age"
                    :rules="[required('Age'), maxLength('Age', 3)]"
                    v-model="newbridgeInfo.age"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> Bridge Id<span>*</span></label>
                </v-col>
                <v-col md="8">
                  <v-text-field
                    class="custom-text-field"
                    :rules="[required('bridgeId'), maxLength('bridgeId', 10)]"
                    label="Bridge Id"
                    v-model="newbridgeInfo.bridge_id"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label>No of span<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.no_of_span"
                    class="custom-text-field"
                    label="No of span"
                    :rules="[requiredNumber('No of span'), maxLength('No of span', 2)]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label>Length Unit Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.length_unit_type_id"
                    :rules="[requiredDropdown('Length Type Unit')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Length(m) <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    :rules="[required('Length')]"
                    label="Length"
                    v-model="newbridgeInfo.length"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label>Deck Width Unit Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.deck_width_unit_type_id"
                    :rules="[requiredDropdown('Deck Width Type Unit')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Deck Width(m) <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.deck_width"
                    :rules="[required('Width')]"
                    label="Deck Width"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label>Deck Thickness Unit Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.deck_thickness_unit_type_id"
                    :rules="[requiredDropdown('Deck Thickness Type Unit')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Deck Thickness(m) <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.deck_thickness"
                    :rules="[required('Deck Thickness')]"
                    label="Deck Thickness "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label>Deck Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.deck_type_id"
                    :rules="[required('Deck  Type')]"
                    :items="bridgeDeckType"
                    item-value="id"
                    item-text="bridge_deck_type"
                    :key="id"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label>Abutment Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.abutment_type_id"
                    :rules="[requiredDropdown('Abutment  Type')]"
                    :items="bridgeAbutmentType"
                    item-value="id"
                    item-text="bridge_abutment_type"
                    :key="id"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label>Cross Section Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.cross_section_type_id"
                    :rules="[requiredDropdown('Cross Section  Type')]"
                    :items="bridgeCrossSectionType"
                    item-value="id"
                    item-text="bridge_cross_section_type"
                    :key="id"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label> High Chord Elevation Unit Type <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.high_chord_elevation_unit_type_id"
                    :rules="[requiredDropdown('High Chord Elevation Unit Type')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Elevation of High Chord(m) <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.high_chord_elevation"
                    :rules="[
                      required(' Elevation of High Chord'),
                    ]"
                    label="Elevation of high Chord"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label> Low Chord Elevation Unit Type <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.low_chord_elevation_unit_type_id"
                    :rules="[requiredDropdown('Low Chord Elevation Unit Type')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Elevation of Low Chord(m) <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.low_chord_elevation"
                    :rules="[
                      required(' Elevation of High Chord'),
                    ]"
                    label="Elevation of Low Chord "
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label> No. of Piers <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.no_of_piers"
                    :rules="[
                      required(' No. of piers'),
                    ]"
                    label=" No. of Piers "
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label> Piers c-c Spacing Unit Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.piers_cc_spacing_unit_type_id"
                    :rules="[requiredDropdown('Piers c-c Spacing Unit Type')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label> Piers c-c Spacing <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.piers_cc_spacing"
                    :rules="[
                      required(' Piers c-c Spacing'),
                    ]"
                    label="Piers c-c Spacing"
                  ></v-text-field>
                </v-col>
              </v-row>
              <!-- <v-row>
                <v-col cols="4">
                  <label> Piers width Unit Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.pier_width_unit_type_id"
                    :rules="[requiredDropdown('Piers width Unit Type')]"
                    :items="unitType"
                    item-value="id"
                    item-text="unit_type_en"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label>Pier Width<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.pier_width"
                    :rules="[required(' Pier Width')]"
                    label="Pier Width"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label> Piers Type<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.pier_type_id"
                    :rules="[requiredDropdown('Pier Type')]"
                    :items="bridgePierType"
                    item-value="id"
                    item-text="bridge_pier_type"
                    :key="id"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label> Span Continuity<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.span_continuity_type_id"
                    :rules="[requiredDropdown('Sapan Continuity')]"
                    :items="bridgeSpanContinuityType"
                    item-value="id"
                    item-text="bridge_span_continuity_type"
                    :key="id"
                    dense
                  >
                  </v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col md="4">
                  <label> Downstream xs starting Station<span>*</span></label>
                </v-col>
                <v-col md="8">
                  <v-text-field
                    v-model="newbridgeInfo.downstream_station"
                    class="custom-text-field"
                    label="Downstream xs starting Station"
                    :rules="[
                      required(' Downstream xs starting Station'),
                    ]"
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <label>Upstream xs starting station <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newbridgeInfo.upstream_station"
                    :rules="[
                      required(' Upstream xs starting station'),
                    ]"
                    label="Upstream Station"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> Type of Road Bridge<span>*</span></label>
                </v-col>
                <v-col md="8">
                  <v-select
                    v-model="newbridgeInfo.bridge_road_type_id"
                    :rules="[requiredDropdown('Type of Road Bridge')]"
                    :items="bridgeRoadType"
                    item-value="id"
                    item-text="bridge_road_type"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  Type of Trail Bridge
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newbridgeInfo.bridge_trail_type_id"
                    :rules="[requiredDropdown('Type of Trail Bridge')]"
                    :items="bridgeTrailType"
                    item-value="id"
                    item-text="bridge_trail_type"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  Co-ordinate
                </v-col>
                <v-col cols="8">
                  <v-col cols="8">
                    {{ mapCordinate.latitude }}, {{ mapCordinate.longitude }}
                  </v-col>
                </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar.show"
                :bottom="y === 'bottom'"
                :color="snackbar.color"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="3000"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
              >
                {{ successfulMessage }}
              </v-snackbar>
              <v-btn
                primary
                v-on:click="save"
                v-if="isPostAuthorized"
                class="primary"
                :disabled="!valid"
                >Submit</v-btn
              >

              <br />
            </v-form>
            <card
              :url="apiUrl"
              :column-header="headerList"
              :href="href"
              :action-list="actionList"
              :photoL-list="photoView"
              v-on:editdetail="edit"
            />
          </v-card>
        </v-col>
        <v-col xs12 md4 lg4>
          <inventory-map class="order-2" cordinateType="singleCordinate" v-model="mapCordinate" />
          <photographs-upload @file-upload="newbridgeInfo.photo = $event" />
          <gallery :photo-list="PhotoList" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Login",
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      isFormLoading: false,
      headerList: ["name", "ward_no", "bridge_id", "deck_width", "no_of_span", "id"],
      href: ["id"],
      actionList: ["Edit"],
      PhotoList: [],
      photoView: ["photo"],
      records: [],
      files: [],
      mapCordinate: {},
      apiUrl: `api/bids/infrastructure/bridge`,
      name: "",

      wardName: [],
      unitType: [],
      bridgeDeckType: [],
      bridgeAbutmentType: [],
      bridgeCrossSectionType: [],
      bridgePierType: [],
      bridgeSpanContinuityType: [],
      bridgeTrailType: [],
      bridgeRoadType: [],
      bridgeUseType: [],
      isPostAuthorized: true,

      formData: new FormData(),
      bridgeInfo: [],
      newbridgeInfo: {
        id: 0,
        name: "",
        age: "",
        bridge_id: "",
        latitude: 0,
        longitude: 0,
        no_of_span: "",
        length_unit_type_id: 1,
        length: "",
        deck_width_unit_type_id: 1,
        deck_width: "",
        deck_thickness_unit_type_id: 1,
        deck_thickness: "",
        deck_type_id: "",
        abutment_type_id: "",
        cross_section_type_id: "",
        high_chord_elevation_unit_type_id: 1,
        high_chord_elevation: "",
        low_chord_elevation_unit_type_id: 1,
        low_chord_elevation: "",
        no_of_piers: "",
        piers_cc_spacing_unit_type_id: 1,
        piers_cc_spacing: "",
        pier_width_unit_type_id: 1,
        pier_width: "",
        pier_type_id: "",
        span_continuity_type_id: "",
        downstream_station: "",
        upstream_station: "",
        bridge_use_type_id: "",
        bridge_road_type_id: "",
        bridge_trail_type_id: "",
        photo: []
      },
      valid: false,
      maxLength(propertyType, maxLength) {
        return v =>
          (v && String(v).length <= maxLength) ||
          `${propertyType} must be less than ${maxLength} digits`;
      },
      required(propertyType) {
        return v => (v && String(v).length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return v => v || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return v => (v && v > 0) || ` Only number is allowed`;
      },

      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      bridge_deck_type: [],
      roadCordinate: [],
      type_id: "",
      user_role_id: localStorage.getItem("auth_user_role_id"),
      id: "single",
      color: { color: "#3F3C3C" }
    };
  },
  computed: {
    isLoading() {
      if (
        this.bridgeDeckType.length === 0 ||
        this.bridgeAbutmentType.length === 0 ||
        this.bridgeCrossSectionType.length === 0 ||
        this.bridgePierType.length === 0 ||
        this.bridgeSpanContinuityType.length === 0 ||
        this.bridgeTrailType.length === 0 ||
        this.bridgeUseType.length === 0 ||
        this.bridgeRoadType.length === 0 ||
        this.wardName.length === 0 ||
        this.unitType.length === 0
      ) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.selectBoxApi();
  
    this.bridgeInfoDetail();
  },

  methods: {
    selectBoxApi() {
      const obj = this;
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then(response => {
          obj.wardName = response.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeDeckTypeUrl = "api/bids/select-box/bridge-deck";
      VueAxios.get(bridgeDeckTypeUrl)
        .then(response => {
          obj.bridgeDeckType = response.data;
          // console.log(obj.bridgeDeckType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeAbutmentTypeUrl = "api/bids/select-box/bridge-abutment";
      VueAxios.get(bridgeAbutmentTypeUrl)
        .then(response => {
          obj.bridgeAbutmentType = response.data;
          // console.log(obj.bridgeAbutmentType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeCrossSectionUrl = "api/bids/select-box/bridge-cross-section";
      VueAxios.get(bridgeCrossSectionUrl)
        .then(response => {
          obj.bridgeCrossSectionType = response.data;
          // console.log(obj.bridgeCrossSectionType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgePierTypeUrl = "api/bids/select-box/bridge-pier";
      VueAxios.get(bridgePierTypeUrl)
        .then(response => {
          obj.bridgePierType = response.data;
          // console.log(obj.bridgePierType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeSpanContinuityUrl = "api/bids/select-box/bridge-span-continuity";
      VueAxios.get(bridgeSpanContinuityUrl)
        .then(response => {
          obj.bridgeSpanContinuityType = response.data;
          // console.log(obj.bridgeSpanContinuityType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeTrailUrl = "api/bids/select-box/bridge-trail";
      VueAxios.get(bridgeTrailUrl)
        .then(response => {
          obj.bridgeTrailType = response.data;
          // console.log(obj.bridgeTrailType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeRoadUrl = "api/bids/select-box/bridge-road";
      VueAxios.get(bridgeRoadUrl)
        .then(response => {
          obj.bridgeRoadType = response.data;
          // console.log(obj.bridgeRoadType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const bridgeUseUrl = "api/bids/select-box/bridge-use";
      VueAxios.get(bridgeUseUrl)
        .then(response => {
          obj.bridgeUseType = response.data;
          // console.log(obj.bridgeUseType);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const unitTypeUrl = "api/bids/select-box/unit-type";
      VueAxios.get(unitTypeUrl)
        .then(response => {
          obj.unitType = response.data;
          // console.log(obj.buildingPermanent);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    bridgeInfoDetail() {
      const obj = this;
      // debugger;
      const url = `/api/bids/infrastructure/bridge/${this.$route.params.regId}`;
      VueAxios.get(url)
        .then(response => {
          // debugger;
          obj.newbridgeInfo = response.data.data;
          obj.PhotoList = response.data.data.photos;
          obj.mapCordinate = {
            longitude: response.data.data.longitude,
            latitude: response.data.data.latitude
          };
          console.log(obj.newbridgeInfo);

          // obj.selectWard(obj.wardName, obj.ward_no,obj.newgeneralInfo.ward_no);
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      // debugger;
      this.newbridgeInfo.latitude = this.mapCordinate.latitude;
      this.newbridgeInfo.longitude = this.mapCordinate.longitude;
      const formData = new FormData();
      const files = this.newbridgeInfo.photo;

       if (
        files === "null" ||
        files === 0 ||
        files === undefined
        ) {
          //No file selected 
          console.log("File is not selected");
        }
        else {
           if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
            for (let i = 0; i < files.length; i += 1) {
              formData.append("photo[]", files[i], files[i].name);
            }
          }
        }
     
      formData.append("name", this.newbridgeInfo.name);
      formData.append("ward_no", this.newbridgeInfo.ward_no);
      formData.append("age", this.newbridgeInfo.age);
      formData.append("bridge_id", this.newbridgeInfo.bridge_id);
      formData.append("latitude", this.newbridgeInfo.latitude);
      formData.append("longitude", this.newbridgeInfo.longitude);
      formData.append("no_of_span", this.newbridgeInfo.no_of_span);
      formData.append("length_unit_type_id", this.newbridgeInfo.length_unit_type_id);
      formData.append("length", this.newbridgeInfo.length);
      formData.append("deck_width_unit_type_id", this.newbridgeInfo.deck_width_unit_type_id);
      formData.append("deck_width", this.newbridgeInfo.deck_width);

      formData.append(
        "deck_thickness_unit_type_id",
        this.newbridgeInfo.deck_thickness_unit_type_id
      );
      formData.append("deck_thickness", this.newbridgeInfo.deck_thickness);
      formData.append("deck_type_id", this.newbridgeInfo.deck_type_id);
      formData.append("abutment_type_id", this.newbridgeInfo.abutment_type_id);
      formData.append("cross_section_type_id", this.newbridgeInfo.cross_section_type_id);
      formData.append(
        "high_chord_elevation_unit_type_id",
        this.newbridgeInfo.high_chord_elevation_unit_type_id
      );
      formData.append("high_chord_elevation", this.newbridgeInfo.high_chord_elevation);
      formData.append(
        "low_chord_elevation_unit_type_id",
        this.newbridgeInfo.low_chord_elevation_unit_type_id
      );
      formData.append("low_chord_elevation", this.newbridgeInfo.low_chord_elevation);
      formData.append("no_of_piers", this.newbridgeInfo.no_of_piers);
      formData.append(
        "piers_cc_spacing_unit_type_id",
        this.newbridgeInfo.piers_cc_spacing_unit_type_id
      );
      formData.append("piers_cc_spacing", this.newbridgeInfo.piers_cc_spacing);
      formData.append("pier_width_unit_type_id", this.newbridgeInfo.pier_width_unit_type_id);
      formData.append("pier_width", this.newbridgeInfo.pier_width);
      formData.append("pier_type_id", this.newbridgeInfo.pier_type_id);
      formData.append("span_continuity_type_id", this.newbridgeInfo.span_continuity_type_id);
      formData.append("downstream_station", this.newbridgeInfo.downstream_station);
      formData.append("upstream_station", this.newbridgeInfo.upstream_station);
      formData.append("bridge_use_type_id", this.newbridgeInfo.bridge_use_type_id);
      formData.append("bridge_road_type_id", this.newbridgeInfo.bridge_road_type_id);
      formData.append("bridge_trail_type_id", this.newbridgeInfo.bridge_trail_type_id);
      console.log(formData);

      const obj = this;
      if (
        this.newbridgeInfo.id === "null" ||
        this.newbridgeInfo.id === 0 ||
        this.newbridgeInfo.id === undefined
      ) {
        formData.append("_method", "POST");
        const saveUrl = `api/bids/infrastructure/bridge`;
        ob.isFormLoading = true;
        VueAxios.post(saveUrl, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(response => {
            obj.isFormLoading = false;

            if (response.status === 200 || response.status === 201) {
              this.newbridgeInfo = response.data.data;

              // this.$toastr.s("All images uplaoded successfully");
              this.newbridgeInfo.photo = [];
              // this.photo = [];
              this.snackbar.show = true;
              this.successfulMessage = "Record added successfully.";
              
              this.$router.push({ name: "infrastructuredashboard" });
              // this.$refs.form.reset();
            }
          })
          .catch(error => {
            obj.isFormLoading = false;
            this.snackbar.show = true;
            this.successfulMessage = "Save Failed.";
            console.log(`api error:${error}`);
          });
      } else {        
        formData.append("_method", "PUT");
        const eurl = `/api/bids/infrastructure/bridge/${this.newbridgeInfo.id}`;
        // obj.isFormLoading = true;
         VueAxios.post(eurl, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
          .then(response => {
            obj.isFormFLoading = false;
            // debugger;
            
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
              obj.isFormFLoading = false;
            }

            console.log(response);
          })
          .catch(error => {
            this.snackbar.show = true;
            this.successfulMessage = "Save failed.";
            obj.isFormFLoading = false;
            console.log(error);
          });
      }
    },
    edit(id) {
      // debugger;
      const obj = this;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/bids/infrastructure/bridge/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        obj.newbridgeInfo = response.data.data;
        obj.PhotoList = obj.newbridgeInfo.photos;
      });
    }
  }
};
</script>
<style scoped>
label span {
  color: red;
}
.card {
  padding: 15px;
}
</style>
