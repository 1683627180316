<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}
.document td,
.document th {
  padding: 8px;
}
.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
.forward_button {
  background-color: #008cba;
}
</style>
<template>
  <div id="app">
    <v-card class="d-flex flex-row md-3">
      <v-card class="d-flex flex-column col-6">
        <NibedanBibaran
          class="order-1"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 2 && results.desk_user_role_id == user_role_id && results.phase_id != 5
          "
        />
        <PrabhidhikBibaran
          class="order-2"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 4 && results.desk_user_role_id == user_role_id && results.phase_id == 1
          "
        />
        <ReceivedCertificates class="order-3" :regId="this.$route.params.id" />
      </v-card>
      <v-card class="d-flex flex-column col-6">
        <v-card class="documents registrationDocuments elevation-12 my-2">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              कागजातहरु
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in documents" :key="item.name" class="registrationtable">
                <td>{{ item.name }}</td>
                <td>
                  <span
                    v-if="
                      user_role_id == 2 &&
                        results.desk_user_role_id == user_role_id &&
                        results.phase_id == 1
                    "
                  >
                    <router-link
                      :to="{
                        name: 'documentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >Upload</router-link
                    >
                  </span>
                  <span v-else>
                    <router-link
                      :to="{
                        name: 'documentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >View</router-link
                    >
                  </span>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
          <RegistrationAction
            v-if="user_role_id == results.desk_user_role_id && results.phase_id !== 5"
            :regPhaseId="this.results.phase_id"
            :regDeskId="this.results.desk_user_role_id"
          />
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NibedanBibaran from "../subComponents/NibedanBibaran.vue";
import PrabhidhikBibaran from "../subComponents/PrabhidhikBibaran.vue";
import ReceivedCertificates from "../subComponents/ReceivedCertificates.vue";
import RegistrationAction from "../subComponents/RegistrationAction.vue";
//   import Application from '../../../modules/tabbedElements/components/Application.vue';

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function
    },
    id: {
      type: Number
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  components: {
    NibedanBibaran,
    PrabhidhikBibaran,
    ReceivedCertificates,
    RegistrationAction
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(url).then(response => {
      // debugger;
      this.results = response.data.data;
      // console.log(response.data.data);
    });
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      nibedanBibaranOrder: "order-1",
      prabhidhikBibaranOrder: "order-2",
      results: [],
      documents: [
        {
          id: 1,
          name: " १।     जग्गा धनी प्रमाणपत्र प्रतिलिपि",
          value: "jaggadhani",
          action: "Upload",
          status: "",
          sn: 1
        },
        {
          id: 2,
          name: "२।     चालु आर्थिक बर्षको मालपोत तिरेको रसिदको प्रतिलिपि",
          value: "malpot",
          action: "Upload",
          status: "completed",
          sn: 2
        },
        {
          id: 3,
          name: "३।     ज.ध दर्ता प्रमाण पुर्जा",
          value: "jaggadhani-purja",
          action: "Upload",
          status: "",
          sn: 3
        },
        {
          id: 4,
          name: "४।     नागरिकता प्रमाणपत्रको  प्रतिलिपि",
          value: "citizenship",
          action: "Upload",
          status: "",
          sn: 4
        },
        {
          id: 5,
          name: " ५।     कि.नं.स्पष्ट भएको नापी प्रमाणित नक्शा (ब्लु प्रिन्ट)",
          value: "blueprint",
          action: "Upload",
          status: "",
          sn: 5
        },
        {
          id: 6,
          name: "६।     डिजाईनरको इजाजतपत्रको नबिकरण सहितको फोटोकपी (सरोकारवालाबाट प्रमाणित)",
          value: "designer-certificate",
          action: "Upload",
          status: "",
          sn: 6
        },
        {
          id: 7,
          name:
            "७।     मञ्जुरी लिई बनाउने भएमा नक्शा वालाले कानुन शाखाको रोहवरमा भएको मञ्जुरीनामाको सक्कल",
          value: "manjurinama",
          action: "Upload",
          status: "",
          sn: 7
        },
        {
          id: 8,
          name: "८।     वारेश राखि नक्शा पास गर्ने भए वारिसको प्रमाणितको प्रतिलिपि",
          value: "warish",
          action: "Upload",
          status: "",
          sn: 8
        },
        {
          id: 9,
          name: "९।     नक्सा बनाउने वा भवन डिजाईन गर्ने प्राविधिकद्धारा मन्जुरी पत्र",
          value: "designer-manjurinama",
          action: "Upload",
          status: "",
          sn: 9
        },
        {
          id: 10,
          name: "१०।     भवनको नक्सा",
          value: "map",
          action: "Upload",
          status: "",
          sn: 10
        },
        {
          id: 11,
          name: "११।     घरधनिको फोटो",
          value: "photo",
          action: "Upload",
          status: "",
          sn: 11
        }
      ]
    };
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
.registrationLevel {
  height: 100% !important;
}
</style>
