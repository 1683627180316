<template>
  <div>
    <!-- <v-card class="fill-width">
            <v-container>
            <div class="row col-sm-12">
                <div class="col-sm-1">
                <router-link :to="{ name: 'home' }">
                    <img :src="require('@/assets/images/nepal-govt.png')" width="80px" />
                </router-link>
                </div>
                <div class="col-sm-5 text-left">
                <h2>भवन निर्माण अनुमति प्रकृया</h2>
                </div>

                <div class="col-sm-5 " >
                <h3 class="logoutdiv">
                    <span class=""> Logged in as: </span>
                    <span v-if="user.user_role_id == 1">
                    {{ user.username }}
                    </span>
                    <span
                    v-else-if="
                        user.user_role_id == 2 ||
                        user.user_role_id == 3 ||
                        user.user_role_id == 4 ||
                        user.user_role_id == 5 ||
                        user.user_role_id == 6
                    "
                    >{{ user.username }} &nbsp; | &nbsp;
                    </span>
                    <span class="a"
                    >Profile &nbsp; | &nbsp; <v-btn class="primary" v-on:click="logout">Logout</v-btn></span
                    >
                </h3>
                </div>
                <div class="col-sm-1 left-logo">
                <router-link :to="{ name: 'home' }">
                    <img
                    src="images/nset.png"
                    height="60px"
                    width="200px"
                    />
                </router-link>
                </div>
            </div>
            <v-row class="col-sm-12" >
                <v-layout wrap>
                <v-flex md2>
                    <router-link :to="{ name: 'home' }">Dashboard </router-link>
                </v-flex>
                <v-flex md2 v-if="userRoleId == 1">
                    <router-link :to="{ name: 'municipalitydetail' }"
                    >Municipality Details
                    </router-link>
                </v-flex>
                <v-flex md2 v-if="userRoleId == 1">
                    <router-link :to="{ name: 'mason' }">Mason</router-link>
                </v-flex>
                <v-flex md2 v-if="userRoleId == 1">
                    <router-link :to="{ name: 'consultancy' }">Consultancy </router-link>
                </v-flex>
                <v-flex md2>
                    <router-link :to="{ name: 'report' }">Report </router-link>
                </v-flex>

                <v-flex md2>
                    <router-link :to="{ name: 'applicationstatuspage' }">
                    Application Status
                    </router-link>
                </v-flex>
                <v-flex md2>
                    Help
                </v-flex>
                </v-layout>
            </v-row>
            </v-container>
        </v-card> -->

    <nav>
      <!-- Start of app toolbar -->
      <v-toolbar app class="primary" dark>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
        <v-toolbar-title class="headline text-uppercase" v-if="is_bps_access == 1">
          <img :src="require('@/assets/images/nepal-govt.png')" width="60px" />
          भवन निर्माण अनुमति प्रकृया
        </v-toolbar-title>
        <!-- <v-toolbar-title class="headline text-uppercase" v-else>
          <img :src="require('@/assets/images/nepal-govt.png')" width="60px" />
          Building Inventory System
        </v-toolbar-title> -->

        <router-link
          :to="{ name: 'inventorydashboard' }"
          class="white--text font-weight-thin text-xl-h5">
          <img :src="require('@/assets/images/nepal-govt.png')" width="60px" />
          Building & Infrastructure Inventory
        </router-link>

        <v-spacer></v-spacer>
        <!-- <v-toolbar-items class="hidden-sm-and-down" v-for="item in getMenu" :key="item.link" link>
          <v-btn color="primary" flat :to="{ name: item.link }" exact>{{ item.title }}</v-btn>
        </v-toolbar-items> -->
        <v-menu transition="slide-x-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-btn large depressed="" class="primary right m-2 subtitle-1" v-bind="attrs" v-on="on" v-for="item in getMenu.filter(item => item.name === 'report')"
              :key="item.link"
              :to="{ name: item.link }">
               {{ item.title }}
            </v-btn>

            <v-btn large depressed="" class="primary right m-2 subtitle-1" v-bind="attrs" v-on="on" v-for="item in getMenu.filter(item => item.name === 'dashboard')"
              :key="item.link"
              :to="{ name: item.link }">
               {{ item.title }}
            </v-btn>
          </template>
          <!-- <v-list>
            <v-list-item
              v-for="item in getMenu.filter(item => item.name === 'dashboard')"
              :key="item.link"
              :to="{ name: item.link }"
            >
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list> -->
        </v-menu>
        <!-- <v-menu transition="slide-x-transition" bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn large depressed="" class="primary right m-2 subtitle-1" v-bind="attrs" v-on="on">
              Report
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in getMenu.filter(item => item.name === 'report')"
              :key="item.link"
              :to="{ name: item.link }"
            >
              <v-list-item-title> {{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu> -->
        <!-- <span   v-for="item in getMenu.filter(
                (item) => item.icon === 'report'
              )" :key="item.link"> -->
        <v-btn
          class="hidden-sm-and-down"
          text
          v-for="item in getMenu.filter(item => item.name === 'application_status')"
          :key="item.link"
        >
          <router-link :to="{ name: item.link }" class="link">Application Status</router-link>
        </v-btn>
        <v-btn
          class="hidden-sm-and-down"
          text
          v-for="item in getMenu.filter(item => item.name === 'municipality')"
          :key="item.link"
        >
          <router-link :to="{ name: item.link }" class="link">Municipality</router-link>
        </v-btn>
        <v-btn
          class="hidden-sm-and-down"
          text
          v-for="item in getMenu.filter(item => item.name === 'consultancy')"
          :key="item.link"
        >
          <router-link :to="{ name: item.link }" class="link">Consultancy</router-link>
        </v-btn>
        <!-- </span> -->
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }" v-if="is_bps_access == 1">
            <v-avatar class="mx-4" size="36" dark v-on="on">
              <v-icon @click="onClick()" large>mdi-email</v-icon>
            </v-avatar>
          </template>

          <v-card>
            <v-list v-if="is_bps_access == 1">
              <v-list-item
                v-for="message in messages"
                :key="message.registration.reg_id"
                :to="{
                  name: 'application_tab',
                  params: { id: message.reg_id }
                }"
              >
                <v-list-item-avatar size="24" dark v-on="on">
                  <v-icon medium>mdi-email</v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    >File of
                    {{ message.registration.houseowner_name_np }}
                  </v-list-item-title>
                  <!-- <v-list-item-subtitle>{{user.username}}</v-list-item-subtitle> -->
                  <v-list-item-subtitle
                    ><strong>
                      Message from {{ message.created_by.username }}:
                      {{ message.message }}
                    </strong></v-list-item-subtitle
                  >
                </v-list-item-content>
              </v-list-item>
              <!-- <v-divider></v-divider> -->
            </v-list>
          </v-card>
        </v-menu>
        <v-spacer></v-spacer>
        <v-menu
          open-on-hover
          v-model="menu"
          :close-on-content-click="false"
          :nudge-width="200"
          offset-y
          transition="slide-y-transition"
        >
          <template v-slot:activator="{ on }">
            <v-avatar class="mx-4 info" size="36" dark v-on="on">
              <!-- PB -->
              {{ usernameInitials }}
            </v-avatar>
          </template>

          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-avatar class="info" size="36" dark v-on="on">
                  {{ usernameInitials }}
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title>{{ userFullName }}</v-list-item-title>
                  <!-- <v-list-item-subtitle>{{user.username}}</v-list-item-subtitle> -->
                  <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <!-- <v-btn text @click="menu = false">Cancel</v-btn> -->
              <v-btn class="error" dark v-on:click="logout">Logout</v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-toolbar>
      <!-- End of app toolbar -->

      <!-- Start of mobile side menu -->
      <v-navigation-drawer app v-model="drawer" left class="primary accent-4" dark temporary>
        <v-flex>
          <v-flex class="justify-start">
            <v-app-bar-nav-icon @click.stop="drawer = !drawer"> </v-app-bar-nav-icon>
            <img class="mx-9 my-3" :src="require('@/assets/images/nepal-govt.png')" width="100px" />
          </v-flex>
          <v-list-item-title class="text-white mx-9" v-if="is_bps_access == 1">
            भवन निर्माण अनुमति प्रकृया
          </v-list-item-title>
          <v-list-item-title class="text-white mx-9" v-else>
            Building Inventory System
          </v-list-item-title>
        </v-flex>
        <v-divider color="white"></v-divider>
        <v-list>
          <v-list-item v-for="item in getMenu" :key="item.link" :to="{ name: item.link }">
            <v-list-item-icon>
              <v-icon v-on="on"> mdi-{{ item.icon }} </v-icon>
            </v-list-item-icon>
            {{ item.title }}
            <!-- <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content> -->
          </v-list-item>
        </v-list>

        <template v-slot:append>
          <div class="pa-2">
            <v-btn class="error" block v-on:click="logout">Logout</v-btn>
          </div>
        </template>
      </v-navigation-drawer>

      <!-- End of mobile side menu -->
    </nav>
    <!-- End of Navigation -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      menu: "",
      is_bps_access: window.localStorage.getItem("is_bps_access"),
      is_bids_access: window.localStorage.getItem("is_bids_access"),
      menuPermission: [],
      on: "",
      userRoleId: localStorage.getItem("auth_user_role_id"),
      items: [
        {
          title: "Dashboard",
          name: "dashboard",
          icon: "view-dashboard",
          link: "home",
          permission: "bps"
        },
        {
          title: "Dashboard",
          name: "dashboard",
          icon: "view-dashboard",
          link: "dashboard",
          permission: "bids"
        },
        {
          title: "BPS Report",
          name: "report",
          icon: "chart-pie",
          link: "report",
          permission: "bps"
        },
        {
          title: "BIS Report",
          name: "report",
          icon: "chart-pie",
          link: "resultAnalysis",
          permission: "bids"
        },
        {
          title: "IIS Report",
          name: "report",
          icon: "chart-pie",
          link: "infrastructureResultAnalysis",
          permission: "bids"
        },
        {
          title: "Application Status",
          name: "application_status",
          icon: "folder",
          link: "applicationstatuspage",
          permission: "bps"
        },
        {
          title: "Municipality",
          name: "municipality",
          icon: "bank",
          link: "municipalitydetail",
          permission: "bps"
        },
        {
          title: "Consultancy",
          name: "consultancy",
          icon: "bank",
          link: "consultancy",
          permission: "bps"
        }
      ],

      user: [],
      messages: [
        {
          registration: {
            reg_id: 0,
            houseowner_name_np: "test"
          },
          message: "message woohooo",
          created_by: {
            username: "username"
          }
        }
      ],
      usernameInitials: "",
      userFullName: "",
      drawer: false
    };
  },
  created() {
    // debugger;
    if (this.is_bps_access == 1) {
      this.menuPermission.push("bps");
    }
    if (this.is_bids_access == 1) {
      this.menuPermission.push("bids");
    }
  },
  mounted() {
    const currentuser_url = "api/profile";
    this.$axios
      .get(currentuser_url)
      .then(response => {
        // debugger;
        if (response.status === 200) {
          this.user = response.data;
          this.usernameInitials = `${this.user.first_name.charAt(0).toUpperCase() +
            this.user.last_name.charAt(0).toUpperCase()}`;
          this.userFullName = `${this.user.first_name} ${this.user.last_name}`;
          this.userRoleId = this.user.user_role_id;
          // console.log(response.data);
          // debugger;
        }
      })
      .catch(error => {
        console.log("Navbar error");
        console.error(error);
        if (error.status !== 500) {
          window.localStorage.clear();
          this.$router.push("/login");
        }
      });

    // const messages_url = "api/messages";
    // this.$axios
    //   .get(messages_url)
    //   .then(response => {
    //     // debugger;
    //     if (response.status === 200) {
    //       this.messages = response.data.data;
    //     }
    //   })
    //   .catch(error => {
    //     console.log("Navbar error");
    //     console.error(error);
    //   });
  },
  computed: {
    getMenu() {
      return this.items.filter(item => {
        return this.menuPermission.some(permission => item.permission.includes(permission));
      });
    }
  },
  methods: {
    logout() {
      // delete VueAxios.defaults.headers.common["Authorization"];
      window.localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>

<style scoped>
.navigation {
  position: relative;
  height: 15vh !important;
  width: 100vw;
  /*
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw; */
}

.logout {
  width: 120px;
}
.link {
  text-decoration: none !important;
  color: white !important;
}
</style>
