<template>
  <div class="container">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>

    <v-card class="d-flex flex-column">
      <v-card class="d-flex flex-column">
        <v-toolbar color="primary" dark flat>
          <v-toolbar-title v-if="newgeneralInfo.id"> General Information </v-toolbar-title>
          <v-toolbar-title v-else> Building Information </v-toolbar-title>
        </v-toolbar>

        <v-form ref="form" class="mx-5 my-5" v-model="valid">
          <v-row>
            <v-col cols="12" sm="6">
              <label> तथ्याङ्क भर्नेको नाम <span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.surveyor_name"
                :rules="[required('सर्वेक्षकको नाम')]"
                label="सर्वेक्षकको नाम"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> घरधनीको नाम/institution<span> *</span></label>

              <v-text-field
                v-model="newgeneralInfo.houseowner_name"
                :rules="[required('घरधनीको नाम')]"
                label="घरधनीको नाम/institution"
                filled
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> लिंग<span> *</span></label>
              <v-select
                v-model="newgeneralInfo.salutation_type_id"
                :rules="[requiredDropdown('लिंग')]"
                label="Select लिंग"
                :items="genderType"
                item-value="id"
                item-text="gender_type"
                :key="id"
                dense
                shaped
                filled
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <label> घरधनीको सम्पर्क नं.<span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.phone_no"
                label="घरधनीको सम्पर्क नं"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> घरधनीको नागरिकता न.<span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.citizenship_no"
                label="घरधनीको नागरिकता न"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> वडा नं.<span> *</span></label>
              <v-select
                v-model="newgeneralInfo.ward_no"
                :rules="[requiredDropdown('वडा नं')]"
                label="Select ward"
                :items="wardName"
                item-value="id"
                item-text="ward_name"
                :key="id"
                dense
                shaped
                filled
              ></v-select>
            </v-col>

            <v-col cols="12" sm="6">
              <label> ठेगाना (टोल)<span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.address"
                :rules="[required('ठेगाना (टोल)')]"
                label="ठेगाना (टोल)"
                outlined
                shaped
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> सडकको नाम वा नं.<span> *</span></label>
              <v-text-field
                label="सडकको नाम"
                :rules="[required('सडकको नाम वा नं.')]"
                v-model="newgeneralInfo.street_name"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> सडक नं.</label>
              <v-text-field
                label="सडक नं."
                v-model="newgeneralInfo.street_no"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> घर नं.<span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.house_no"
                label="घर नं."
                outlined
                shaped
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label for="">घरको नक्शा पास गरेको छ कि छैन <span> *</span></label>
              <v-radio-group dense v-model="newgeneralInfo.is_map_verified" :mandatory="true">
                <v-radio label="छ" v-bind:value="1"></v-radio>
                <v-radio label="छैन" v-bind:value="0"></v-radio>
              </v-radio-group>
            </v-col>

            <v-col cols="12" sm="6">
              <v-col md="6"> नक्शा पास गरेको साल (B.S) </v-col>
              <v-select
                v-model="newgeneralInfo.map_verified_date_np"
                label="Select Year"
                :items="getYear(2000, 2078)"
                outlined
                shaped
              ></v-select>
            </v-col>

            <v-col cols="12" sm="12">
              <label> निर्माणको लागत<span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.construction_cost"
                :rules="[required('निर्माणको लागत'), maxLength('निर्माणको लागत', 15)]"
                label="निर्माणको लागत"
                outlined
                shaped
                filled
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="12">
              <label> घरमा कुन कुन प्रकारका ईन्धनहरु प्रयोग र भण्डारण गरिन्छ?<span> *</span></label>
              <v-row>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_kaath" />
                  <label> &nbsp; &nbsp; काठ </label>
                </v-col>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_matitel" />
                  <label> &nbsp; &nbsp; पेट्रोल, मट्टितेल, एल.पि.जि ग्यास </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_gobar_gas" />
                  <label> &nbsp;&nbsp; गोवर ग्यास </label>
                </v-col>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_madira" />
                  <label> &nbsp;&nbsp; मदिरा </label>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_heater" />
                  <label> &nbsp;&nbsp; हिटर </label>
                </v-col>
                <v-col cols="12" md="4">
                  <input type="checkbox" v-model="newgeneralInfo.fuel_other" />
                  <label> &nbsp;&nbsp; अन्य </label>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <!-- <v-row>
            <v-col md="4">
              <label> तथ्याङ्क भर्नेको नाम <span> *</span></label>
            </v-col>
            <v-col cols="4">
              <v-text-field
                v-model="newgeneralInfo.surveyor_name"
                :rules="[required('सर्वेक्षकको नाम')]"
                label="सर्वेक्षकको नाम"
                class=" "
                clearable
                filled
                ref="dateField"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label> घरधनीको नाम/institution<span> *</span></label>
            </v-col>

            <v-col cols="4">
              <v-text-field
                v-model="newgeneralInfo.houseowner_name"
                :rules="[required('घरधनीको नाम')]"
                label="घरधनीको नाम"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <label> लिंग<span> *</span></label>
            </v-col>

            <v-col cols="4">
              <v-select
                v-model="newgeneralInfo.salutation_type_id"
                :rules="[requiredDropdown('लिंग')]"
                label="Select लिंग"
                :items="genderType"
                item-value="id"
                item-text="gender_type"
                :key="id"
                dense
                filled
              ></v-select>
            </v-col>
          </v-row>

          <v-row>
            <v-col md="4">
              <label> घरधनीको सम्पर्क नं.<span> *</span></label>
            </v-col>
            <v-col md="4">
              <v-text-field
                v-model="newgeneralInfo.phone_no"
                label="घरधनीको सम्पर्क नं"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4"> घरधनीको नागरिकता न </v-col>

            <v-col md="4">
              <v-text-field
                v-model="newgeneralInfo.citizenship_no"
                label="घरधनीको नागरिकता न"
                dense
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> वडा नं.<span> *</span></label>
            </v-col>

            <v-col md="4">
              <v-select
                v-model="newgeneralInfo.ward_no"
                :rules="[requiredDropdown('वडा नं')]"
                label="Select ward"
                :items="wardName"
                item-value="id"
                item-text="ward_name"
                :key="id"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> ठेगाना (टोल)<span> *</span></label>
            </v-col>

            <v-col md="4">
              <v-text-field
                required
                v-model="newgeneralInfo.address"
                :rules="[required('ठेगाना (टोल)')]"
                label="ठेगाना (टोल)"
                dense
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> सडकको नाम वा नं.<span> *</span></label>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="सडकको नाम"
                :rules="[required('सडकको नाम वा नं.')]"
                v-model="newgeneralInfo.street_name"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> सडक नं.</label>
            </v-col>

            <v-col cols="12" md="4">
              <v-text-field
                label="सडक नं."
                v-model="newgeneralInfo.street_no"
                filled
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> घर नं.<span> *</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newgeneralInfo.house_no" label="घर नं." dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <label> घरको नक्शा पास गरेको छ कि छैन <span> *</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <v-radio-group dense v-model="newgeneralInfo.is_map_verified" :mandatory="true">
                <v-radio label="छ" v-bind:value="1"></v-radio>
                <v-radio label="छैन" v-bind:value="0"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row v-if="newgeneralInfo.is_map_verified">
            <v-col md="4"> नक्शा पास गरेको मिति </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="newgeneralInfo.map_verified_date_np"
                label="Select Year"
                :items="getYear(2000, 2078)"
                dense
                filled
              ></v-select>
            </v-col>
          </v-row>
          <v-row hidden>
            <v-col md="4"> नक्शा नं. </v-col>
            <v-col cols="12" md="4">
              <v-text-field v-model="newgeneralInfo.map_no" label="नक्शा नं." dense></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <label> निर्माण सम्पन्न मिति<span> *</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="newgeneralInfo.construction_completion_date_np"
                :rules="[requiredDropdown('निर्माण सम्पन्न मिति')]"
                label="Select Year"
                :items="getYear(2000, 2078)"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <label> निर्माणको लागत<span> *</span></label>
            </v-col>
            <v-col cols="12" md="4">
              <v-text-field
                v-model="newgeneralInfo.construction_cost"
                :rules="[required('निर्माणको लागत'), maxLength('निर्माणको लागत', 15)]"
                label="निर्माणको लागत"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <label> घरमा कुन कुन प्रकारका ईन्धनहरु प्रयोग र भण्डारण गरिन्छ?<span> *</span></label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_kaath" />
              <label> &nbsp; &nbsp; काठ </label>
            </v-col>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_matitel" />
              <label> &nbsp; &nbsp; पेट्रोल, मट्टितेल, एल.पि.जि ग्यास </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_gobar_gas" />
              <label> &nbsp;&nbsp; गोवर ग्यास </label>
            </v-col>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_madira" />
              <label> &nbsp;&nbsp; मदिरा </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_heater" />
              <label> &nbsp;&nbsp; हिटर </label>
            </v-col>
            <v-col cols="12" md="4">
              <input type="checkbox" v-model="newgeneralInfo.fuel_other" />
              <label> &nbsp;&nbsp; अन्य </label>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col cols="12" md="12">
              <v-toolbar-title class="black--text">
                <h3 class="text-center">GPS Location</h3>
              </v-toolbar-title>
            </v-col>
            <!-- <v-col cols="12" md="4" v-if="newgeneralInfo.longitude && newgeneralInfo.latitude">
              {{ newgeneralInfo.latitude }}, {{ newgeneralInfo.longitude }}
            </v-col> -->
            <!-- <v-col cols="12" md="4" v-else>
              {{ mapCordinate.latitude }}, {{ mapCordinate.longitude }}
            </v-col> -->
            <v-col cols="12" md="4">
              {{ newgeneralInfo.latitude }} , {{ newgeneralInfo.longitude }}
            </v-col>
          </v-row>
          <!-- <inventory-map
            class="order-2"
            cordinateType="singleCordinate"
            v-model="mapCordinate"
           v-bind:latitude-value="newgeneralInfo.latitude" 
           v-bind:longitude-value="newgeneralInfo.longitude"
          /> -->
          <!-- <inventory-map class="order-2" cordinateType="singleCordinate" v-model="mapCordinate" /> -->
          <Map
            @marker="addCoordinates($event)"
            :inputLatitude="newgeneralInfo.latitude"
            :inputLongitude="newgeneralInfo.longitude"
          ></Map>

          <!-- <v-row class="my-3">
            <v-col cols="12" sm="6">
              <label> Longitude <span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.longitude"
                label="Longitude"
                outlined
                shaped
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6">
              <label> Latitude <span> *</span></label>
              <v-text-field
                v-model="newgeneralInfo.latitude"
                label="Latitude"
                filled
                shaped
              ></v-text-field>
            </v-col>
          </v-row> -->

          <v-row>
            <v-col>
              <v-btn primary v-on:click="save" class="primary my-4" :disabled="!valid"
                >Save & Next
              </v-btn>
              <v-btn class="ma-1" color="primary" v-if="showSkipButton" v-on:click="skipPage">
                Skip
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";
import Map from "./../../map/map.vue";

export default {
  name: "newgeneralInfo",
  props: {
    nextStep: {
      type: Function,
    },
    showSkipButton: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    Map,
  },

  data() {
    return {
      testlat: "",
      testlon: "",
      isFormLoading: false,
      isPostAuthorized: true,
      images: [],
      records: [],
      files: [],
      formData: new FormData(),
      apiLink: "",
      mapCordinate: {},
      id: "",
      valid: false,

      maxLength(propertyType, maxLength) {
        return (v) =>
          (v && String(v).length <= maxLength) ||
          `${propertyType} must be less than ${maxLength} digits`;
      },
      required(propertyType) {
        return (v) => (v && String(v).length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return (v) => (v && Number(v) > 0) || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return (v) => (v && v > 0) || `Only number is allowed`;
      },

      user_role_id: localStorage.getItem("auth_user_role_id"),
      userRoleId: localStorage.getItem("auth_user_role_id"),
      ward_no: localStorage.getItem("ward_no"),
      is_municipal_staff: localStorage.getItem("is_municipal_staff"),
      color: { color: "#3F3C3C" },
      mode: "",
      genderType: [],
      wardName: [],
      generalInfo: [],
      newgeneralInfo: {
        id: 0,
        surveyor_name: "",
        houseowner_name: "",
        salutation_type_id: "",
        phone_no: "",
        citizenship_no: "",
        ward_no: "",
        address: "",
        street_no: "",
        house_no: "",
        is_map_verified: "",
        map_verified_date: "",
        map_verified_date_np: "",
        map_no: "",
        construction_completion_date: "",
        // construction_completion_date_np: "",
        construction_cost: "",
        latitude: "",
        longitude: "",
      },
      apiUrl: `api/registration/1/charkilla`,
      newgeneralInforole: [],
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
    };
  },

  computed: {
    isLoading() {
      if (this.genderType.length === 0 || this.wardName.length === 0) {
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    },
  },
  created() {
    this.buildingInfoDetail();
    this.selectBoxApi();
  },
  getLatitude: function () {
    return this.newgeneralInfo.latitude;
  },
  getLongitude: function () {
    return this.newgeneralInfo.longitude;
  },

  mounted() {
    const obj = this;
    const dateField = obj.$refs.dateField;
    if (dateField) {
      $(dateField.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      $(dateField.$el).on("dateSelect", (event) => {
        console.log(`Date: ${event.datePickerData.formattedDate}`);
        obj.newgeneralInfo.map_verified_date_np = event.datePickerData.formattedDate;
      });
    }
    const dateFieldCompletionDate = obj.$refs.dateFieldCompletionDate;
    if (dateFieldCompletionDate) {
      $(dateFieldCompletionDate.$el).nepaliDatePicker({
        dateFormat: "%y-%m-%d",
        closeOnDateSelect: true,
      });
      // $(dateFieldCompletionDate.$el).on("dateSelect", (event) => {
      //   console.log(`Date: ${event.datePickerData.formattedDate}`);
      //   obj.newgeneralInfo.construction_completion_date_np = event.datePickerData.formattedDate;
      // });
    }
  },

  methods: {
    skipPage() {
      this.$router.push(`/inventoryTabbedelements/2/${this.$route.params.regId}/2`);
    },
    buildingInfoDetail() {
      const obj = this;
      if (this.$route.params.regId !== undefined) {
        const url = `/api/bids/infrastructure/building/${this.$route.params.regId}`;
        VueAxios.get(url)
          .then((response) => {
            obj.newgeneralInfo = response.data.data;
            obj.mapCordinate = {
              longitude: response.data.data.longitude,
              latitude: response.data.data.latitude,
            };
            // obj.newgeneralInfo.construction_completion_date_np = parseInt(
            //   obj.newgeneralInfo.construction_completion_date_np
            // );
            obj.newgeneralInfo.map_verified_date_np = parseInt(
              obj.newgeneralInfo.map_verified_date_np
            );
          })
          .catch((error) => {
            console.log(`api error:${error}`);
          });
      }
    },
    getYear(start, stop) {
      return new Array(stop - start).fill(start).map((n, i) => n + i);
    },
    selectBoxApi() {
      const obj = this;
      const genderUrl = "api/bids/select-box/gender";
      VueAxios.get(genderUrl)
        .then((response) => {
          obj.genderType = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then((response) => {
          const wardList = response.data;
          obj.wardName = wardList;
          if (obj.is_municipal_staff == "0") {
            obj.wardName = wardList.filter((w) => w.id == obj.ward_no);

            this.selectWard(wardList, obj.ward_no, obj.newgeneralInfo.ward_no);
          }
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    selectWard(wardList, selectedValue, element) {
      element = wardList.filter((w) => w.id == selectedValue)[0];
    },
    save() {
      this.isFormLoading = true;
      if (
        this.newgeneralInfo.id === "null" ||
        this.newgeneralInfo.id === 0 ||
        this.newgeneralInfo.id === undefined
      ) {
        const saveUrl = `/api/bids/infrastructure/building`;
        VueAxios.post(saveUrl, this.newgeneralInfo).then((response) => {
          this.isFormLoading = false;
          if (response.error != null) {
            this.message = response.error;
          } else {
            console.log(localStorage.getItem("token"));
            let id = response.data.data.id;
            this.$router.push(`/inventoryTabbedelements/2/${id}/2`);
          }
        });
      } else {
        this.isFormLoading = true;
        const eurl = `/api/bids/infrastructure/building/${this.$route.params.regId}`;
        this.link = eurl;
        console.log(this.link);
        this.newgeneralInfo.latitude = this.mapCordinate.latitude;
        this.newgeneralInfo.longitude = this.mapCordinate.longitude;
        VueAxios.put(eurl, this.newgeneralInfo)
          .then((response) => {
            this.isFormLoading = false;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }

            console.log(response);
          })
          .catch((error) => {
            this.snackbar.show = true;
            this.successfulMessage = "Save failed.";
            this.$refs.form.reset();
            console.log(error);
          });
      }
    },

    edit(id) {
      const url = `api/bids/infrastructure/building/${id}`;

      VueAxios.get(url).then((response) => {
        this.newgeneralInfo = response.data.data;
        console.log(response.data.data);
      });
    },
    addCoordinates(coordinates) {
      this.newgeneralInfo.latitude = coordinates[0];
      this.newgeneralInfo.longitude = coordinates[1];
    },
  },
};
</script>
<style scoped>
label span {
  color: red;
}
.card {
  padding: 15px;
}
.tab {
  tab-size: 2;
}
</style>
