<template>
  <v-card height="automatic">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title> Map </v-toolbar-title>
    </v-toolbar>
    <div class=" map">
      <l-map :zoom="zoom" :center="center" @click="add" v-resize="onResize" :value="value">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
        <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
      </l-map>
    </div>
  </v-card>
</template>
<script>
// import { EventBus } from "../../main.js";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson, LPolyline } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson
  },
  props: {
    value: {
      type: [Object, Array]
    },
    roadValue: { type: Array },

    cordinateType: {
      // type: [Array],
      type: [String, Number]
    }
  },
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
    });
    return {
      cordinateValue: {},
      cordinateList: [],
      latitude: 0,
      longitude: 0,
      dataType: "",
      zoom: 11,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(27.72243591897343, 85.3143310546875),
      lat: "27.72243591897343",
      long: "85.3143310546875",
      geojson: { features: [] },
      options: { color: "#555555" },
      features: [
        {
          type: "Feature",
          properties: { party: "Road" },
          geometry: {
            type: "LineString",
            coordinates: []
          }
        }
      ]
    };
  },
  created() {},
  mounted() {
    setTimeout(function() {
      //debugger;
      window.dispatchEvent(new Event("resize"));
      // this.marker = L.latLng(26.4525, 87.2718);
    }, 250);
  },
  watch: {
    value: {
      handler: function() {
        // alert(this.value.latitude);

        if (this.cordinateType != "multipleCordinate") {
          this.marker = L.latLng(this.value.latitude, this.value.longitude);
        } else if (this.cordinateType == "multipleCordinate") {
          //  debugger;

          this.features[0].geometry.coordinates = this.value;
          this.geojson.features = this.features;
        }
      }
      // deep: true
    }
  },
  methods: {
    add(e) {
      // debugger;
      this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
      if (this.cordinateType == "multipleCordinate") {
        this.latitude = e.latlng.lat;
        this.longitude = e.latlng.lng;
        this.cordinateList.push([this.longitude, this.latitude]);
        this.cordinateValue = this.cordinateList;
        //  debugger;
        this.features[0].geometry.coordinates = this.cordinateValue;
        // debugger;

        this.$emit("input", this.cordinateValue);
      } else {
        this.latitude = e.latlng.lat;
        this.longitude = e.latlng.lng;
        this.cordinateValue = [e.latlng.lat, e.latlng.lng];
        this.features[0].geometry.coordinates = null;
        this.$emit("input", {
          latitude: this.latitude,
          longitude: this.longitude
        });
      }
      // this.value = this.cordinateValue;
      // this.$emit("coordinateChange", this.cordinateValue);
      // this.$emit("input", this.value);

      this.geojson.features = this.features;
      // console.log(this.geojson.features);debugger;
    }
  }
};
</script>
<style scoped>
.map {
  width: 100%;
  height: 350px;
}
</style>
