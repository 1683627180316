<template>
  <v-card class="registrationAction elevation-12 my-2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        File Action
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <template v-slot:default>
        <thead>
          <tr>
            <td></td>
          </tr>
        </thead>
        <tbody>
          <tr class="registrationtable">
            <td class="registrationtable">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-text-field
                  v-model="forward_message.message"
                  :rules="nameRules"
                  placeholder="Message"
                  single-line
                  solo
                ></v-text-field>
                <div>
                  <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-width="200"
                    offset-y
                    transition="slide-y-transition"
                  >
                    <template v-slot:activator="{ on }">
                      <v-avatar class="mx-4" size="36" dark v-on="on">
                        <v-icon @click="onClick()" large>mdi-email</v-icon>
                      </v-avatar>
                    </template>

                    <v-card>
                      <v-list>
                        <v-list-item>
                          <v-list-item-avatar size="36" dark v-on="on">
                            <v-icon large>mdi-email</v-icon>
                          </v-list-item-avatar>

                          <v-list-item-content>
                            <v-list-item-title
                              >Message from
                              {{ notificationMessage.created_by.username }}
                            </v-list-item-title>
                            <!-- <v-list-item-subtitle>{{user.username}}</v-list-item-subtitle> -->
                            <v-list-item-subtitle
                              ><strong>
                                {{ notificationMessage.message }}
                              </strong></v-list-item-subtitle
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-menu>
                  <center>
                    <!-- <span v-if="isActionAuthorized"> -->
                    <span v-if="user_role_id == regDeskId">
                      <v-btn
                        class="forward_button primary mb-1"
                        @click="forward"
                        v-show="forwardTo"
                        >{{ forwardTo }}</v-btn
                      >
                      <br />
                      <v-btn
                        class="forward_button primary mb-1"
                        @click="revert"
                        v-show="revertTo"
                        >{{ revertTo }}</v-btn
                      >
                    </span>
                  </center>
                  <!-- <v-snackbar
                    v-model="snackbar.show"
                    :bottom="y === 'bottom'"
                    :color="snackbar.color"
                    :left="x === 'left'"
                    :multi-line="mode === 'multi-line'"
                    :right="x === 'right'"
                    :timeout="3000"
                    :top="y === 'top'"
                    :vertical="mode === 'vertical'"
                  >
                    {{ message }}
                  </v-snackbar> -->
                  <span v-if="message">
                    <v-alert dense outlined type="success">
                      {{ message }}
                    </v-alert>
                  </span>
                </div>
              </v-form>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "RegistrationAction",
  props: {
    regId: {
      type: Number
    },
    regPhaseId: {
      type: Number
    },
    regDeskId: {
      type: Number
    }
  },
  computed: {
    isActionAuthorized() {
      // Only authorized if the file is in the user's desk
      if (this.regDeskId === this.user_role_id) {
        return true;
      }
      return false;
    },
    forwardTo() {
      const currentRole = this.roles[this.user_role_id];
      return currentRole.next !== 0 ? `Forward to ${this.roles[currentRole.next].name}` : "";
    },
    revertTo() {
      const currentRole = this.roles[this.user_role_id];
      return currentRole.previous ? `Revert to ${this.roles[currentRole.previous].name}` : false;
    }
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      dialog: false,
      notificationMessage: {
        message: "",
        created_by: ""
      },
      show: true,
      message: "",
      nameRules: [v => !!v || "Message is required"],
      forward_message: {
        message: ""
      },
      roles: {
        2: { name: "Registration", next: 4, previous: 0 },
        4: { name: "Technical", next: 6, previous: 2 },
        6: { name: "Executive", next: 2, previous: 4 }
      }
    };
  },
  mounted() {
    const messageUrl = `api/registration/${this.$route.params.id}/message`;
    VueAxios.get(messageUrl)
      .then(response => {
        // debugger;
        this.notificationMessage = response.data.data;
        console.log(response.data.data);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    forward() {
      const url = `/api/registration/${this.$route.params.id}/forward`;
      VueAxios.post(url, this.forward_message)
        .then(response => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.message = "Forwarded successfully.";
          }
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    revert() {
      const url = `/api/registration/${this.$route.params.id}/revert`;
      VueAxios.post(url, this.forward_message)
        .then(response => {
          if (response.status === 200) {
            //    this.successmessage = 'Forwarded successfully.';
            this.message = "Reverted successfully.";
          }
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    getNextDesk(currentdeskid) {
      if (currentdeskid === 2) {
        return "Technical Desk";
      }
      if (currentdeskid === 4) {
        return "Executive Desk";
      }

      return "";
    },
    getPreviousDesk(currentdeskid) {
      if (currentdeskid === 6) {
        return "Technical Desk";
      }
      if (currentdeskid === 4) {
        return "Registration Desk";
      }

      return "";
    }
  }
};
</script>

<style scoped></style>
