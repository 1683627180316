<template>
  <v-card height="automatic">
    <div class="custom_map">
      <l-map
        :zoom="zoom"
        :center="center"
        @click="add"
        v-resize="onResize"
        :value="value"
        ref="mymap"
      >
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
        <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
      </l-map>
    </div>
  </v-card>
</template>
<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
  },
  props: {
    value: {
      type: [Object, Array],
    },
    roadValue: { type: Array },

    cordinateType: {
      // type: [Array],
      type: [String, Number],
    },
    inputLatitude: String,
    inputLongitude: String,
  },
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      // iconUrl: "/images/gov_logo.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow
      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
    });
    return {
      coordinates: [],
      cordinateValue: {},
      cordinateList: [],
      dataType: "",
      zoom: 11,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(27.72243591897343, 85.3143310546875),
      lat: "27.72243591897343",
      long: "85.3143310546875",
      geojson: { features: [] },
      features: [
        {
          type: "Feature",
          properties: { party: "Road" },
          geometry: {
            type: "LineString",
            coordinates: [],
          },
        },
      ],
      fillColor: "#4eaae6",
    };
  },
  created() {
    // this.getMapJson();
  },
  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          color: "#1c7dfc",
          fillOpacity: 0.5,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        if (feature.properties.houseowner) {
          layer.bindTooltip(
            "<div>Name:" +
              feature.properties.houseowner +
              "</div>",
            {
              permanent: false,
              sticky: true,
            }
          );
        }
      };
    },
    getMarker: function () {
      return [this.inputLatitude, this.inputLongitude]; // returns the marker value
    },
  },
  mounted() {
    this.getMapJson();
  },
  watch: {
    getMarker() {
      return (this.marker = L.latLng(this.inputLatitude, this.inputLongitude));
    },
  },
  methods: {
    add(e) {
      this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
      this.coordinates = [e.latlng.lat, e.latlng.lng];
      this.$emit("marker", this.coordinates);
    },
    getMapJson() {
      const mapUrl = "api/map";
      this.$axios
        .get(mapUrl)
        .then((response) => {
          this.geojson = response.data;
          this.center = L.latLng(
            this.geojson.features[0].geometry.coordinates[0][0][1],
            this.geojson.features[0].geometry.coordinates[0][0][0]
          );
          this.marker = L.latLng(this.inputLatitude, this.inputLongitude);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    onResize() {
      setTimeout(() => {
        this.$refs.mymap.mapObject.invalidateSize();
      }, 100);
    },
  },
};
</script>
<style scoped>
.custom_map {
  width: 100%;
  height: 350px;
}
</style>
