<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
div.options > label > input {
  visibility: hidden;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" color="grey lighten-1" height="auto">
      <v-app id="inspire">
        <v-container>
          <div>
            <router-link :to="{ name: 'application_tab', params: { regId: regId } }"
              >Back
            </router-link>
          </div>
          <img :src="require('@/assets/images/gov_logo.jpg')" style="height:80px;width:90px;" />
          <img
            :src="require('@/assets/images/nset.png')"
            style="height:70px;width:200px;margin-left:850px;"
          />
          <h3>
            <center>
              Building Code Implementation Program in Nepal<br />Assessment of<br />Building Permit
              Drawing
            </center>
          </h3>

          <p>
            This checklist is developed to evaluate building design and drawings submitted in the
            Municipality according to the codal provisions. Additionally, it helps to prepare an
            inventory of building practices and analysis for Building Code Compliance. This
            assessment is being carried out by the NAtional Society for Earthquake Technology-Nepal
            (NSET) together with Municiplaity.
          </p>

          <h3>A. GENERAL Compliance</h3>
          <v-row>
            <v-col cols="2">File. No:</v-col>
            <v-col cols="4"> {{ registration.file_code }}0 </v-col>
            <v-col cols="2">Submitted Date</v-col>
            <v-col cols="4"> {{ registration.registration_date_np }}0 </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Municipality</v-col>
            <v-col cols="4">8 </v-col>
            <v-col md="2">Ward No</v-col>
            <v-col md="4">
              {{ registration.ward_no }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Tole</v-col>
            <v-col cols="4">{{ houseownerDetail.tole }}0 </v-col>
            <v-col md="2">Land Plot No</v-col>
            <v-col md="4">{{ registration.land_plot_no }}0 </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">House Owner Name</v-col>
            <v-col cols="4">{{ houseownerDetail.name_np }} </v-col>
            <v-col md="2">Owner Contact No</v-col>
            <v-col md="4">
              {{ houseownerDetail.phone_no }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Land Area</v-col>
            <v-col cols="4">
              {{ landinfoarea.area_sqm }}
            </v-col>
            <v-col cols="2">Building Typology</v-col>
            <v-col cols="4">
              <span v-if="application.construction_type_id == 1">
                नयाँ भवन निर्माण
              </span>
              <span v-else-if="application.construction_type_id == 2">
                पुरानो भत्काइ नयाँ निर्माण
              </span>
              <span v-else-if="application.construction_type_id == 3">
                पुरानो भवनमा थप क्षेक्रफल सहित निर्माण
              </span>
              <span v-else-if="application.construction_type_id == 4">
                पर्खाल निर्माण
              </span>
              <span v-else-if="application.construction_type_id == 5">
                तला थप
              </span>
              <span v-else-if="application.construction_type_id == 6">
                मोहडा परिवर्तन
              </span>
              <span v-else-if="application.construction_type_id == 7">
                छाना परिवर्तन
              </span>
            </v-col>
            <!-- { id: 'new_building_construction', value: 'नयाँ भवन निर्माण' },
  { id: 'demolish_old_construct_new', value: 'पुरानो भत्काइ नयाँ निर्माण' },
      { id: 'add_area_to_existing', value: 'पुरानो भवनमा थप क्षेक्रफल सहित निर्माण' },
  { id: 'wall_construction', value: 'पर्खाल निर्माण' },
      { id: 'floor_addition', value: 'तला थप' },
  { id: 'changing_face', value: 'मोहडा परिवर्तन' },
      { id: 'change_roof', value: 'छाना परिवर्तन' }, -->
          </v-row>
          <v-row>
            <v-col cols="2">No. of Storey</v-col>
            <v-col cols="4">
              {{ application.no_of_storey }}
            </v-col>
            <v-col md="2">Ground Floor Area</v-col>
            <v-col md="4"> </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Type of Construction</v-col>
            <v-col cols="4"> </v-col>
            <v-col md="2">Building Category</v-col>
            <v-col md="4">
              {{ application.building_category }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Drawn By</v-col>
            <v-col cols="4"> </v-col>
            <v-col md="2">Designed By</v-col>
            <v-col md="4"> </v-col>
          </v-row>
          <h3 style="margin-left:150px;">B. BUILDING CODE ATTRIBUES</h3>
          <v-form>
            <table class="registrationtable buildingcode sober" style="padding-bottom:10px">
              <thead>
                <tr class="registrationtable">
                  <th class="text-left registrationtable">S.N</th>
                  <th class="text-left registrationtable">Attributes</th>
                  <th class="text-left registrationtable">Specific Conditions</th>
                  <th class="text-left registrationtable">Check</th>
                  <th class="text-left registrationtable">Recommendation</th>
                </tr>
              </thead>
              <tbody>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>1. CONFIGURATION RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">1</td>
                  <td class="registrationtable" rowspan="2">Wall thickness</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.wall_thickness_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.wall_thickness_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.wall_thickness_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">2</td>
                  <td class="registrationtable" rowspan="2">
                    Bands (Foundation, plinth, still, lintel, roof)s
                  </td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.bands_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.bands_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.bands_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">3</td>
                  <td class="registrationtable" rowspan="2">Soft Storey</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.soft_storey_compliance" />
                        <img />
                      </label>
                    </div>
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.soft_storey_compliance" class="ma-0 pa-0"></v-checkbox> -->
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.soft_storey_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox>
                    -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.soft_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">4</td>
                  <td class="registrationtable" rowspan="2">Overall Dimension Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.soft_storey_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.overall_dim_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.overall_dim_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.overall_dim_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">5</td>
                  <td class="registrationtable" rowspan="2">No. of storey</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.no_of_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.no_of_storey_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.no_of_storey_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">6</td>
                  <td class="registrationtable" rowspan="2">Length of Wings</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.wings_length_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.wings_length_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.wings_length_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">7</td>
                  <td class="registrationtable" rowspan="2">Thickness to height ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.thickness_ht_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.thickness_ht_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.beam_column_joint_compliance" class="ma-0 pa-0"></v-checkbox>
                    -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.thickness_ht_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">8</td>
                  <td class="registrationtable" rowspan="2">Setbacks</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.setbacks_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.setbacks_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.setbacks_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">9</td>
                  <td class="registrationtable" rowspan="2">Redundancy</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.redundancy_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.redundancy_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.redundancy_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">10</td>
                  <td class="registrationtable" rowspan="2">
                    Vertical Discontinuity of Major wall
                  </td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.vertical_discontinuity_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.vertical_discontinuity_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.vertical_discontinuity_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">11</td>
                  <td class="registrationtable" rowspan="2">Cantilever Projection</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <!-- <v-checkbox  v-bind:value="true" v-model="masonry.overall_dim_ratio_compliance" class="ma-0 pa-0"></v-checkbox> -->
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.cantilever_projection_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.cantilever_projection_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.cantilever_projection_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">12</td>
                  <td class="registrationtable" rowspan="2">Torsion</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.torsion_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.torsion_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.torsion_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">13</td>
                  <td class="registrationtable" rowspan="2">Adjacent Buildings</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.adjacent_building_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.adjacent_building_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.adjacent_building_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">14</td>
                  <td class="registrationtable" rowspan="2">Openings Percentage</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.opening_percentage_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.opening_percentage_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.opening_percentage_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">15</td>
                  <td class="registrationtable" rowspan="2">Position of Opening</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.opening_position_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.opening_position_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.opening_position_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">16</td>
                  <td class="registrationtable" rowspan="2">Unsupported Wall</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.unsupported_wall_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.unsupported_wall_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.unsupported_wall_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>C.STRENGTH RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Foundation</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.foundation_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.foundation_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.foundation_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Joint Mortar Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.joint_mortar_ratio_strength_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.joint_mortar_ratio_strength_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.joint_mortar_ratio_strength_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>D.DUCTILITY RELATED CHECKLIST</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Vertical Bar</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.vertical_bar_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.vertical_bar_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.vertical_bar_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>E.CONNECTION RELATED ATTRIBUTES</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Corner stitch</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.corner_stitch_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.corner_stitch_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.corner_stitch_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Joint Mortar Ratio</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.joint_mortar_ratio_conn_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.joint_mortar_ratio_conn_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.joint_mortar_ratio_conn_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Roof wall connection</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.roof_wall_conn_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.roof_wall_conn_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.roof_wall_conn_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Through Stone</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.through_stone_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.through_stone_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.through_stone_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>F.NON STRUCTURAL ATTRIBUTES</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">17</td>
                  <td class="registrationtable" rowspan="2">Gable Wall</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.gable_wall_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.gable_wall_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.gable_wall_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td colspan="5" class="registrationtable">
                    <strong>E. BUILDING BYELAWS</strong>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">32</td>
                  <td class="registrationtable" rowspan="2">Front width</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.front_width_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.front_width_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.front_width_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">33</td>
                  <td class="registrationtable" rowspan="2">Floor Area Ratio(FAR)</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.floor_area_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.floor_area_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="0"
                          v-model="masonry.floor_area_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">34</td>
                  <td class="registrationtable" rowspan="2">Front Setback</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="1" v-model="masonry.front_setback_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.front_setback_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="masonry.front_setback_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" rowspan="2">35</td>
                  <td class="registrationtable" rowspan="2">Ground Coverage Ratio(GCR)</td>
                  <td>Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input
                          type="radio"
                          value="1"
                          v-model="masonry.ground_coverage_ratio_compliance"
                        />
                        <img />
                      </label>
                    </div>
                  </td>
                  <td class="registrationtable" rowspan="2">
                    <v-text-field
                      v-model="masonry.ground_coverage_ratio_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td>Non-Compliance</td>
                  <td class="registrationtable">
                    <div class="options">
                      <label title="item1">
                        <input type="radio" value="0" v-model="ground_coverage_ratio_compliance" />
                        <img />
                      </label>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="sober">
              <thead>
                <tr class="registrationtable">
                  <th rowspan="2" class="registrationtable">
                    Floor
                  </th>
                  <th rowspan="2" class="registrationtable">
                    Plinth area
                  </th>
                  <th colspan="2" class="registrationtable">
                    Municipality Fees
                  </th>
                  <th rowspan="2" class="registrationtable">
                    Remarks
                  </th>
                </tr>
                <tr class="registrationtable">
                  <th class="registrationtable">Rate</th>
                  <th class="registrationtable">Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr class="registrationtable">
                  <td class="registrationtable">Basement 1</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement1_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement1_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement1_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement1_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Basement 2</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement2_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement2_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement2_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement2_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Basement 3</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement3_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement3_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement3_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.basement3_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">First</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.first_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.first_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.first_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.first_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Second</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.second_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.second_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.second_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.second_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Third</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.third_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.third_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.third_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.third_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Fourth</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fourth_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fourth_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fourth_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fourth_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Fifth</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fifth_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fifth_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fifth_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.fifth_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Sixth</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.sixth_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.sixth_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.sixth_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.sixth_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable">Seventh</td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.seventh_plinth_area"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.seventh_rate"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.seventh_amount"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.seventh_remarks"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <b>Total</b>
                  </td>
                  <td class="registrationtable">
                    <v-text-field solo class="ma-0 pa-0"></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field solo class="ma-0 pa-0"></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field
                      label="Layout Charge"
                      disabled
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.layout_charge"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable" rowspan="2"></td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field
                      label="Design Charge"
                      disabled
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.design_charge"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field
                      label="Form Charge"
                      disabled
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.form_charge"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field
                      label="Application registration"
                      disabled
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field
                      v-model="masonry.application_registration"
                      solo
                      class="ma-0 pa-0"
                    ></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field label="Others" disabled solo class="ma-0 pa-0"></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field v-model="masonry.others" solo class="ma-0 pa-0"></v-text-field>
                  </td>
                </tr>
                <tr class="registrationtable">
                  <td class="registrationtable" colspan="3">
                    <v-text-field label="Total" disabled solo class="ma-0 pa-0"></v-text-field>
                  </td>
                  <td class="registrationtable">
                    <v-text-field v-model="masonry.total" solo class="ma-0 pa-0"></v-text-field>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="sober">
              <thead>
                <tr>
                  <th class="text-left ma-1"><strong>Observations</strong></th>
                  <th class="ma-1"><strong>Recommendation</strong></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong
                      ><v-text-field
                        v-model="masonry.observation"
                        solo
                        class="ma-1 pa-0"
                      ></v-text-field
                    ></strong>
                  </td>
                  <td>
                    <strong
                      ><v-text-field
                        v-model="masonry.recommendation"
                        solo
                        class="ma-1 pa-0"
                      ></v-text-field
                    ></strong>
                  </td>
                </tr>
              </tbody>
            </table>
            <span v-if="successfulMessage">
              <v-alert dense outlined type="alert">
                {{ successfulMessage }}
              </v-alert>
            </span>
            <v-btn v-if="userRoleId == 4" primary v-on:click="save" class="primary">Submit</v-btn>
          </v-form>
        </v-container>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "masonry",
  props: {
    nextStep: {
      type: Function
    }
  },
  data() {
    return {
      userRoleId: localStorage.getItem("auth_user_role_id"),
      designer: "",
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      application: "",
      registration: "",
      houseownerDetail: "",
      landinfoarea: "",
      masonry: {
        id: 0,
        regId: this.$route.params.regId,
        wall_thickness_compliance: "",
        wall_thickness_remarks: "",
        bands_compliance: "",
        bands_remarks: "",
        soft_storey_compliance: "",
        soft_storey_remarks: "",
        overall_dim_ratio_compliance: "",
        overall_dim_ratio_remarks: "",
        no_of_storey_compliance: "",
        no_of_storey_remarks: "",
        wings_length_compliance: "",
        wings_length_remarks: "",
        thickness_ht_ratio_compliance: "",
        thickness_ht_ratio_remarks: "",
        setbacks_compliance: "",
        setbacks_remarks: "",
        redundancy_compliance: "",
        redundancy_remarks: "",
        vertical_discontinuity_compliance: "",
        vertical_discontinuity_remarks: "",
        cantilever_projection_compliance: "",
        cantilever_projection_remarks: "",
        opening_percentage_compliance: "",
        opening_percentage_remarks: "",
        opening_position_compliance: "",
        opening_position_remarks: "",
        unsupported_wall_compliance: "",
        unsupported_wall_remarks: "",
        foundation_compliance: "",
        foundation_remarks: "",
        joint_mortar_ratio_strength_compliance: "",
        joint_mortar_ratio_strength_remarks: "",
        vertical_bar_compliance: "",
        vertical_bar_remarks: "",
        corner_stitch_compliance: "",
        corner_stitch_remarks: "",
        joint_mortar_ratio_conn_compliance: "",
        joint_mortar_ratio_conn_remarks: "",
        roof_wall_conn_compliance: "",
        roof_wall_conn_remarks: "",
        through_stone_compliance: "",
        through_stone_remarks: "",
        gable_wall_compliance: "",
        gable_wall_remarks: "",
        front_width_compliance: "",
        front_width_remarks: "",
        floor_area_ratio_compliance: "",
        floor_area_ratio_remarks: "",
        front_setback_compliance: "",
        front_setback_remarks: "",
        ground_coverage_ratio_compliance: "",
        ground_coverage_ratio_remarks: "",
        // Revenue Section
        basement1_plinth_area: null,
        basement1_rate: null,
        basement1_amount: null,
        basement1_remarks: null,
        basement2_plinth_area: null,
        basement2_rate: null,
        basement2_amount: null,
        basement2_remarks: null,
        basement3_plinth_area: null,
        basement3_rate: null,
        basement3_amount: null,
        basement3_remarks: null,
        first_plinth_area: null,
        first_rate: null,
        first_amount: null,
        first_remarks: null,
        second_plinth_area: null,
        second_rate: null,
        second_amount: null,
        second_remarks: null,
        hird_plinth_area: null,
        third_rate: null,
        third_amount: null,
        third_remarks: null,
        fourth_plinth_area: null,
        fourth_rate: null,
        fourth_amount: null,
        fourth_remarks: null,
        fifth_plinth_area: null,
        fifth_rate: null,
        fifth_amount: null,
        fifth_remarks: null,
        sixth_plinth_area: null,
        sixth_rate: null,
        sixth_amount: null,
        sixth_remarks: null,
        seventh_plinth_area: null,
        seventh_rate: null,
        seventh_amount: null,
        seventh_remarks: null,
        layout_charge: null,
        design_charge: null,
        form_charge: null,
        application_registration: null,
        others: null,
        total: null,
        observation: "",
        recommendation: ""
      }
    };
  },
  mounted() {
    const url = `api/registration/${this.registrationId}/designer`;
    VueAxios.get(url)
      .then(response => {
        // debugger;
        this.designer = response.data.data;
        // this.newDesigner.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const applicationUrl = `api/registration/${this.$route.params.regId}/application`;
    VueAxios.get(
      applicationUrl,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.application = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlMasonry = `api/registration/${this.$route.params.regId}/masonry`;
    VueAxios.get(
      urlMasonry,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.masonry = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlRegistration = "api/registration";
    VueAxios.get(
      urlRegistration,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.registration = response.data;
        console.log(this.registration);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const urlHouseowner = `api/registration/${this.$route.params.regId}/houseowner-detail`;
    VueAxios.get(
      urlHouseowner,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        // debugger;
        this.houseownerDetail = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
    const landinfoareaUrl = `api/registration/${this.registrationId}/land-info-area`;
    VueAxios.get(landinfoareaUrl)
      .then(response => {
        // debugger;
        this.landinfoarea = response.data.data;
        // this.newLandinfoarea.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    save() {
      // console.log(this.$route.params.regId);
      //   debugger;
      if (this.masonry.id === null || this.masonry.id === 0 || this.masonry.id === undefined) {
        // debugger;
        const url = `/api/registration/${this.$route.params.regId}/masonry`;
        VueAxios.post(
          url,
          this.masonry,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.masonry = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/masonry/${this.masonry.id}`;
        VueAxios.put(
          eurl,
          this.masonry,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    edit(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/masonry/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/masonry/${id}`;
      VueAxios.get(
        url,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      ).then(response => {
        // debugger;
        this.masonry = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/masonry/${id}`;
      VueAxios.delete(
        deleteurl,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      )
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
