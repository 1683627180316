<template>
  <div>
    <v-card class="sober">
      <apexchart type="bar" height="400" width="800" :options="chartOptions" :series="series"></apexchart>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
//  new Vue({
//         el: '#app',
//         components: {
//           apexchart: VueApexCharts,
//         },
export default {
  data() {
    return {
      series: [],
      categories: [],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          stacked: false
          //  stackType: '100%'
        },
        plotOptions: {
          bar: {
            vertical: true
          }
        },
        stroke: {
          width: 1,
          colors: ["#fff"]
        },
        title: {
          text: "Reports"
        },
        category: ["ward 1", "ward 2", "ward 3", "ward 4", "ward 5", "ward 6", "ward 7"],
        xaxis: { categories: [] },
        // xaxis: {
        //   // categories: ["ward 1", "ward 2", "ward 3", "ward 4", "ward 5", "ward 6", "ward 7"]
        //   categories: this.categories
        //   // labels: {
        //   //   formatter: function (val) {
        //   //     return val
        //   //   }/
        //   // }
        // },
        yaxis: {
          title: {
            text: undefined
          }
        },
        tooltip: {
          y: {
            formatter: function(val) {
              return val + "K";
            }
          }
        },
        fill: {
          opacity: 1
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40
        }
      }
    };
  },
  created() {
    this.loadData();
  },

  methods: {
    loadData() {
      console.log(this.category);

      // this.category.forEach((value, index)=> {
      //   this.xaxis.categories.push(value);
      //   debugger;
      // })
      this.chartOptions.category.forEach((value, index) => {
        // this.$set(this.chartOptions, "xaxis", {
        //   categories: this.chartOptions.xaxis.categories.concat(value),
        this.chartOptions.xaxis.categories = this.chartOptions.category;
        // });
      });
      this.series = [
        {
          name: "1 Storey",
          data: [44, 55, 41, 37, 22, 43, 21]
        },
        {
          name: "2 Storey",
          data: [53, 32, 33, 52, 13, 43, 32]
        },
        {
          name: "3 Storey",
          data: [12, 17, 11, 9, 15, 11, 20]
        },
        {
          name: "4 Storey",
          data: [9, 7, 5, 8, 6, 9, 4]
        },
        {
          name: "5 Storey and above",
          data: [25, 12, 19, 32, 25, 24, 10]
        }
      ];
    }
  }
};
// })
// export default {
//   data() {
//     return {
//       series: [{
//             name: 'Net Profit',
//             data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
//           }, {
//             name: 'Revenue',
//             data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
//           }, {
//             name: 'Free Cash Flow',
//             data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
//           }],
//           chartOptions: {
//             chart: {
//               type: 'bar',
//               height: 350
//             },
//             plotOptions: {
//               bar: {
//                 horizontal: false,
//                 columnWidth: '55%',
//                 endingShape: 'rounded'
//               },
//             },
//             dataLabels: {
//               enabled: false
//             },
//             stroke: {
//               show: true,
//               width: 2,
//               colors: ['transparent']
//             },
//             xaxis: {
//               categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
//             },
//             yaxis: {
//               title: {
//                 text: '$ (thousands)'
//               }
//             },
//             fill: {
//               opacity: 1
//             },
//             tooltip: {
//               y: {
//                 formatter: function (val) {
//                   return "$ " + val + " thousands"
//                 }
//               }
//             }
//     };

//   }
//   }

//   // methods: {
//   //   onChartReady(chart, google) {
//   //     VueAxios.get(`api/report/construction-type`).then(response => {
//   //       const options = {
//   //         title: response.data.Title,
//   //         is3D: true
//   //       };
//   //       // console.log(response.data.data);
//   //       chart.draw(google.visualization.arrayToDataTable(response.data.data), options);
//   //     });
//   //   }
//   // }
// };
</script>

<style scoped>
.piechart {
  width: 900px;
  height: 500px;
}
</style>
