<style>
.houseownerinfo {
  font-weight: normal;
  width: 250px;
}
</style>
<template>
  <v-layout class="mb-9">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>

    <v-flex>
      <v-card class="d-flex flex-column mb-2">
        <div>
          <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
        </div>
        <v-row>
          <v-col sm="8">
            <v-toolbar dense dark flat color="primary">
              <v-toolbar-title>{{ results.houseowner_name }}</v-toolbar-title>
            </v-toolbar>
            <v-simple-table class="sober elevation-2">
              <template v-slot:default>
                <!-- <thead>
                                                <tr class="registrationtables">
                                                    <th class="text-left registrationtables"></th>
                                                </tr>
                                            </thead> -->
                <tbody>
                  <tr class="">
                    <td class="registrationtables">
                      आर्थिक बर्ष: <b> {{ results.fiscal_year }} </b>
                    </td>
                    <td class="registrationtables">
                      सम्पर्क नं: <b>{{ results.contact_no }}</b>
                    </td>
                    <td class="registrationtables">
                      घरधनीको नाम : <b>{{ results.houseowner_name_np }}</b>
                    </td>
                    <td class="registrationtables">
                      सडक न : <b>{{ results.street_no }}</b>
                    </td>
                  </tr>
                  <tr class="registrationtables">
                    <td class="registrationtables">
                      करदाता संकेत न: <b>{{ results.houseowner_tax_no }}</b>
                    </td>
                    <td class="registrationtables">
                      जग्गा कित्ता न: <b>{{ results.land_plot_no }}</b>
                    </td>
                    <td class="registrationtables">
                      आन्तरिक संकेत न: <b>{{ results.internal_code }}</b>
                    </td>
                    <td class="registrationtables">
                      वडा नं: <b>{{ results.ward_no }}</b>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table class="registrationtables">
              <template v-slot:default> </template>
            </v-simple-table>
          </v-col>
          <v-col sm="4">
            <v-card class="sober elevation-2">
              <v-toolbar dense dark flat color="primary">
                <v-toolbar-title>Application Status</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <strong>{{ log }}</strong>
                <br />
                <strong> Phase: </strong> {{ results.phase }}
              </v-card-text>
              <!-- <h4>View Application status logs</h4> -->
            </v-card>
          </v-col>
        </v-row>
      </v-card>
      <v-card>
        <v-stepper v-model="el">
          <v-stepper-header class="py-0 my-0">
            <template v-for="n in steps">
              <v-stepper-step
                :key="n.sn"
                :step="n.sn"
                :complete="n.sn <= results.phase_id || n.sn == '6'"
                :editable="n.sn <= results.phase_id || n.sn == '6'"
              >
                {{ n.text }}
              </v-stepper-step>
            </template>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content
              v-for="n in steps"
              :key="`${n.sn}-content`"
              :step="n.sn"
              class="sober px-1 "
            >
              <component
                :is="getComponent(n.sn)"
                :id="results.id"
                v-if="n.sn === el"
                :next-step="nextStep"
                :current-application-phase="results.phase_id"
                :current-application-desk="results.desk_user_role_id"
              />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-flex>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import RegistrationLevel from "./components/RegistrationLevel.vue";
import FifteenDays from "./components/FifteenDays.vue";
import PlinthLevel from "./components/PlinthLevel.vue";
import SuperStructuralLevel from "./components/SuperStructuralLevel.vue";
import CompletionLevel from "./components/CompletionLevel.vue";
import ApplicationStatus from "./components/ApplicationStatus.vue";
// import Test from '../test.vue'

const COMPONENT_MAP = {
  1: "RegistrationLevel",
  2: "FifteenDays",
  3: "PlinthLevel",
  4: "SuperStructuralLevel",
  5: "CompletionLevel",
  6: "ApplicationStatus"
};
export default {
  name: "ApplicationTab",
  data() {
    return {
      el: 1,
      steps: [
        {
          text: "Registration Level",
          sn: 1
        },
        {
          text: "15 Days",
          sn: 2
        },
        {
          text: "Plinth Level",
          sn: 3
        },
        {
          text: "Superstructural Level",
          sn: 4
        },
        {
          text: "Completion Level",
          sn: 5
        },
        {
          text: "Application Status",
          sn: 6
        }
      ],
      results: [],
      log: ""
    };
  },
  components: {
    RegistrationLevel,
    FifteenDays,
    PlinthLevel,
    SuperStructuralLevel,
    CompletionLevel,
    ApplicationStatus
    // Test
  },
  computed: {
    isLoading() {
      if (this.results === undefined || this.results.length === 0) {
        // registration value not yet initiated from api. Loading spinner active
        return true;
      }
      return false;
    }
  },
  watch: {
    steps(val) {
      if (this.el > val) {
        this.el = val;
      }
    }
  },
  mounted() {
    // debugger;
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${window.localStorage.getItem("token")}`
        }
      },
      {
        timeout: 100000
      }
    ).then(response => {
      // debugger;
      this.results = response.data.data;
      // sconsole.log(response.data.data);

      // Select the tab that the registration phase is in
      this.el = this.results.phase_id;
    });

    const regLogUrl = `api/registration/${this.$route.params.id}/log`;
    VueAxios.get(regLogUrl).then(response => {
      this.log = response.data.data[0].remarks;
    });
  },
  methods: {
    getComponent(n) {
      return COMPONENT_MAP[n];
    },
    nextStep(n) {
      if (n === this.results.phase_id) {
        this.el = n;
      } else {
        this.el = this.results.phase_id;
      }
      // if (n === this.steps.sn) {
      //     this.el = 1;
      // } else {
      //     this.el = n + 1;
      // }
    }
  }
};
</script>
