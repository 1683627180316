<template>
  <div>
    <loading :active.sync="isDataLoading" :can-cancel="true" :is-full-page="false"></loading>
    <v-row class="mx-2">
      <v-spacer></v-spacer>
      <v-btn
        large
        depressed=""
        color="primary"
        class="m-2 subtitle-1"
        :to="{ name: 'buildinginformation' }"
      >
        <v-icon left>mdi-plus</v-icon>
        नयाँ दर्ता
      </v-btn>
      <v-btn
        large
        depressed=""
        color="primary"
        class="m-2 subtitle-1 font-weight-small"
        :to="{ name: 'dashboard' }"
      >
        <!-- <v-icon dark> mdi-arrow-collapse-left </v-icon> -->
        Back
      </v-btn>
    </v-row>

    <div class="mt-5">
      <!-- <loading :active.sync="isDataLoading" :can-cancel="true" :is-full-page="false"></loading> -->
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> Inventory List </v-toolbar-title>
      </v-toolbar>
      <!-- <v-data-table
          :headers="headers"
          :items="items"
          :search="search"
          :hide-default-header="false"
          :items-per-page="10"
          :loading="isLoading"
          hide-default-footer
          item-key="name"
          class="sober elevation-12"
        > -->
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="itemsPerPage"
        :hide-default-header="false"
        :hide-default-footer="false"
        :loading="isLoading"
        :server-items-length="total"
        :footer-props="{
          'items-per-page-options': [10, 15],
          'show-first-last': true,
        }"
        :page.sync="page"
        @update:page="getBuildingList"
        item-key="name"
        class="sober"
      >
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.houseowner_name"
                @keyup="searchFilters"
                class="search_filter"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.surveyor_name"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.address"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.ward_no"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.citizenship_no"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.street_no"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.house_no"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.is_map_verified"
                @keyup="searchFilters"
                class="search_filter"
                >></v-text-field
              >
            </td>
            <td colspan="2"></td>
          </tr>
        </template>

        <template v-slot:item.is_map_verified="{ item }">
          {{ verifyPermit(item.is_map_verified) }}
        </template>

        <template v-slot:item.status="{ item }">
          <v-btn color="primary" small :to="{ name: 'inventory_tab', params: { id: item.id } }"
            >Open
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  data() {
    return {
      // boolean to start/stop progress spinner
      isDataLoading: true,
      enabled: null,
      DESSERTS: [],
      items: [],
      newItems: [],
      search: null,
      slots: [
        "body",
        "body.append",
        "body.prepend",
        "footer",
        "header.data-table-select",
        "header",
        "progress",
        "item.data-table-select",
        "item.<name>",
        "no-data",
        "no-results",
        "top",
      ],
      headers: [
        {
          text: "घरधनीको नाम",
          align: "start",
          sortable: false,
          value: "houseowner_name",
        },
        { text: "सर्भेक्षकको नाम ", value: "surveyor_name" },
        { text: "ठेगाना (टोल)", value: "address" },
        { text: "वडा नं.", value: "ward_no" },
        { text: "नागरिकता नं", value: "citizenship_no" },
        { text: " सडकको नाम वा नं", value: "street_no" },
        { text: "घर नं ", value: "house_no" },
        { text: "पास गरेको ", value: "is_map_verified" },
        { text: "कार्य", value: "status" },
      ],
      searchKeys: {
        houseowner_name: "",
        surveyor_name: "",
        ward_no: "",
        citizenship_no: "",
        street_no: "",
        house_no: "",
        is_map_verified: "",
        is_old: 0,
      },
      pageInfo: [],
      itemsPerPage: 10,
      total: 0,
      page: 1,
      options: {},
    };
  },
  computed: {
    isLoading() {
      return this.isEnabled("progress");
    },
  },

  watch: {
    enabled(slot) {
      if (slot === "no-data") {
        this.items = [];
      } else if (slot === "no-results") {
        this.search = "...";
      } else {
        this.search = null;
      }
    },
    searchKeys: {
      handler() {
        console.log(`handler page: ${this.page}`);
        this.page = 1;
      },
      deep: true,
    },
    page() {
      this.isDataLoading = true;
    },
  },

  created() {
    this.getBuildingList();
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    searchFilters() {
      const searchUrl = "api/bids/building/search";
      this.searchkeyFormData = this.jsonToFormData(this.searchKeys);
      this.$axios.post(searchUrl, this.searchKeys, {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      }).then((response) => {
        this.items = response.data.data.data;
        this.pageInfo = response.data.data;
        this.itemsPerPage = response.data.data.per_page;
        this.total = response.data.data.total;
        this.isDataLoading = false;
      });
    },
    buildFormData(formData, data, parentKey) {
      if (data && typeof data === "object" && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? "" : data;

        formData.append(parentKey, value);
      }
    },
    jsonToFormData(data) {
      this.searchkeyFormData = new FormData();
      this.buildFormData(this.searchkeyFormData, data);

      return this.searchkeyFormData;
    },

    isEnabled(slot) {
      return this.enabled === slot;
    },
    getBuildingList() {
      const url = "api/bids/infrastructure/building";
      VueAxios.get(url, { params: { page: parseInt(this.page) } }).then((response) => {
        this.newItems = response.data.data.data;
        this.pageInfo = response.data.data;
        this.itemsPerPage = response.data.data.per_page;
        this.total = response.data.data.total;

        this.items = this.newItems;
        this.isDataLoading = false;
      });
    },

    verifyPermit(is_map_verified) {
      if (is_map_verified == 1) return "Permitted";
      else return "Not Permitted";
    },
    
  },
  mounted() {
    this.scrollToTop();
    console.log(localStorage.getItem("token"));
    this.$root.$on("home_search", () => {
      // this.isLoading;
      this.searchFilters();
      this.getSiteConfiguration();
    });

  },
};
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 1.2rem !important;
}
.v-data-table::v-deep td {
  font-size: 20px !important;
}
.search_filter {
  font-size: 1.2rem;
}
</style>
