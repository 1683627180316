<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
  width: 1000px;
}
.revenue td,
.revenue th {
  width: 500px;
}
.document td,
.document th {
  padding: 8px;
}
.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}

div.options > label {
  display: block;
  margin: 0 0 0 -10px;
  padding: 0 0 20px 0;
  height: 20px;
  width: 150px;
}

div.options > label > img {
  display: inline-block;
  padding: 0px;
  height: 30px;
  width: 30px;
  background: none;
}

div.options > label > input:checked + img {
  background: url(http://cdn1.iconfinder.com/data/icons/onebit/PNG/onebit_34.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30px 30px;
}
</style>
<template>
  <div>
    <v-card class="mb-12" height="auto">
      <v-app id="inspire">
        <v-form class="sober">
          <v-container height="auto">
            <v-row height="auto">
              <v-col md="12">
                <v-card>
                  <center>
                    <h4>Building Code Implementation in Municipalities in Nepal(BCIPN)</h4>
                    <h4>Compliance Checklist to Evaluate Building Design and Drawings</h4>
                  </center>
                  <br />
                  <v-simple-table class="registrationtable buildingcode sober">
                    <template v-slot:default>
                      <thead>
                        <tr class="registrationtable">
                          <th class="text-left registrationtable">S.N</th>
                          <th class="text-left registrationtable">Attributes</th>
                          <th class="text-left registrationtable">
                            Specific Conditions
                          </th>
                          <th class="text-left registrationtable">Check</th>
                          <th class="text-left registrationtable">Remarks</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>A. Configuration Related Attributes</strong>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">1</td>
                          <td class="registrationtable" rowspan="2">
                            Overall Dimension Ratio: The length of building shall not be greater
                            than three times the breadth of the buildings.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.overall_dim_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.overall_dim_ratio_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item21">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.overall_dim_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">2</td>
                          <td class="registrationtable" rowspan="2">
                            Length of Wings: The length of wings of building shall be less than 0.15
                            times the corresponding width of the building.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.wings_length_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.wings_length_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item21">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.wings_length_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">3</td>
                          <td class="registrationtable" rowspan="2">
                            Setbacks: a) Individual setbacks of each side of the building do not
                            exceed 10% of the parallel dimension of the storey below. b) if setbacks
                            are not symmetric at opposite sides of the building , the total setback
                            of the roof at each side with respect to the base does not exceed 30% of
                            the parallel dimension at the building base. c) It there is a single
                            setback within the lowest 15% of the total height of the building , it
                            dos not exceed 50% of the parallel dimension at the base of the
                            building.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.setbacks_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.setbacks_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.setbacks_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">4</td>
                          <td class="registrationtable" rowspan="2">
                            Redundancy: The number of bays of moment frames in each direction shall
                            be greater than or equal to 2
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.redundancy_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.redundancy_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.redundancy_compliance" />
                                <img />
                              </label>
                            </div>
                            <!-- <v-checkbox
                              v-bind:value="true"
                              v-model="rcc.redundancy_compliance"
                              class="ma-0 pa-0"
                            ></v-checkbox> -->
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">5</td>
                          <td class="registrationtable" rowspan="2">
                            Column Layout: All columns shall be in grid lines
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_layout_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_layout_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_layout_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">6</td>
                          <td class="registrationtable" rowspan="2">
                            Beam Discontinuity: Beam shall not be discontinuous in any frame system
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.beam_continuity_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.beam_continuity_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.beam_continuity_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">7</td>
                          <td class="registrationtable" rowspan="2">
                            Vertical Discontinuity: All vertical elements in the lateral force
                            resisting system shall be continuous to the foundation.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.vertical_discontinuity_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.vertical_discontinuity_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.vertical_discontinuity_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">8</td>
                          <td class="registrationtable" rowspan="2">
                            Cantilever Projection: There shall be no cantilever projection exceeding
                            1m. Full height partition walls shall not be built on a cantilever slab
                            except a parapet.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.cantilever_projection_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.cantilever_projection_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.cantilever_projection_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">9</td>
                          <td class="registrationtable" rowspan="2">
                            Short Column: The reduced height of a column due to surrounding parapet,
                            infill wall, landing beam etc. shall not be less than five times the
                            dimension of the column in the direction of parapet, infill wall,
                            landing beam etc. or 50% of the nominal height of the typical columns in
                            that storey.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.short_column_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.short_column_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.short_column_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">10</td>
                          <td class="registrationtable" rowspan="2">
                            Torsion: The estimated distance between the storey center of mass and
                            the storey centre of stiffness shall be less than 30% of the building
                            dimension at right angles to the direction of loading considered.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.torsion_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.torsion_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.torsion_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">11</td>
                          <td class="registrationtable" rowspan="2">
                            Adjacent Building: The clear horizontal distance between the building
                            under consideration and any adjacent building shall be greater than 4 %
                            of the height of the shorter building, expect for buildings that are of
                            the same height with floors located at the same levels.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.adjacent_building_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.adjacent_building_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.adjacent_building_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">12</td>
                          <td class="registrationtable" rowspan="2">
                            Soft Storey: The stiffness of vertical lateral lead resisting system in
                            any storey shall not be less than 60% of the stiffness in an adjacent
                            storey or less than 70% of the average stiffness of the three stories
                            aboves.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.soft_storey_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.soft_storey_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.soft_storey_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>B. Strength Related Attributes Related Attributes</strong>
                          </td>
                        </tr>

                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">13</td>
                          <td class="registrationtable" rowspan="2">
                            Size of Beam: The width of the member shall not be less than 200 mm. The
                            depth D of the member shall preferably be not more than 1/4 of the clear
                            span. Width of beam shall not be more than the width of the column.
                            Also, distances on each side of the column shall not exceeding half of
                            the depth of the beam.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.beam_size_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.beam_size_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.beam_size_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">14</td>
                          <td class="registrationtable" rowspan="2">
                            Strong column Weak Beam: The sum of the moments of resistance of the
                            columns shall be at least 1.1 times the sum of the moment of resistance
                            of the beams at each frame joint.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.strong_column_weak_beam_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.strong_column_weak_beam_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.strong_column_weak_beam_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">15</td>
                          <td class="registrationtable" rowspan="2">
                            Shear Stress In Column: The average shear stress in concrete columns
                            Tcol, computed in accordance with 6.5.1 of IITK-GSDMA guidelines for
                            seismic evaluation and strengthening of Buildings shall be lesser of 0.4
                            Mpa and 0.10 √fck
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_shear_stress_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_shear_stress_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_shear_stress_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">16</td>
                          <td class="registrationtable" rowspan="2">
                            Grade of Concrete: The minimum grade of concrete shall be M20 (20MPa).
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.concrete_grade_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.concrete_grade_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.concrete_grade_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">17</td>
                          <td class="registrationtable" rowspan="2">
                            Size of column : The minimum dimension of the member shall not be less
                            than (a). 15 times the largest beam bar diameter of the longitudional
                            reinforcement in the beam passing through or (b) anchoring into the
                            column joint or (c) 300m.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_size_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_size_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_size_compliance"
                                />
                                <img />
                              </label>
                            </div>
                            <!-- <v-checkbox
                              v-bind:value="true"
                              v-model="rcc.column_size_compliance"
                              class="ma-0 pa-0"
                            ></v-checkbox> -->
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>C Ductility Related Attributes</strong>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">18</td>
                          <td class="registrationtable" rowspan="2">
                            Minimum Number of Bars in Column: At least there shall be 8 numbers of
                            bars in column.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.min_column_bars_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.min_column_bars_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.min_column_bars_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">19</td>
                          <td class="registrationtable" rowspan="2">
                            Stirrups in Column: The minimum diameter of the stirrups shall be 8 mm
                            for fy500 (TMT).
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_stirrups_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_stirrups_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_stirrups_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">20</td>
                          <td class="registrationtable" rowspan="2">
                            Column Bar Splices: Lap splices shall be located only in the central
                            half of the member length. Stirrups shall be located over the entire
                            splice length at spacing not exceeding 100mm centre to center. Not more
                            than 50% of the bars shall preferably be spliced at one section.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_bar_splices_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_bar_splices_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_bar_splices_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">21</td>
                          <td class="registrationtable" rowspan="2">
                            Column stirrups Spacing: Frame columns shall have stirrups spaced at or
                            less than 100mm over a length larger of depth of column (D), clear
                            height of column (h)/6 or 600mm at either end of column.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.column_stirrups_spacing_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.column_stirrups_spacing_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.column_stirrups_spacing_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">22</td>
                          <td class="registrationtable" rowspan="2">
                            Beam Column Joint: In an external joint, both the top and the bottom
                            bars of the beam shall be provided with anchorage length of 60 times dia
                            of longitudinal bar. In all joints, both face bars of the beam shall be
                            taken continuously through the column bar without bending.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.beam_column_joint_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.beam_column_joint_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.beam_column_joint_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">23</td>
                          <td class="registrationtable" rowspan="2">
                            Beam Bar Splices: The lap length shall not be less than the 60 times of
                            longitudinal bar. Lap splices shall not be located (a) within a beam
                            column joint, (b) within a distance of 2 times effective depth of beam
                            from beam column joint face, and (c) within a quarter length of the
                            member where flexural yielding may occur under the effect of earthquake
                            forces. Not more than 50% of the bars shall be spliced at one section.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.beam_bar_splices_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.beam_bar_splices_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.beam_bar_splices_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">24</td>
                          <td class="registrationtable" rowspan="2">
                            Beam Stirrup Spacing: The spacing of stirrups over a length of 2d at
                            either end of a beam shall not exceed 100 mm.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.beam_stirrups_spacing_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.beam_stirrups_spacing_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.beam_stirrups_spacing_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">25</td>
                          <td class="registrationtable" rowspan="2">
                            Joint Reinforcement: Beam- column joints shall have closed stirrups
                            spaced at 100mm
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.joint_reinforcement_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.joint_reinforcement_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.joint_reinforcement_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">26</td>
                          <td class="registrationtable" rowspan="2">
                            Stirrup: The stirrups shall be anchored into the member cores with hooks
                            of 135°. Stirrup should be 75 mm length bend at 135 degree
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="1" v-model="rcc.stirrup_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.stirrup_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input type="radio" value="0" v-model="rcc.stirrup_compliance" />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>D Connection Related Attributes</strong>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">27</td>
                          <td class="registrationtable" rowspan="2">
                            Wall Connection: All infill shall tie to the frame to resist
                            out-of-plane forces.
                          </td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.wall_connection_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="">
                            <v-text-field
                              v-model="rcc.wall_connection_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.wall_connection_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>E. BUILDING BYELAWS</strong>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">28</td>
                          <td class="registrationtable" rowspan="2">Front width</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.front_width_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.front_width_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.front_width_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">29</td>
                          <td class="registrationtable" rowspan="2">Floor Area Ratio(FAR)</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.floor_area_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.floor_area_ratio_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.floor_area_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">30</td>
                          <td class="registrationtable" rowspan="2">Front Setback</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.front_setback_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.front_setback_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.front_setback_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td class="registrationtable" rowspan="2">31</td>
                          <td class="registrationtable" rowspan="2">Ground Coverage Ratio(GCR)</td>
                          <td>Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="1"
                                  v-model="rcc.ground_coverage_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                          <td class="registrationtable" rowspan="2">
                            <v-text-field
                              v-model="rcc.ground_coverage_ratio_remarks"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td>Non-Compliance</td>
                          <td class="registrationtable">
                            <div class="options">
                              <label title="item1">
                                <input
                                  type="radio"
                                  value="0"
                                  v-model="rcc.ground_coverage_ratio_compliance"
                                />
                                <img />
                              </label>
                            </div>
                          </td>
                        </tr>
                        <tr class="registrationtable">
                          <td colspan="5" class="registrationtable">
                            <strong>F REVENUE</strong>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  <v-simple-table class="sober">
                    <thead>
                      <tr class="registrationtable">
                        <th rowspan="2" class="registrationtable">
                          Floor
                        </th>
                        <th rowspan="2" class="registrationtable">
                          Plinth area
                        </th>
                        <th colspan="2" class="registrationtable">
                          Municipality Fees
                        </th>
                        <th rowspan="2" class="registrationtable">
                          Remarks
                        </th>
                      </tr>
                      <tr class="registrationtable">
                        <th class="registrationtable">Rate</th>
                        <th class="registrationtable">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="registrationtable">
                        <td class="registrationtable">Basement 1</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement1_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement1_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement1_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement1_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Basement 2</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement2_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement2_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement2_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement2_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Basement 3</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement3_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement3_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement3_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.basement3_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">First</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.first_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.first_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.first_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.first_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Second</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.second_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.second_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.second_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.second_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Third</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.third_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.third_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.third_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.third_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Fourth</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fourth_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fourth_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fourth_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fourth_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Fifth</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fifth_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fifth_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fifth_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.fifth_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Sixth</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.sixth_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.sixth_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.sixth_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.sixth_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable">Seventh</td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.seventh_plinth_area"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.seventh_rate"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.seventh_amount"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.seventh_remarks"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          <b>Sub Total</b>
                        </td>
                        <td class="registrationtable">
                          <v-text-field :value="subTotal" solo class="ma-0 pa-0"></v-text-field>
                        </td>
                        <td class="registrationtable">
                          <v-text-field solo class="ma-0 pa-0"></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          Layout Charge
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.layout_charge"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                        <td class="registrationtable" rowspan="2"></td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          Design Charge
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.design_charge"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          Form Charge
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.form_charge"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          Application Registration Charge
                        </td>
                        <td class="registrationtable">
                          <v-text-field
                            v-model="rcc.application_registration"
                            solo
                            class="ma-0 pa-0"
                          ></v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          Others
                        </td>
                        <td class="registrationtable">
                          <v-text-field v-model="rcc.others" solo class="ma-0 pa-0"> </v-text-field>
                        </td>
                      </tr>
                      <tr class="registrationtable">
                        <td class="registrationtable" colspan="3">
                          <b>Total Amount</b>
                        </td>
                        <td class="registrationtable">
                          <v-text-field v-model="rcc.total" solo class="ma-0 pa-0"> </v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <v-simple-table class="sober">
                    <thead>
                      <tr>
                        <th class="text-left ">
                          <strong>Observations</strong>
                        </th>
                        <th class=""><strong>Recommendation</strong></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <strong
                            ><v-text-field
                              v-model="rcc.observation"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field
                          ></strong>
                        </td>
                        <td>
                          <strong
                            ><v-text-field
                              v-model="rcc.recommendation"
                              solo
                              class="ma-0 pa-0"
                            ></v-text-field
                          ></strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                  <br />
                </v-card>
                <v-snackbar
                  v-model="snackbar.show"
                  :bottom="y === 'bottom'"
                  :color="snackbar.color"
                  :left="x === 'left'"
                  :multi-line="mode === 'multi-line'"
                  :right="x === 'right'"
                  :timeout="3000"
                  :top="y === 'top'"
                  :vertical="mode === 'vertical'"
                >
                  {{ successfulMessage }}
                </v-snackbar>
                <v-btn v-if="userRoleId == 4" primary v-on:click="save" class="primary ma-3"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Rcc",
  props: {
    nextStep: {
      type: Function
    },
    isPostAuthorized: {
      type: Boolean
    }
  },
  data() {
    return {
      userRoleId: localStorage.getItem("auth_user_role_id"),
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      rcc: {
        id: 0,
        regId: this.$route.params.regId,
        overall_dim_ratio_compliance: "",
        overall_dim_ratio_remarks: "",
        wings_length_compliance: "",
        wings_length_remarks: "",
        setbacks_compliance: "",
        setbacks_remarks: "",
        // one missing field here in prototype
        column_layout_compliance: "",
        column_layout_remarks: "",
        beam_continuity_compliance: "",
        beam_continuity_remarks: "",
        vertical_discontinuity_compliance: "",
        vertical_discontinuity_remarks: "",
        cantilever_projection_compliance: "",
        cantilever_projection_remarks: "",
        short_column_compliance: "",
        short_column_remarks: "",
        torsion_compliance: "",
        torsion_remarks: "",
        adjacent_building_compliance: "",
        adjacent_building_remarks: "",
        soft_storey_compliance: "",
        soft_storey_remarks: "",
        beam_size_compliance: "",
        beam_size_remarks: "",
        strong_column_weak_beam_compliance: "",
        strong_column_weak_beam_remarks: "",
        column_shear_stress_compliance: "",
        column_shear_stress_remarks: "",
        concrete_grade_compliance: "",
        concrete_grade_remarks: "",
        column_size_compliance: "",
        column_size_remarks: "",
        min_column_bars_compliance: "",
        min_column_bars_remarks: "",
        column_stirrups_compliance: "",
        column_stirrups_remarks: "",
        column_bar_splices_compliance: "",
        column_bar_splices_remarks: "",
        column_stirrups_spacing_compliance: "",
        column_stirrups_spacing_remarks: "",
        beam_column_joint_compliance: "",
        beam_column_joint_remarks: "",
        beam_bar_splices_compliance: "",
        beam_bar_splices_remarks: "",
        beam_stirrups_spacing_compliance: "",
        beam_stirrups_spacing_remarks: "",
        joint_reinforcement_compliance: "",
        joint_reinforcement_remarks: "",
        stirrup_compliance: "",
        stirrup_remarks: "",
        wall_connection_compliance: "",
        wall_connection_remarks: "",
        // missing in prototype
        front_width_compliance: "",
        front_width_remarks: "",
        floor_area_ratio_compliance: "",
        floor_area_ratio_remarks: "",
        front_setback_compliance: "",
        front_setback_remarks: "",
        ground_coverage_ratio_compliance: "",
        ground_coverage_ratio_remarks: "",
        // Revenue Section
        basement1_plinth_area: null,
        basement1_rate: null,
        basement1_amount: 0,
        basement1_remarks: null,
        basement2_plinth_area: null,
        basement2_rate: null,
        basement2_amount: 0,
        basement2_remarks: null,
        basement3_plinth_area: null,
        basement3_rate: null,
        basement3_amount: 0,
        basement3_remarks: null,
        first_plinth_area: null,
        first_rate: null,
        first_amount: 0,
        first_remarks: null,
        second_plinth_area: null,
        second_rate: null,
        second_amount: 0,
        second_remarks: null,
        third_plinth_area: null,
        third_rate: null,
        third_amount: 0,
        third_remarks: null,
        fourth_plinth_area: null,
        fourth_rate: null,
        fourth_amount: 0,
        fourth_remarks: null,
        fifth_plinth_area: null,
        fifth_rate: null,
        fifth_amount: 0,
        fifth_remarks: null,
        sixth_plinth_area: null,
        sixth_rate: null,
        sixth_amount: 0,
        sixth_remarks: null,
        seventh_plinth_area: null,
        seventh_rate: null,
        seventh_amount: 0,
        seventh_remarks: null,
        layout_charge: 0,
        design_charge: 0,
        form_charge: 0,
        application_registration: 0,
        others: 0,
        total: 0,
        observation: "",
        recommendation: ""
      }
    };
  },
  computed: {
    subTotal: function() {
      return (
        parseFloat(this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount)
      );
    },
    totalAmount: function() {
      // convert the string to numbers first with the parseFloat function
      return (
        parseFloat(this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount) +
        parseFloat(this.rcc.layout_charge) +
        parseFloat(this.rcc.design_charge) +
        parseFloat(this.rcc.form_charge) +
        parseFloat(this.rcc.application_registration) +
        parseFloat(this.rcc.others)
      );
    }
  },
  watch: {
    totalAmount() {
      // change the value of rcc total if the totalAmount changes
      this.rcc.total = this.totalAmount;
    }
  },
  mounted() {
    console.log("test parseFloat");
    console.log(
      parseFloat(this.rcc.basement1_amount) +
        parseFloat(this.rcc.basement2_amount) +
        parseFloat(this.rcc.basement3_amount) +
        parseFloat(this.rcc.first_amount) +
        parseFloat(this.rcc.second_amount) +
        parseFloat(this.rcc.third_amount) +
        parseFloat(this.rcc.fourth_amount) +
        parseFloat(this.rcc.fifth_amount) +
        parseFloat(this.rcc.sixth_amount) +
        parseFloat(this.rcc.seventh_amount) +
        parseFloat(this.rcc.layout_charge) +
        parseFloat(this.rcc.design_charge) +
        parseFloat(this.rcc.form_charge) +
        parseFloat(this.rcc.application_registration) +
        parseFloat(this.rcc.others)
    );
    console.log(parseFloat(20));
    console.log(parseFloat(30.12));
    const url = `api/registration/${this.$route.params.regId}/rcc`;
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {}
    )
      .then(response => {
        this.rcc = response.data.data;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    save() {
      // console.log(this.$route.params.regId);
      if (this.rcc.id === null || this.rcc.id === 0 || this.rcc.id === undefined) {
        const url = `/api/registration/${this.$route.params.regId}/rcc`;
        VueAxios.post(
          url,
          this.rcc,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            }
          },
          {
            timeout: 10000
          }
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.rcc = response.data.data;
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
            }
          })
          .catch(error => {
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/registration/${this.$route.params.regId}/rcc/${this.rcc.id}`;
        VueAxios.put(
          eurl,
          this.rcc,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data Updated successfully";
            }
            // else {
            //     this.$router.push({
            //         name: 'tabbed',
            //     });
            // }
            console.log(response);
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    edit(id) {
      //    const url = `api/registration/${this.$route.params.regId}/rcc/${this.$route.params.id}`;
      const url = `api/registration/${this.$route.params.regId}/rcc/${id}`;
      VueAxios.get(
        url,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      ).then(response => {
        this.rcc = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecord(id) {
      const deleteurl = `api/registration/${this.$route.params.regId}/rcc/${id}`;
      VueAxios.delete(
        deleteurl,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        {
          timeout: 100000
        }
      )
        .then(response => {
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
.row,
.col {
  height: 58px;
}
.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
