<template>
  <div>
    <v-card height="auto">
      <div>
        <v-btn class="primary back ma-1" :to="{ name: 'home' }">Back</v-btn>
      </div>
      <v-form>
        <v-card>
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              Application Status
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober">
            <template v-slot:default>
              <thead>
                <tr class="registrationtable">
                  <!-- <th class="text-left registrationtable">Level</th> -->
                  <th class="text-left registrationtable" v-for="p in applicationHeader" :key="p">
                    {{ p }}
                  </th>
                  <!-- <th class="text-left registrationtable">Technical Desk</th>
                                                  <th class="text-left registrationtable">Senior Technical Desk</th>
                                            <th class="text-left registrationtable">Executive Desk </th> -->
                  <th class="text-left registrationtable">Issued</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="registrationtable"
                  v-for="applicationStatusList in applicationStatus"
                  :key="applicationStatusList.id"
                >
                  <td class="registrationtable">{{ applicationStatusList["Level"] }}</td>
                  <td class="registrationtable">
                    <p v-html="applicationStatusList['Registration Desk']"></p>
                  </td>
                  <td>{{ applicationStatusList["Technical Desk"] }}</td>
                  <td class="registrationtable">
                    {{ applicationStatusList["Executive Desk"] }}
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Component1",
  props: {
    nextStep: {
      type: Function
    }
  },
  data() {
    return {
      applicationStatus: [],
      applicationHeader: []
    };
  },
  mounted() {
    const url = "api/report/application-status";
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`
        }
      },
      {
        timeout: 100000
      }
    )
      .then(response => {
        this.applicationStatus = response.data.data;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  }
};
</script>
<style scoped></style>
