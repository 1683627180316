<template>
  <div>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar>
      <gallery :id="imageId" :image-udate-path="basePath" />
      <v-simple-table 
        class="sober">
        <template v-slot:default>
          <thead>
            <!-- <tr class="registrationtable">
                <th
                class="text-left registrationtable"
                v-for="titleList in selectedTitle"
                v-bind:key="titleList"
              >
                {{ titleList }}
              </th>
              
            </tr> -->
            <tr class="registrationtable">
              <th
                class="text-left registrationtable"
                v-for="titleList in columnHeader"
                v-bind:key="titleList"
              >
                <span v-if="titleList != 'id'">{{ titleList }} </span>
                <span v-else>action </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="valueList in values" v-bind:key="valueList">
              <td class="registrationtable" v-for="head in columnHeader" v-bind:key="head">
                <span v-if="href.includes(head)">
                  <a
                    v-for="action in actionList"
                    v-bind:key="action"
                    @click="callEdit(valueList[head])"
                    >{{ action }}</a
                  ></span
                >

                <span v-else>{{ valueList[head] }}</span>
              </td>
            </tr>
            <!-- <tr class="registrationtable" v-for="valueList in values" v-bind:key="valueList">
              <td class="registrationtable" v-for="head in columnHeader" v-bind:key="head">
              {{ valueList[head] }}
              </td>
            </tr> -->
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <br />
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Charkilla",

  props: {
    id: Number,

    url: [Number, String],
    columnHeader: [Array],
    href: [Array],
    actionList: [Array],
    photoList: [Array]
    // methodToCall: [Function],
  },

  data() {
    return {
      imageId: 0,
      imageUrl: "process.env.VUE_APP_BASE_URL",
      imageHeaderList: ["photo", "remarks"],
      imageHref: ["id"],
      imageUpdatePath: "http://localhost:8080/#/infrastructureRoad/" + this.imageId,
      basePath: process.env.VUE_APP_BASE_URL,
      title: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      records: [],
      values: []
    };
  },

  computed: {
    selectedTitle() {
      // debugger;
      return this.title.filter(t => {
        return this.columnHeader.some(header => header == t);
      });
    }
  },
  created() {
    //this.methodToCall(id);
    this.apiCall();
  },
  mounted() {
    //this.method(this.value);
  },
  methods: {
    //   methodToCall:function(id){
    //     alert(3);
    //      this.$emit('methodToCall', id);
    // },
    apiCall() {
      // debugger;
      const link = this.url;

      console.log(link);
      const obj = this;
      VueAxios.get(link)
        .then(response => {
          obj.records = response.data.data;
          // obj.columnHeader.forEach((value, index) => {
          // debugger;
          // if (value == "id"){
          //   // obj.columnHeader = "Action";
          //   this.$set(obj.columnHeader, 4 ,"Action");
          //   obj.values = obj.filterRows(obj.records, obj.columnHeader);

          //           obj.title = Object.keys(obj.records[0]);
          // }
          // });
          obj.values = obj.filterRows(obj.records, obj.columnHeader);
          // // debugger;
          obj.title = Object.keys(obj.records[0]);
        })
        .catch(error => {
          // alert(3)
          console.log(`api error:${error}`);
        });
    },
    callEdit(id) {
      this.imageId = id;

      this.$emit("editdetail", id);
    },
    filterRows(rows, headers) {
      // Map over the full list of rows to filter down
      return rows.map(row => {
        // Create a new row to return to avoid mutating
        const filteredRow = {};
        // debugger;
        // Loop through each header and copy the row value corresponding to that header value
        headers.forEach(header => {
          filteredRow[header] = row[header];
        });
        // Return the copied and filtered row
        return filteredRow;
      });
    }
  }
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
label span {
  color: red;
}
</style>
