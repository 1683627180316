<template>
  <div>
    <label>{{ label }}</label>
    <select @change="$emit('input', $event.target.value)" class="form-control">
      <option
        v-for="opt in options"
        :key="opt.value"
        :value="opt.value"
        :selected="value === opt.value"
      >
        {{ opt.label || "No label" }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      required: true
    },

    name: {
      type: String
    },
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      errorMessage: "",
      option: "lorem",
      options: [
        { label: "Select", value: "" },
        { label: "lorem", value: "lorem" },
        { label: "ipsum", value: "ipsum" }
      ]
    };
  }
};
</script>
