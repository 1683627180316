<template>
  <div>
    <general-information :showSkipButton="false" />
  </div>
</template>
<script>
export default {
  data() {
    return {
      nepalidate: "",
    };
  },
};
</script>
