<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-container class="pa-0" dense>
      <v-row class="" dense>
        <v-col xs12 md12 lg12>
          <v-card class="pa-0">
            <v-btn class="primary my-3 mx-3" :to="{ name: 'infrastructuredashboard' }">Back</v-btn>

            <v-btn
              class="primary my-3 mx-3"
              :href="basePath + `/api/bids/electricity-report/pdf/${this.$route.params.regId}`"
            >
              Download electricity report data in pdf
            </v-btn>

            <v-toolbar color="primary " dark>
              <v-col class="d-flex justify-space-around">
                <v-toolbar-title class="text-center justify-center">
                  Electrical Infrastructures Inventory System
                </v-toolbar-title>
              </v-col>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="elevation-12 my-4" dense>
        <v-col xs12 md8 lg8>
          <v-card class="nibedanBibaran elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>
                Information
              </v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" class="mx-4 my-4" v-model="valid">
              <v-row>
                <v-col md="4">
                  <label> Name<span>*</span></label>
                </v-col>

                <v-col md="8">
                  <v-text-field
                    v-model="newelectricityInfo.name"
                    :rules="[required('Name'), maxLength('Name', 30)]"
                    label="Name"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> वडा नं.<span>*</span></label>
                </v-col>

                <v-col md="8">
                  <v-select
                    v-model="newelectricityInfo.ward_no"
                    :rules="[requiredDropdown('वडा नं')]"
                    label="Select ward"
                    :items="wardName"
                    item-value="id"
                    item-text="ward_name"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label> Type of Electricity Infrastructure<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newelectricityInfo.electrical_infrastructure_type_id"
                    :items="electricalInfrastructureType"
                    :rules="[requiredDropdown('Type of Electricity')]"
                    item-value="id"
                    item-text="electrical_infrastructure_type"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="4">
                  <label>Electrical Infrastructure ID<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newelectricityInfo.electrical_infrastructure_id"
                    :rules="[
                      required('Electrical Infrastructure ID'),
                      maxLength('Electrical Infrastructure ID', 30)
                    ]"
                    label="Electrical Infrastructure ID"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  <label> Transmission Power <span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newelectricityInfo.transmission_power"
                    :rules="[
                      requiredNumber('Electrical Infrastructure ID'),
                      maxLength('Electrical Infrastructure ID', 10)
                    ]"
                    label="Transmission Power"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <label> Coordinates<span>*</span></label>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="8"> {{ mapCordinate.latitude }}, {{ mapCordinate.longitude }} </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar.show"
                :bottom="y === 'bottom'"
                :color="snackbar.color"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="3000"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
              >
                {{ successfulMessage }}
              </v-snackbar>
              <v-btn
                primary
                v-on:click="save"
                class="primary"
                v-if="isPostAuthorized"
                :disabled="!valid"
                >Submit</v-btn
              >
              <br />
            </v-form>
            <card
              :url="apiUrl"
              :column-header="headerList"
              :href="href"
              :action-list="actionList"
              :photoL-list="photoView"
              v-on:editdetail="edit"
            />
          </v-card>
        </v-col>
        <v-col xs12 md4 lg4>
          <inventory-map class="order-2" cordinateType="singleCordinate" v-model="mapCordinate" />
          <photographs-upload @file-upload="newelectricityInfo.photos = $event" />
          <gallery :photo-list="PhotoList" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Electricity",
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      isPostAuthorized: true,
      isFormLoading: false,
      images: [],
      headerList: ["name", "ward_no", "transmission_power", "id"],
      href: ["id"],
      apiUrl: `api/bids/infrastructure/electrical-infrastructure`,
      records: [],
      actionList: ["Edit"],
      photoView: ["photo"],
      PhotoList: [],
      files: [],
      wardName: [],
      mapCordinate: {},
      electricalInfrastructureType: [],
      formData: new FormData(),
      electricityInfo: [],
      newelectricityInfo: {
        id: 0,
        ward_no: "",
        name: "",
        electrical_infrastructure_type_id: "",
        electrical_infrastructure_id: "",
        latitude: "",
        longitude: "",
        transmission_power: "",
        photos: []
      },
      valid: false,
      maxLength(propertyType, maxLength) {
        return v =>
          (v && String(v).length <= maxLength) ||
          `${propertyType} must be less than ${maxLength} digits`;
      },
      required(propertyType) {
        return v => (v && String(v).length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return v => v || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return v => (v && v > 0) || `Only number is allowed`;
      },

      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },
      latitude: 0,
      longitude: 0,

      type_id: "",
      user_role_id: localStorage.getItem("auth_user_role_id"),
      id: "single",
      color: { color: "#3F3C3C" }
    };
  },
  computed: {
    isLoading() {
      if (this.electricalInfrastructureType === 0 || this.wardName.length === 0) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    }
  },
  created() {
    this.selectBoxApi();
    const detailUrl = `/api/bids/infrastructure/electrical-infrastructure/${this.$route.params.regId}`
    VueAxios.get(detailUrl)
      .then(response => {
        this.newelectricityInfo = response.data.data;
        this.PhotoList = response.data.data.photos;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    selectBoxApi() {
      const obj = this;
      const electricalInfrastructureUrl = "api/bids/select-box/electrical-infrastructure";
      VueAxios.get(electricalInfrastructureUrl)
        .then(response => {
          obj.electricalInfrastructureType = response.data;
          // console.log(obj.electrical-infrastructure);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then(response => {
          obj.wardName = response.data;
          // console.log(obj.buildingPermanent);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      this.isFormLoading = true;
      this.newelectricityInfo.latitude = this.mapCordinate.latitude;
      this.newelectricityInfo.longitude = this.mapCordinate.longitude;
      const formData = new FormData();
      const files = this.newelectricityInfo.photos;

      if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
        for (let i = 0; i < files.length; i += 1) {
          formData.append("photo[]", files[i], files[i].name);
        }
      }
      formData.append("ward_no", this.newelectricityInfo.ward_no);
      formData.append("name", this.newelectricityInfo.name);
      formData.append(
        "electrical_infrastructure_type_id",
        this.newelectricityInfo.electrical_infrastructure_type_id
      );
      formData.append(
        "electrical_infrastructure_id",
        this.newelectricityInfo.electrical_infrastructure_id
      );

      formData.append("latitude", this.newelectricityInfo.latitude);
      formData.append("longitude", this.newelectricityInfo.longitude);
      formData.append("transmission_power", this.newelectricityInfo.transmission_power);

      console.log(formData);
      formData.append("_method", "POST");

      const saveUrl = `api/bids/infrastructure/electrical-infrastructure`;
      VueAxios.post(saveUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.isFormLoading = false;

          if (response.status === 200 || response.status === 201) {
            this.electricityInfo.push(response.data.data);
            // this.$toastr.s("All images uplaoded successfully");
            this.newelectricityInfo.photo = [];
            // this.photo = [];
            this.snackbar.show = true;
            this.successfulMessage = "Record added successfully.";
            this.$refs.form.reset();
          }
        })
        .catch(error => {
          this.isFormLoading = false;

          this.snackbar.show = true;
          this.successfulMessage = "Saved Failed.";
          console.log(`api error:${error}`);
        });
    },

    edit(id) {
      // debugger;
      const obj = this;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/bids/infrastructure/electrical-infrastructure/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        obj.newelectricityInfo = response.data.data;
        obj.PhotoList = obj.newelectricityInfo.photos;
      });
    }
  }
};
</script>
<style scoped>
label span {
  color: red;
}
.card {
  padding: 15px;
}
</style>
