<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
</style>
<template>
  <div id="app">
    <v-card class="d-flex flex-row md-3">
      <v-card class="d-flex flex-column col-6 md-3 ">
        <NibedanBibaran
          class="order-1"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 2 && results.desk_user_role_id == user_role_id && results.phase_id != 5
          "
        />
        <PrabhidhikBibaran
          class="order-2"
          :regId="this.$route.params.id"
          :isAddEditAuthorized="
            user_role_id == 4 && results.desk_user_role_id == user_role_id && results.phase_id == 1
          "
        />
        <ReceivedCertificates class="order-3" :regId="this.$route.params.id" />
      </v-card>
      <v-card class="d-flex flex-column col-6">
        <v-card class="documents fifteenDaysDocuments elevation-12 my-2">
          <v-toolbar color="primary" dark flat>
            <v-toolbar-title>
              कागजातहरु
            </v-toolbar-title>
          </v-toolbar>
          <v-simple-table class="sober elevation-2">
            <thead>
              <tr>
                <th class="text-left">Name</th>
                <th class="text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in documents" :key="item.name">
                <td>{{ item.name }}</td>
                <td>
                  <span
                    v-if="
                      user_role_id == 2 &&
                        results.desk_user_role_id == user_role_id &&
                        results.phase_id == 2
                    "
                  >
                    <router-link
                      :to="{
                        name: 'fifteendaysdocumentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >Upload</router-link
                    >
                  </span>
                  <span v-else>
                    <router-link
                      :to="{
                        name: 'fifteendaysdocumentupload',
                        params: { id: item.id, regId: id, sn: item.sn }
                      }"
                      >View</router-link
                    >
                  </span>
                </td>
              </tr>
            </tbody>
            <!-- </template> -->
          </v-simple-table>
          <RegistrationAction
            v-if="user_role_id == results.desk_user_role_id && results.phase_id !== 5"
            :regPhaseId="this.results.phase_id"
            :regDeskId="this.results.desk_user_role_id"
          />
        </v-card>
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NibedanBibaran from "../subComponents/NibedanBibaran.vue";
import PrabhidhikBibaran from "../subComponents/PrabhidhikBibaran.vue";
import ReceivedCertificates from "../subComponents/ReceivedCertificates.vue";
import RegistrationAction from "../subComponents/RegistrationAction.vue";
//   import Application from '../../../modules/tabbedElements/components/Application.vue';

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function
    },
    id: {
      type: Number
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  components: {
    NibedanBibaran,
    PrabhidhikBibaran,
    ReceivedCertificates,
    RegistrationAction
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(url).then(response => {
      // debugger;
      this.results = response.data.data;
      // console.log(response.data.data);
    });
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      results: [],
      documents: [
        {
          id: 1,
          name: "नक्सा पासको लागि १५ दिने टाँस मुचुल्का",
          value: "jaggadhani",
          action: "Save as Pdf",
          status: "",
          sn: 1
        },
        {
          id: 2,
          name: "सरजमिन मुचुल्का",
          value: "malpot",
          action: "Save as Pdf",
          status: "",
          sn: 2
        },
        {
          id: 3,
          name: "जमिनको प्राविधिक प्रतिवेदन",
          value: "jaggadhani-purja",
          action: "Save as Pdf",
          status: "",
          sn: 3
        },
        {
          id: 4,
          name: "प्लिन्थ लेभलसम्मको निर्माणको इजाजतको लागि निवेदन",
          value: "jaggadhani-purja",
          action: "Save as Pdf",
          status: "completed",
          sn: 4
        },
        {
          id: 5,
          name: "सुपरिवेक्षक/कन्सल्टेन्ट तथा घरधनी बीच सम्झौता पत्र",
          value: "blueprint",
          action: "Upload",
          status: "",
          sn: 5
        },
        {
          id: 6,
          name: "निर्माणकर्मी/ठेकेदार तथा घरधनी बीच सम्झौता पत्र",
          value: "designer-certificate",
          action: "Upload",
          status: "",
          sn: 6
        },
        {
          id: 7,
          name: "प्लिन्थ लेभलसम्म निर्माण कार्यको ईजाजत पत्र्र",
          value: "manjurinama",
          action: "Upload",
          status: "",
          sn: 7
        }
      ]
    };
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
