<template>
  <v-card class="nibedanBibaran elevation-12 my-2">
    <v-toolbar color="primary" dark flat>
      <v-toolbar-title>
        निवेदन विवरणहरु
      </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <thead>
        <tr class="">
          <th class="text-left ">Name</th>
          <th class="text-left ">Actions</th>
          <th class="text-left ">Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in nibedanBibarans" :key="item.name" class="">
          <td class="">{{ item.name }}</td>
          <td class="">
            <router-link
              :to="{ name: 'tabbed', params: { id: item.id, regId: regId, sn: item.sn } }"
              >{{ actionButtonText }}</router-link
            >
          </td>
          <td>{{ item.status }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </v-card>
</template>

<script>
export default {
  name: "NibedanBibaran",
  props: {
    regId: {
      type: Number
    },
    isAddEditAuthorized: {
      type: Boolean
    }
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    }
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      registrationStatus: [],
      nibedanBibarans: [
        {
          id: 1,
          name: " निवेदन",
          nameEng: "application",
          action: "Add/Edit",
          status: "",
          sn: 1
        },
        {
          id: 2,
          name: "घरधनीको विवरण",
          nameEng: "houseowner_detail",
          action: "Add/Edit",
          status: "",
          sn: 2
        },
        {
          id: 3,
          name: "डिजाइनर",
          nameEng: "designer",
          action: "Add/Edit",
          status: "",
          sn: 3
        },
        {
          id: 4,
          name: "सुपरभाइजर",
          nameEng: "supervisor",
          action: "Add/Edit",
          status: "",
          sn: 4
        },
        {
          id: 5,
          name: " जग्गाको विवरण (ठेगाना)",
          nameEng: "land_info_address",
          action: "Add/Edit",
          status: "",
          sn: 5
        },
        {
          id: 6,
          name: "चारकिल्ला",
          nameEng: "charkilla",
          action: "Add/Edit",
          status: "",
          sn: 6
        },
        {
          id: 7,
          name: "जग्गाको विवरण (क्षेत्रफल)",
          nameEng: "land_info_area",
          action: "Add/Edit",
          status: "",
          sn: 7
        },
        {
          id: 8,
          name: "जग्गाधनीको विवरण",
          nameEng: "landowner_info",
          action: "Add/Edit",
          status: "",
          sn: 8
        },
        {
          id: 9,
          name: "निर्माणकर्मी / ठेकेदार",
          nameEng: "contractor",
          action: "Add/Edit",
          status: "",
          sn: 9
        },
        {
          id: 10,
          name: "निवेदकको विवरण",
          nameEng: "applicant",
          action: "Add/Edit",
          status: "",
          sn: 10
        }
      ]
    };
  },
  mounted() {
    const statusUrl = `api/registration/${this.regId}/status`;
    const temp = this.nibedanBibarans;

    this.$axios
      .get(statusUrl)
      .then(response => {
        this.registrationStatus = response.data.data;

        temp.forEach(item => {
          this.nibedanBibarans[item.sn - 1].status = this.registrationStatus[item.nameEng];
        });
      })
      .catch(error => {
        console.log(`api error:${error}`);
        temp.forEach(item => {
          this.nibedanBibarans[item.sn - 1].status = "Not Completed";
        });
      });
  }
};
</script>

<style scoped></style>
