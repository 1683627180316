<style>
#styled-input {
  height: 20px;
  font-size: 30pt;
  width: 10px;
}
.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}
</style>
<template>
  <div>
    <v-row class="mx-2 my-2">
      <v-spacer></v-spacer>
      <v-btn
        large
        depressed=""
        class="primary right mb-2 subtitle-1"
        :to="{ name: 'new_registration' }"
      >
        <v-icon left>mdi-plus</v-icon>
        नयाँ दर्ता
      </v-btn>
      <!-- <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'inventory_tab' }"
        >
          <v-icon left>mdi-plus</v-icon>
          Inventory
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureRoad', params: { id: 'road' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Road
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureBridge', params: { id: 'bridge' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Bridge
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureElectricity', params: { id: 'electricity' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Electricity Infrastructure
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureWater', params: { id: 'water' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Water Supply Infrastructure
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureRetainingStructure', params: { id: 'retainingstructure' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Retaining Structure Infrastructure
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'infrastructureOther', params: { id: 'other' } }"
        >
          <v-icon left>mdi-plus</v-icon>
          Other Infrastructure
        </v-btn>
        <v-btn
          large
          depressed=""
          class="primary right mb-2 subtitle-1"
          :to="{ name: 'resultAnalysis' }"
        >
          <v-icon left>mdi-plus</v-icon>
          Results and analysis
        </v-btn> -->
    </v-row>

    <div>
      <loading :active.sync="isDataLoading" :can-cancel="true" :is-full-page="false"></loading>
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title> दर्ता भएका निवेदनहरु </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="10"
        :hide-default-header="false"
        :loading="isLoading"
        item-key="name"
        class="sober elevation-12"
      >
        <template v-slot:body.prepend>
          <tr>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.houseowner_name_np"
                @keyup="searchFilters"
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.registration_date_np"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.internal_code"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.file_code"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.phase"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.desk"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td>
              <v-text-field
                label="Search"
                type="text"
                v-model="searchKeys.documents_status"
                @keyup="searchFilters"
                >></v-text-field
              >
            </td>
            <td colspan="2"></td>
          </tr>
        </template>
        <template v-slot:item.yellowslip="{ item }">
          <v-btn color="primary" small :to="{ name: 'yellowslip', params: { regId: item.id } }"
            >Yellow slip
          </v-btn>
        </template>
        <template v-slot:item.status="{ item }">
          <v-btn color="primary" small :to="{ name: 'application_tab', params: { id: item.id } }"
            >Add More
          </v-btn>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  data() {
    return {
      isDataLoading: true,
      enabled: null,
      DESSERTS: [],
      items: [],
      search: null,
      slots: [
        "body",
        "body.append",
        "body.prepend",
        "footer",
        "header.data-table-select",
        "header",
        "progress",
        "item.data-table-select",
        "item.<name>",
        "no-data",
        "no-results",
        "top",
      ],
      headers: [
        {
          text: "घरधनीको नाम",
          align: "start",
          sortable: false,
          value: "houseowner_name_np",
        },
        { text: "मिति (वि.स.)", value: "registration_date_np" },
        { text: "कित्ता नं", value: "internal_code" },
        { text: "Code No", value: "file_code" },
        { text: "स्थिति", value: "phase" },
        { text: "Desk", value: "desk" },
        { text: "Status ", value: "documents_status" },
        { text: "Yellow Slip", value: "yellowslip" },
        { text: "कार्य", value: "status" },
      ],
      searchKeys: {
        houseowner_name_np: "",
        registration_date_np: "",
        internal_code: "",
        file_code: "",
        phase: "",
        desk: "",
        documents_status: "",
      },
    };
  },
  computed: {
    isLoading() {
      return this.isEnabled("progress");
    },
  },

  watch: {
    enabled(slot) {
      if (slot === "no-data") {
        this.items = [];
      } else if (slot === "no-results") {
        this.search = "...";
      } else {
        this.search = null;
      }
    },
  },

  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    searchFilter() {},
    isEnabled(slot) {
      return this.enabled === slot;
    },
    searchFilters() {
      // alert();
      // const houseowner_name_np=this.houseowner_name_np;
      //  debugger;
      const statusurl = "api/registration/search";
      VueAxios.post(
        statusurl,
        this.searchKeys,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        },
        {
          // timeout: 1000000,
        }
      ).then((response) => {
        // debugger;
        // this.searchKeys.houseowner_name_np = response.data.data.searchKeys;
        // console.log(this.DESSERTS);
        this.items = response.data.data;
      });
    },
  },
  mounted() {
    this.scrollToTop();
    console.log(localStorage.getItem("token"));
    const url = "api/registration";
    VueAxios.get(
      url,
      {
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      },
      {
        // timeout: 1000000,
      }
    ).then((response) => {
      // debugger;
      this.DESSERTS = response.data.data;
      this.items = response.data.data;
      // Stop the progress bar
      this.isDataLoading = false;
    });
  },
};
</script>
