<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <v-container class="pa-0" dense>
      <v-row class="" dense>
        <v-col xs12 md12 lg12>
          <v-card class="pa-0">
            <v-btn class="primary my-3 mx-3" :to="{ name: 'infrastructuredashboard' }">Back</v-btn>

            <v-toolbar color="primary " dark>
              <v-col class="d-flex justify-space-around">
                <v-toolbar-title class="text-center justify-center">
                  Retaining Structures Inventory System
                </v-toolbar-title>
              </v-col>
            </v-toolbar>
          </v-card>
        </v-col>
      </v-row>
      <v-row class="elevation-12" dense>
        <v-col xs12 md8 lg8>
          <v-card class="nibedanBibaran elevation-12">
            <v-toolbar color="primary" dark>
              <v-toolbar-title>
                Information
              </v-toolbar-title>
            </v-toolbar>
            <v-form ref="form" class="mx-4 my-4" v-model="valid">
              <v-row>
                <v-col cols="4">
                  <label> Name of retaining structure<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newretainingStructure.name"
                    :rules="[required('Name')]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="4">
                  Ward No.
                </v-col>
                <v-col cols="8">
                  <v-select
                    v-model="newretainingStructure.ward_no"
                    :rules="[requiredDropdown('वडा नं')]"
                    label="Select ward"
                    :items="wardName"
                    item-value="id"
                    item-text="ward_name"
                    :key="id"
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <!-- <selectbox
                v-model="newretainingStructure.ward_no"
                :rules="[requiredDropdown('वडा नं')]"
              /> -->
              <!-- <v-row>
                <v-col cols="4">
                  <label> Age<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newretainingStructure.age"
                    :rules="[requiredNumber('Age'), maxLength('Age', 3)]"
                  ></v-text-field>
                </v-col>
              </v-row> -->

              <!-- <v-row>
                <v-col cols="4">
                  <label> Bridge ID<span>*</span></label>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    v-model="newretainingStructure.bridge_id"
                    :rules="[required('bridgeId'), maxLength('bridgeId', 10)]"
                    label="Bridge ID"
                  ></v-text-field>
                </v-col>
              </v-row> -->
              <v-row>
                <v-col cols="4">
                  <label>Co-ordinate <span>*</span></label></v-col
                >
                <v-col cols="8">{{ mapCordinate.latitude }}, {{ mapCordinate.longitude }} </v-col>
              </v-row>
              <v-snackbar
                v-model="snackbar.show"
                :bottom="y === 'bottom'"
                :color="snackbar.color"
                :left="x === 'left'"
                :multi-line="mode === 'multi-line'"
                :right="x === 'right'"
                :timeout="3000"
                :top="y === 'top'"
                :vertical="mode === 'vertical'"
              >
                {{ successfulMessage }}
              </v-snackbar>
              <v-btn
                primary
                v-if="isPostAuthorized"
                v-on:click="save"
                class="primary"
                :disabled="!valid"
                >Submit</v-btn
              >
              <!-- <v-btn primary v-on:click="save" class="primary" :disabled="!valid">Submit</v-btn> -->
              <br />
            </v-form>
            <card
              :url="apiUrl"
              :column-header="headerList"
              :href="href"
              :action-list="actionList"
              :photoL-list="photoView"
              v-on:editdetail="edit"
            />
          </v-card>
        </v-col>
        <v-col xs12 md4 lg4>
          <!-- <inventoryMap class="order-2" :features22="features" cordinate-type="Road" /> -->
          <inventory-map class="order-2" cordinateType="singleCordinate" v-model="mapCordinate" />
          <photographs-upload @file-upload="newretainingStructure.photo = $event" />
          <gallery :photo-list="PhotoList" />
        </v-col>
      </v-row>
    </v-container>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Login",
  data() {
    return {
      isPostAuthorized: true,
      isFormLoading: false,
      headerList: ["name", "ward_no", "id"],
      href: ["id"],
      actionList: ["Edit"],
      photoView: ["photo"],
      PhotoList: [],
      images: [],
      apiUrl: `api/bids/infrastructure/retaining-works`,
      records: [],
      files: [],

      mapCordinate: {},

      formData: new FormData(),
      retainingStructure: [],
      newretainingStructure: {
        id: 0,
        name: "",

        // age: "",
        ward_no: "",
        photo: [],
        // bridge_id: "",
        longitude: "",
        latitude: ""
      },
      valid: false,
      maxLength(propertyType, maxLength) {
        return v =>
          (v && v.length <= maxLength) ||
          `${propertyType} must be less than ${maxLength} characters`;
      },
      required(propertyType) {
        return v => (v && String(v).length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return v => v || `${propertyType} is required`;
      },
      requiredNumber(propertyType) {
        return v => (v && v > 0) || `Only number is allowed`;
      },
      successfulMessage: "",
      wardName: [],
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      },

      option: "",

      type_id: "",
      user_role_id: localStorage.getItem("auth_user_role_id"),

      color: { color: "#3F3C3C" }
    };
  },
  // computed: {
  //   isLoading() {
  //     if (this.wardName.length === 0) {
  //       // all drop-down values not initiated from api
  //       return true;
  //     } else if (this.isFormLoading) {
  //       return true;
  //     }
  //     return false;
  //   }
  // },
  created() {
    this.selectBoxApi();
    const detailUrl = `/api/bids/infrastructure/retaining-works/${this.$route.params.regId}`
    VueAxios.get(detailUrl)
      .then(response => {
        this.newretainingStructure = response.data.data;
        this.PhotoList = response.data.data.photos;
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },

  methods: {
    selectBoxApi() {
      const obj = this;

      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then(response => {
          obj.wardName = response.data;
          // console.log(obj.buildingPermanent);
          // debugger;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      this.isFormLoading = true;
      this.newretainingStructure.latitude = this.mapCordinate.latitude;
      // debugger;
      this.newretainingStructure.longitude = this.mapCordinate.longitude;
      const formData = new FormData();
      const files = this.newretainingStructure.photo;

      if (files.length > 0 && files[0].size < 1024 * 1024 * 5) {
        for (let i = 0; i < files.length; i += 1) {
          formData.append("photo[]", files[i], files[i].name);
        }
      }
      formData.append("name", this.newretainingStructure.name);
      formData.append("ward_no", this.newretainingStructure.ward_no);
      formData.append("latitude", this.newretainingStructure.latitude);
      formData.append("longitude", this.newretainingStructure.longitude);

      console.log(formData);
      formData.append("_method", "POST");

      const saveUrl = `api/bids/infrastructure/retaining-works`;
      VueAxios.post(saveUrl, formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          this.isFormLoading = false;

          if (response.status === 200 || response.status === 201) {
            this.retainingStructure.push(response.data.data);
            // this.$toastr.s("All images uplaoded successfully");
            this.newretainingStructure.photo = [];
            // this.photo = [];
            this.snackbar.show = true;
            this.successfulMessage = "Record added successfully.";
            this.$refs.form.reset();
          }
        })
        .catch(error => {
          this.isFormLoading = false;
          this.snackbar.show = true;
          this.successfulMessage = "Saved Failed.";
          console.log(`api error:${error}`);
        });
    },
    edit(id) {
      // debugger;
      const obj = this;
      //    const url = `api/registration/${this.$route.params.regId}/application/${this.$route.params.id}`;
      const url = `api/bids/infrastructure/retaining-works/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        obj.newretainingStructure = response.data.data;
        obj.PhotoList = obj.newretainingStructure.photos;
      });
    }
  }
};
</script>
<style scoped>
label span {
  color: red;
}
.card {
  padding: 15px;
}
</style>
