<template>
  <div>
    <v-card class="mb-12" color="grey lighten-1" height="auto">
      <v-app id="inspire">
        <div>
          <v-btn class="primary" :to="{ name: 'home' }">Back</v-btn>
        </div>
        <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
        <v-form ref="form" v-model="valid">
          <v-container>
            <v-row>
              <v-col md="6">
                <v-radio-group v-model="newconsultancy.is_consulting_firm" :mandatory="true">
                  <v-radio label="Consulting Firm" v-bind:value="true"></v-radio>
                  <v-radio label="Freelancer" v-bind:value="false"></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="2">
                Registration Number
              </v-col>
              <v-col cols="4">
                <v-text-field
                  v-model="newconsultancy.registration_number"
                  class="custom-text-field"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <br />
            <v-row>
              <v-col cols="2">
                District
              </v-col>
              <v-col cols="4">
                <v-select
                  v-model="newconsultancy.district_id"
                  :items="districts"
                  item-value="id"
                  item-text="district_name"
                  :key="id"
                  solo
                ></v-select>
              </v-col>
              <v-col md="2">
                Location
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="newconsultancy.location"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col md="2">
                Registration Date
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="newconsultancy.registration_date_np"
                  label=""
                  class="custom-text-field"
                  ref="dateField"
                  required
                ></v-text-field>
              </v-col>
              <v-col md="2">
                Name
              </v-col>
              <v-col md="4">
                <v-text-field
                  v-model="newconsultancy.name"
                  label=""
                  height="10"
                  solo
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <v-btn
            v-if="userRoleId == 1"
            primary
            v-on:click="saveConsultancy"
            class="primary"
            :disabled="!valid"
            >Submit</v-btn
          >
        </v-form>
        <v-snackbar
          v-model="snackbar.show"
          :bottom="y === 'bottom'"
          :color="snackbar.color"
          :left="x === 'left'"
          :multi-line="mode === 'multi-line'"
          :right="x === 'right'"
          :timeout="3000"
          :top="y === 'top'"
          :vertical="mode === 'vertical'"
        >
          {{ successfulMessage }}
        </v-snackbar>
        <!-- <v-toolbar color="primary mt-2 pa-0" dark dense flat>
        <v-toolbar-title>
          List
        </v-toolbar-title>
      </v-toolbar> -->
        <v-simple-table class="sober">
          <template v-slot:default>
            <thead>
              <tr class="registrationtable">
                <th class="text-left registrationtable">
                  Consultancy Name
                </th>
                <th class="text-left registrationtable">
                  Registration Number
                </th>
                <th class="text-left registrationtable">Location</th>
                <th class="text-left registrationtable">Registration Date</th>
                <th class="text-left registrationtable">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="registrationtable"
                v-for="consultancyList in consultancy"
                :key="consultancyList.id"
              >
                <td class="registrationtable">{{ consultancyList.name }}</td>
                <td class="registrationtable">
                  {{ consultancyList.registration_number }}
                </td>
                <td class="registrationtable">{{ consultancyList.location }}</td>
                <td class="registrationtable">{{ consultancyList.registration_date_np }}</td>
                <td>
                  <v-btn class="primary" small v-on:click="editConsultancy(consultancyList.id)"
                    >Edit</v-btn
                  >
                  <v-btn
                    small
                    class="primary"
                    v-on:click="deleterecordConsultancy(consultancyList.id)"
                    >Delete</v-btn
                  >
                  <br />
                  <br />
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
// import * as nepaliDatePicker from "../../../node_modules/nepali-date-picker/dist/jquery.nepaliDatePicker.min.js"; /*eslint-disable-line*/
// import "../../../node_modules/nepali-date-picker/dist/nepaliDatePicker.min.css";

export default {
  name: "consultancy",

  data() {
    return {
      isLoading: false,
      userRoleId: localStorage.getItem("auth_user_role_id"),
      valid: false,
      date: "",
      consultancy: [],
      districts: [],
      newconsultancy: {
        id: 0,
        reg_id: this.registrationId,
        is_consulting_firm: "",
        registration_number: "",
        name: "",
        location: "",
        district_id: "",
        registration_date_np: "",
        registration_date: "2020-04-04"
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success"
      }
    };
  },
  created() {
    this.getDistricts();
  },
  mounted() {
    // const { dateField } = this.$refs;
    // if (dateField) {
    //   $(dateField.$el).nepaliDatePicker({
    //     dateFormat: "%y-%m-%d",
    //     closeOnDateSelect: true
    //   });
    //   $(dateField.$el).on("dateSelect", event => {
    //     this.newconsultancy.registration_date_np = event.datePickerData.formattedDate;
    //   });
    // }
    const ConsultancyListUrl = "api/consultancy";
    VueAxios.get(ConsultancyListUrl)
      .then(response => {
        // debugger;
        this.consultancy = response.data.data;
        // this.newconsultancy.clear();
      })
      .catch(error => {
        console.log(`api error:${error}`);
      });
  },
  methods: {
    getDistricts() {
      const districtUrl = "api/districts";
      VueAxios.get(districtUrl)
        .then(response => {
          // debugger;
          this.districts = response.data.data;
        })
        .catch(error => {
          console.log(`api error:${error}`);
        });
    },
    clearAllData() {
      this.newconsultancy.id = 0;
      this.newconsultancy.reg_id = this.registrationId;
      this.newconsultancy.is_consulting_firm = "";
      this.newconsultancy.registration_number = "";
      this.newconsultancy.name = "";
      this.newconsultancy.location = "";
      this.newconsultancy.district_id = "";
      this.newconsultancy.registration_date_np = "";
      this.newconsultancy.registration_date = "2043-3-3";
    },
    saveConsultancy() {
      this.isLoading = true;
      //   debugger;
      if (
        this.newconsultancy.id === "null" ||
        this.newconsultancy.id === 0 ||
        this.newconsultancy.id === undefined
      ) {
        const saveUrl = "/api/consultancy";
        VueAxios.post(saveUrl, this.newconsultancy)
          .then(response => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.consultancy.push(response.data.data);
              this.snackbar.show = true;
              this.successfulMessage = "Successful.";
              this.$refs.form.reset();
            }
          })
          .catch(error => {
            // alert(2);
            console.log(`api error:${error}`);
          });
      } else {
        const eurl = `/api/consultancy/${this.newconsultancy.id}`;
        VueAxios.put(
          eurl,
          this.newconsultancy,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${window.localStorage.getItem("token")}`
            },
            _method: "patch"
          },
          {}
        )
          .then(response => {
            this.isLoading = false;
            // debugger;
            if (response.status === 200 || response.status === 201) {
              this.snackbar.show = true;
              this.successfulMessage = "Data updated successfully";
              this.$refs.form.reset();
              this.newconsultancy.id = 0; // reset the edit id
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    editConsultancy(id) {
      //   debugger;
      //    const url = `api/registration/${this.$route.params.regId}/consultancy/${this.$route.params.id}`;
      const url = `api/consultancy/${id}`;
      VueAxios.get(url).then(response => {
        // debugger;
        this.newconsultancy = response.data.data;
        console.log(response.data.data);
      });
    },
    deleterecordConsultancy(id) {
      const deleteurl = `api/consultancy/${id}`;
      VueAxios.delete(deleteurl)
        .then(response => {
          // debugger;
          if (response.status === 200 || response.status === 201) {
            this.snackbar.show = true;
            this.successfulMessage = "Data deleted successfully";
            const i = this.consultancy.map(item => item.id).indexOf(id); // find index of your object
            this.consultancy.splice(i, 1);
          }
          console.log(response);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped></style>
