import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../modules/home/Home.vue";
import Login from "../modules/login/Login.vue";
import Index from "../modules/Index.vue";
import NewRegistration from "../modules/home/NewRegistration.vue";
import RegistrationInfo from "../modules/home/RegistrationInfo.vue";
import Yellowslip from "../modules/home/Yellowslip.vue";
import Rcc from "../modules/home/Rcc.vue";
import Masonry from "../modules/home/Masonry.vue";
import ApplicationstatusPage from "../modules/home/ApplicationstatusPage.vue";
import ApplicationTab from "../modules/applicationlevel/ApplicationTab.vue";
import Report from "../modules/home/Report.vue";
import InfrastructureRoad from "../modules/BIDS/infrastructure/InfrastructureRoad.vue";
import InfrastructureBridge from "../modules/BIDS/infrastructure/InfrastructureBridge.vue";
import InfrastructureElectricity from "../modules/BIDS/infrastructure/InfrastructureElectricity.vue";
import InfrastructureWater from "../modules/BIDS/infrastructure/InfrastructureWater.vue";
import InfrastructureRetainingStructure from "../modules/BIDS/infrastructure/InfrastructureRetainingStructure.vue";
import InfrastructureOther from "../modules/BIDS/infrastructure/InfrastructureOther.vue";
import ResultAnalysis from "../modules/BIDS/inventory/ResultAnalysis.vue";
import InfrastructureResultAnalysis from "../modules/BIDS/infrastructure/InfrastructureResultAnalysis.vue";
import Map from "../modules/home/Map.vue";
import Mason from "../modules/home/Mason.vue";
import Consultant from "../modules/home/Consultant.vue";
import MunicipalityDetail from "../modules/home/MunicipalityDetail.vue";
import InventoryTab from "../modules/BIDS/inventory/inventorylevel/InventoryTab.vue";
import Dashboard from "../modules/home/Dashboard.vue";
import inventoryDashboard from "../modules/BIDS/inventory/inventoryDashboard.vue";
import BuildingInformation from "../modules/BIDS/inventory/BuildingInformation.vue";
import infrastructureDashboard from "../modules/BIDS/infrastructure/infrastructureDashboard.vue";
import BidReport from "../modules/home/BidReport.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      const token = window.localStorage.getItem("token");
      const is_bids_access = localStorage.getItem("is_bids_access");
      const is_bps_access = localStorage.getItem("is_bps_access");

      if (token != undefined) {
        // forward to home if token is present i.e user is already logged in
        //console.log(is_bids_access);debugger;
        // const user = JSON.parse(window.localStorage.getItem("user"));
        // console.log(user);debugger;
        if (is_bids_access == 1 && is_bps_access == 1) {
          next({
            name: "dashboard"
          });
        } else if (is_bps_access == 1) {
          next({
            name: "home"
          });
        } else {
          next();
        }
      } else {
        next();
      }
    }
  },

  {
    path: "/rcc/:regId",
    name: "rcc",
    component: Rcc,
    props: true,
    beforeEnter: (to, from, next) => {
      // console.log("beforeEnter");
      // console.log(window.localStorage.getItem("user"));
      const token = window.localStorage.getItem("token");

      if (token) {
        next();
      } else {
        next({
          name: "login"
        });
      }
    }
  },
  {
    path: "/masonry/:regId",
    name: "masonry",
    component: Masonry,
    props: true,
    beforeEnter: (to, from, next) => {
      const token = window.localStorage.getItem("token");

      if (token) {
        next();
      } else {
        next({
          name: "login"
        });
      }
    }
  },
  {
    path: "/yellowslip/:regId",
    name: "yellowslip",
    component: Yellowslip,
    props: true,
    beforeEnter: (to, from, next) => {
      const token = window.localStorage.getItem("token");

      if (token) {
        next();
      } else {
        next({
          name: "login"
        });
      }
    }
  },
  {
    path: "/",
    name: "index",
    component: Index,
    beforeEnter: (to, from, next) => {
      console.log("beforeEnter Index");
      // console.log(window.localStorage.getItem("user"));
      const token = window.localStorage.getItem("token");

      if (token) {
        next();
      } else {
        next({
          name: "login"
        });
      }
    },
    children: [
      {
        path: "/home",
        name: "home",
        component: Home,
        beforeEnter: (to, from, next) => {
          const token = localStorage.getItem("token");
          const accesstoken = localStorage.getItem("access_token");
          const is_bids_access = localStorage.getItem("is_bids_access");
          const is_bps_access = localStorage.getItem("is_bps_access");

          if (token) {
            // forward to home if token is present i.e user is already logged in
            // console.log(user);debugger;
            // debugger;
            // const user = JSON.parse(window.localStorage.getItem("user"));

            if (is_bps_access == 1 || is_bids_access == 1) {
              next();
            } else {
              next({
                name: "login"
              });
            }
          } else {
            next({
              name: "login"
            });
          }
        }
      },
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          const token = localStorage.getItem("token");
          const accesstoken = localStorage.getItem("access_token");
          const is_bids_access = localStorage.getItem("is_bids_access");
          const is_bps_access = localStorage.getItem("is_bps_access");

          if (token) {
            // forward to home if token is present i.e user is already logged in
            // console.log(user);debugger;
            // debugger;
            // const user = JSON.parse(window.localStorage.getItem("user"));
            if (is_bids_access == 1 && is_bps_access == 1) {
              next();
            } else if (is_bids_access == 1) {
              next();
            } else if (is_bps_access == 1) {
              next({
                name: "home"
              });
            } else {
              next({
                name: "login"
              });
            }
          } else {
            next({
              name: "login"
            });
          }
        }
      },
      {
        path: "/inventorydashboard",
        name: "inventorydashboard",
        component: inventoryDashboard
      },
      {
        path: "/buildinginformation",
        name: "buildinginformation",
        component: BuildingInformation
      },
      {
        path: "/infrastructuredashboard",
        name: "infrastructuredashboard",
        component: infrastructureDashboard
      },
      {
        path: "newregistration",
        name: "new_registration",
        component: NewRegistration
      },
      {
        path: "report",
        name: "report",
        component: Report
      },
      {
        path: "bidreport",
        name: "bidreport",
        component: BidReport
      },
      {
        path: "resultAnalysis",
        name: "resultAnalysis",
        component: ResultAnalysis
      },
      {
        path: "infrastructureResultAnalysis",
        name: "infrastructureResultAnalysis",
        component: InfrastructureResultAnalysis
      },
      {
        path: "infrastructureRoad/:id/:regId",
        name: "infrastructureRoad",
        component: InfrastructureRoad
      },
      {
        path: "infrastructureBridge/:id/:regId",
        name: "infrastructureBridge",
        component: InfrastructureBridge
      },

      {
        path: "infrastructureElectricity/:id/:regId",
        name: "infrastructureElectricity",
        component: InfrastructureElectricity
      },
      {
        path: "infrastructureWater/:id/:regId",
        name: "infrastructureWater",
        component: InfrastructureWater
      },
      {
        path: "infrastructureRetainingStructure/:id/:regId",
        name: "infrastructureRetainingStructure",
        component: InfrastructureRetainingStructure
      },
      {
        path: "infrastructureOther/:id/:regId",
        name: "infrastructureOther",
        component: InfrastructureOther
      },
      {
        path: "applicationstatuspage",
        name: "applicationstatuspage",
        component: ApplicationstatusPage
      },
      {
        path: "map",
        name: "map",
        component: Map
      },
      {
        path: "mason",
        name: "mason",
        component: Mason
      },
      {
        path: "consultancy",
        name: "consultancy",
        component: Consultant
      },
      {
        path: "/municipalitydetail",
        name: "municipalitydetail",
        component: MunicipalityDetail
      },
      {
        path: "applicationtab/:id",
        name: "application_tab",
        component: ApplicationTab
      },
      {
        path: "inventorytab/:id",
        name: "inventory_tab",
        component: InventoryTab
      },
      {
        path: "registrationinfo",
        name: "registrationinfo",
        component: RegistrationInfo
      },
      // {
      //   path: 'yellowslip',
      //   name: 'yellowslip',
      //   component: Yellowslip,
      // },
      {
        path: "tabbed/:id/:regId/:sn",
        name: "tabbed",
        component: () =>
          import(/* webpackChunkName: "multiple" */ "../modules/tabbedElements/Index.vue"),
        props: true
      },
      // {
      //   path: "generalinfo",
      //   name: "generalinfo",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "multiple" */ "../modules/BIDS/inventory/inventoryTabbedElements/components/GeneralInfo.vue"
      //     ),

      // },
      {
        path: "inventoryTabbedelements/:id/:regId/:sn",
        name: "inventorytabbedelements",
        component: () =>
          import(
            /* webpackChunkName: "multiple" */ "../modules/BIDS/inventory/inventoryTabbedElements/InventoryIndex.vue"
          ),
        props: true
      },
      {
        path: "documentupload/:id/:regId/:sn",
        name: "documentupload",
        component: () =>
          import(
            /* webpackChunkName: "multiple" */ "../modules/documentUpload/DocumentUploadTab.vue"
          ),
        props: true
      },
      {
        path: "fifteendaysdocumentupload/:id/:regId/:sn",
        name: "fifteendaysdocumentupload",
        component: () =>
          import(
            /* webpackChunkName: "multiple" */ "../modules/fifteenDaysDocumentUpload/FifteenDaysDocumentUploadTab.vue"
          ),
        props: true
      },
      {
        path: "plinthdocumentupload/:id/:regId/:sn",
        name: "plinthdocumentupload",
        component: () =>
          import(
            /* webpackChunkName: "multiple" */ "../modules/plinthdocumentUpload/PlinthDocumentUploadTab.vue"
          ),
        props: true
      },
      {
        path: "superstructuraldocumentupload/:id/:regId/:sn",
        name: "superstructuraldocumentupload",
        component: () =>
          import(
            /* webpackChunkName: "multiple" */ "../modules/superstructuraldocumentUpload/SuperStructuralDocumentUploadTab.vue"
          ),
        props: true
      },
      {
        path: "/",
        redirect: { name: "dashboard" }
      },
      {
        path: "*",
        redirect: { name: "dashboard" }
      }
    ]
  }
  // {
  //   path: '/home/:id',
  //   name: 'home',
  //   component: Home,
  // },
];

const router = new VueRouter({
  routes
});

export default router;
