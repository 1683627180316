<style scoped>
.registrationtable td,
.registrationtable th {
  border: 1px solid #ddd;
  padding: 8px;
}

.document td,
.document th {
  padding: 8px;
}

.technicaltable td,
.technicaltable th {
  border: 1px;
  padding: 8px;
}
</style>
<template>
  <div id="app">
    <v-card class="d-flex flex-row md-3">
      <v-card class="d-flex flex-column col-6 md-3">
        <NibedanBibaran
          :regId="this.$route.params.id"
          :regPhaseId="this.results.phase_id"
          :regDeskId="this.results.desk_user_role_id"
          :isAddEditAuthorized="
            user_role_id == 2 && results.desk_user_role_id == user_role_id && results.phase_id != 5
          "
        />
      </v-card>
      <v-card class="d-flex flex-column col-6 md-3">
        <PrabhidhikBibaran
          :regId="this.$route.params.id"
          :regPhaseId="this.results.phase_id"
          :regDeskId="this.results.desk_user_role_id"
          :isAddEditAuthorized="
            user_role_id == 4 && results.desk_user_role_id == user_role_id && results.phase_id == 1
          "
        />
        <ReceivedCertificates :regId="this.$route.params.id" />
      </v-card>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import NibedanBibaran from "../subComponents/NibedanBibaran.vue";
import PrabhidhikBibaran from "../subComponents/PrabhidhikBibaran.vue";
import ReceivedCertificates from "../subComponents/ReceivedCertificates.vue";
// import RegistrationAction from '../subComponents/RegistrationAction.vue';
//   import Application from '../../../modules/tabbedElements/components/Application.vue';

export default {
  name: "Application",
  props: {
    nextStep: {
      type: Function
    },
    id: {
      type: Number
    },
    currentApplicationPhase: {
      type: [String, Number]
    },
    currentApplicationDesk: {
      type: [String, Number]
    }
  },
  components: {
    NibedanBibaran,
    PrabhidhikBibaran,
    ReceivedCertificates
  },
  mounted() {
    const url = `api/registration/${this.$route.params.id}`;
    VueAxios.get(url).then(response => {
      // debugger;
      this.results = response.data.data;
      // console.log(response.data.data);
    });
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      results: []
    };
  }
};
</script>
<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
</style>
