<template>
  <div>
    <v-card class="sober d-flex flex-column">
      <v-toolbar color="primary mt-2" dark flat>
        <v-toolbar-title> List </v-toolbar-title>
      </v-toolbar>
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead>
            <tr class="registrationtable">
              <th
                class="text-left registrationtable"
                v-for="titleList in selectedTitle"
                v-bind:key="titleList"
              >
                {{ titleList }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="registrationtable" v-for="valueList in values" v-bind:key="valueList">
              <td class="registrationtable" v-for="head in columnHeader" v-bind:key="head">
                <span v-if="href.includes(head)">
                  <a :href="basePath + valueList[head]" target="_blank">{{
                    valueList[head]
                  }}</a></span
                >
                <span v-else>{{ valueList[head] }}</span>
              </td>
            </tr>
            <!-- <tr class="registrationtable">
              <td class="registrationtable" v-for="valueList in values" v-bind:key="valueList">
                {{ valueList }}
              </td>
            </tr> -->
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
    <br />
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

export default {
  name: "Charkilla",

  props: {
    url: [Number, String],
    columnHeader: [Array],
    href: [Array],
  },

  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      title: [],
      userRoleId: localStorage.getItem("auth_user_role_id"),
      records: [],
      values: [],
    };
  },
  computed: {
    selectedTitle() {
      // debugger;
      return this.title.filter((t) => {
        return this.columnHeader.some((header) => t.includes(header.toLowerCase()));
      });
    },
  },
  created() {
    this.apiCall();
  },
  // mounted() {
  //   this.$root.$on("component1", () => {
  //     this.c1method();
  //   });
  // },
  methods: {
    apiCall() {
      const link = this.url;
      console.log(link);
      const obj = this;
      VueAxios.get(link)
        .then((response) => {
          obj.records = response.data.data;
          //   debugger;
          obj.values = obj.filterRows(obj.records, obj.columnHeader);
          obj.title = Object.keys(obj.records[0]);
        })
        .catch((error) => {
          // alert(3)
          console.log(`api error:${error}`);
        });
    },
    filterRows(rows, headers) {
      // Map over the full list of rows to filter down
      return rows.map((row) => {
        // Create a new row to return to avoid mutating
        const filteredRow = {};
        // Loop through each header and copy the row value corresponding to that header value
        headers.forEach((header) => {
          filteredRow[header] = row[header];
        });
        // Return the copied and filtered row
        return filteredRow;
      });
    },
  },
};
</script>

<style scoped>
#styled-input {
  height: 30px;
  font-size: 20pt;
}

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field.v-text-field.v-text-field--enclosed .v-input__slot {
  padding: 0;
}
label span {
  color: red;
}
</style>
