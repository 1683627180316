<template>
  <v-stepper>
    <v-stepper-header>
          <v-btn class="primary my-3 mx-3" :to="{ name: 'infrastructuredashboard' }">Back</v-btn>
      <v-divider></v-divider>
      <v-stepper-step step="IIS">
         <strong class="mx-3 bold-header">
        Infrastructure Inventory System Report
      </strong>
      </v-stepper-step>

      <v-divider></v-divider>
    </v-stepper-header>

    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12" color="grey lighten-1" height="800px">
          <l-map :zoom="zoom" :center="center" v-resize="onResize">
            <l-tile-layer :url="url" :attribution="attribution" />
            <l-geo-json
              v-if="show"
              :geojson="geojson"
              :options="options"
              :options-style="styleFunction"
            />
          </l-map></v-card
        >

      </v-stepper-content>

    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { VueAxios } from "@/plugins/axios";
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  // LMarker,
  LGeoJson,
} from "vue2-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";
import redicon from "leaflet/dist/images/marker-shadow.png"; // Change later

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow

  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 40], // the same for the shadow
});

let RedIcon = L.icon({
  iconUrl: icon, // redicon here haii ta
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow

  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 40], // the same for the shadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default {
  components: {
    LMap,
    LTileLayer,
    // LMarker,
    LGeoJson,
  },
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow

      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
      popupAnchor: [-3, -76], // point from which the po
    });
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      status: true,
      isPostAuthorized: true,
      isFormLoading: false,
      valid: false,
      requiredDropdown(propertyType) {
        return (v) => v || `${propertyType} is required`;
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      show: true,
      enableTooltip: true,
      zoom: 12,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      geojson: null,
      fillColor: "#4eaae6",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      marker: L.latLng(27.692149607288425, 86.02294921875001),

      color: { color: "#3F3C3C" },
      // wards: ["1", "2", "3", "4", "5", "6"],
      // coordinates: "",
      wardName: [],
      building: {
        ward: [],
        selectedBridge: [],
        selectedRetaining: [],
        selectedRoad: [],
        selectedOther: [],
        selectedWater: [],
        selectedElectrical: [],
      },

      Retaining: [],
      Bridge: [],
      Road: [],
      selectedRoad: [],
      Other: [],
      selectedOther: [],
      areaOfBuilding: [
        "less than 300 sqft.",
        "300-500 sqft.",
        "500-750 sqft.",
        "750-1000 sqft.",
        "more than 1000 sqft.",
      ],
      selectedAreaOfBuilding: [],
      Water: [],
      selectedWater: [],
      Electrical: [],
      selectedElectrical: [],
      reportTable: [],
      headers: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "Description",
        },
        { text: "Number/Length", value: "Number/Length" },
        { text: "Density", value: "Density" },
      ],
      chartsData: {
        roadChartData: [],
        waterChartData: [],
        bridgeChartData: [],
        retainingChartData: [],
        electricalChartData: [],
        otherChartData: [],
        ElectricalChartData: [],
      },
      retainingChartOptions: {
        title: "Building Road-wise Chart of various wards",
        height: 600,
        width: 800,
        bars: "horizontal", //for material design
      },
      roadChartOptions: {
        title: "Building permit-wise Chart of various wards",
        height: 600,
        width: 800,
      },
      waterChartOptions: {
        title: "Building Retaining-wise Chart of various wards",
        height: 600,
        width: 800,
      },
      bridgeChartOptions: {
        title: "Building Bridge-wise Chart of various wards",
        height: 600,
        width: 800,
      },
      electricalChartOptions: {
        title: "Building area-wise Chart of various wards",
        height: 600,
        width: 800,
      },
      otherChartOptions: {
        title: "Building roof-type-wise Chart of various wards",
        height: 600,
        width: 800,
      },
      ElectricalChartOptions: {
        title: "Building category-wise Chart of various wards",
        height: 600,
        width: 800,
      },
    };
  },
  created() {
    this.selectBoxApi();
  },
  mounted() {
    setTimeout(function () {
      //debugger;
      window.dispatchEvent(new Event("resize"));
      // this.marker = L.latLng(26.4525, 87.2718);
    }, 250);
  },
  computed: {
    // roadChartData: [],
    //     waterChartData: [],
    //     bridgeChartData: [],
    //     retainingChartData: [],
    //     electricalChartData: [],
    //     otherChartData: [],
    //     ElectricalChartData: []
    roadChartData() {
      return [...this.chartsData.roadChartData];
    },
    waterChartData() {
      return [...this.chartsData.waterChartData];
    },
    bridgeChartData() {
      return [...this.chartsData.bridgeChartData];
    },
    retainingChartData() {
      return [...this.chartsData.retainingChartData];
    },
    electricalChartData() {
      return [...this.chartsData.electricalChartData];
    },
    otherChartData() {
      return [...this.chartsData.otherChartData];
    },
    ElectricalChartData() {
      return [...this.chartsData.ElectricalChartData];
    },
    isLoading() {
      if (this.wardName.length === 0) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 0.5,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        layer.bindTooltip(
          "<div>name:" +
            feature.properties.name +
            // "</div><div>nom: " +
            // feature.properties.nom +
            "</div>",
          { permanent: false, sticky: true }
        );
      };
    },
  },
  methods: {
    OnStructuralChartDataReady(chart, google) {
      const saveUrl = `api/bids/report/building-search`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const options = {
          title: "Chart",
          is3D: true,
        };
        console.log(response.data.roadChartData);
        chart.draw(google.visualization.arrayToDataTable(response.data.roadChartData), options);
      });
    },
    selectBoxApi() {
      const obj = this;
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then((response) => {
          // debugger;
          obj.wardName = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const BridgeUrl = "api/bids/select-box/bridge";
      VueAxios.get(BridgeUrl)
        .then((response) => {
          // debugger;
          obj.Bridge = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const RoadUrl = "api/bids/select-box/road";
      VueAxios.get(RoadUrl)
        .then((response) => {
          // debugger;
          obj.Road = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const retainingUrl = "api/bids/select-box/retaining-works";
      VueAxios.get(retainingUrl)
        .then((response) => {
          // debugger;
          obj.Retaining = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const categoryUrl = "api/bids/select-box/electrical-infrastructure";
      VueAxios.get(categoryUrl)
        .then((response) => {
          // debugger;
          obj.Electrical = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      //Other
      //type of roof building-ceiling-material

      const roofUrl = "api/bids/select-box/water-supply-structure";
      VueAxios.get(roofUrl)
        .then((response) => {
          // debugger;
          obj.Water = response.data;
          // debugger;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const mapUrl = "api/bids/report/infrastructure-search";
      VueAxios.post(mapUrl)
        .then((response) => {
          // debugger;
          obj.geojson = response.data.mapdata;
          // debugger;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      // console.log(this.ward);

      // this.selectedIndex.push({ ward: this.ward, status: true });
      // const newData = [{ ward: this.ward, status: true }];
      // console.log(this.newData);
      const obj = this;
      let list = [];
      let selectedItem = [];
      // const formData = new FormData();

      // $.each(obj.building.ward, function(key, value) {
      //   console.log(key);
      //   // const  status = true;
      //   selectedItem = value;
      //   // const ward_no = selectItem;
      //   console.log(selectedItem);
      //   // console.log(this.value);
      //   obj.building.ward.push(selectedItem);
      //   // formData.append(selectedItem.ward_name, true);
      // });

      // console.log(obj.building.ward);debugger;
      // debugger;
      // this.list = []
      // this.ward.forEach(e => {
      //     this.list.push({ ward: e, status: true });
      //   });
      // alert(formData.toString());
      // console.log(list);
      // formData.append("_method", "POST");
      // const saveUrl = `/api/bids/infrastructure/building`;
      this.isFormLoading = true;
      const saveUrl = `api/bids/report/building-search`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          this.isFormLoading = false;
          obj.geojson = response.data.mapdata;
          obj.chartsData.roadChartData = response.data.roadChartData;
          obj.chartsData.waterChartData = response.data.waterChartData;
          obj.chartsData.bridgeChartData = response.data.bridgeChartData;
          obj.chartsData.retainingChartData = response.data.retainingChartData;
          obj.chartsData.electricalChartData = response.data.electricalChartData;
          obj.chartsData.otherChartData = response.data.otherChartData;

          // console.log(obj.coordinates);
        })
        .catch((error) => {
          // console.log(formData.toString());
        })
        .finally(() => {});

      const url = `api/bids/report/dashboard-table`;
      VueAxios.get(url).then((response) => {
        // debugger;
        obj.reportTable = response.data.data;
        // console.log(obj.reportTable);debugger;
      });
    },
    getBuildingExcelBackup() {
      // Axios.get(url, { responseType: 'blob' })
      //   .then(response => {
      //     const blob = new Blob([response.data], { type: 'application/xlsx' })
      //     const link = document.createElement('a')
      //     link.href = URL.createObjectURL(blob)
      //     link.download = label
      //     link.click()
      //     URL.revokeObjectURL(link.href)
      //   }).catch(console.error)
    },
  },
};
</script>

<style scoped>
.bold-header {
 font-size: 25pt;
color: black;
font-weight: bold;
}
</style>
