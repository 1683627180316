<template>
  <v-layout class="my-3">
    <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="true"></loading>
    <div style="width: 100%">
      <v-row dense>
        <v-btn class="primary my-3 mx-7" :to="{ name: 'inventorydashboard' }">Back</v-btn>
        <v-btn class="primary my-3" :href="basePath + `api/bids/report/excel`">
          Download Building Data in Excel
        </v-btn>
      </v-row>

      <v-space class="mx-4"></v-space>
      <v-flex class="elevation-12" dense>
        <v-card class="d-flex flex-row">
          <v-card class="d-flex flex-column col-3">
            <v-card class="nibedanBibaran elevation-12">
              <v-toolbar color="primary" dark>
                <v-toolbar-title> Analyse {{ list }}</v-toolbar-title>
              </v-toolbar>
              <v-form ref="form" class="mx-4 my-4" v-model="valid">
                Building Permit
                <v-radio-group dense v-model="building.building_permit" :mandatory="true">
                  <v-radio label="Yes" v-bind:value="true"></v-radio>
                  <v-radio label="No" v-bind:value="false"></v-radio>
                </v-radio-group>
                Ward Number
                <v-select
                  v-model="building.ward"
                  :items="wardName"
                  item-value="id"
                  :reduce="(ward_name) => ward_name.id"
                  item-text="ward_name"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>

                No. of storey
                <v-select
                  v-model="building.selectedStorey"
                  :items="storey"
                  @change="onchange()"
                  dense
                  multiple
                >
                </v-select>
                Year of Construction (BS)
                <v-select v-model="building.selectedYear" :items="year" dense multiple> </v-select>
                Typology
                <v-select
                  v-model="building.selectedTypology"
                  :items="typology"
                  item-value="id"
                  :reduce="(building_structure_type) => building_structure_type.id"
                  item-text="building_structure_type"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>

                Building Category acc. To NBC
                <v-select
                  v-model="building.selectedBuildingCategory"
                  :items="buildingCategory"
                  item-value="id"
                  :reduce="(building_category_type) => building_category_type.id"
                  item-text="building_category_type"
                  :key="id"
                  dense
                  multiple
                >
                </v-select>
                <br />
                <v-snackbar
                  v-model="snackbar.show"
                  :bottom="y === 'bottom'"
                  :color="snackbar.color"
                  :left="x === 'left'"
                  :multi-line="mode === 'multi-line'"
                  :right="x === 'right'"
                  :timeout="3000"
                  :top="y === 'top'"
                  :vertical="mode === 'vertical'"
                >
                  {{ successfulMessage }}
                </v-snackbar>
                <v-btn
                  primary
                  v-on:click="save"
                  v-if="isPostAuthorized"
                  class="primary mb-4"
                  :disabled="!valid"
                  >Submit</v-btn
                >
              </v-form>
            </v-card>
          </v-card>

          <v-card class="d-flex flex-column col-9">
            <v-card class="d-flex flex-row">
              <div class="col-md-12 map">
                <l-map :zoom="zoom" :center="center" v-resize="onResize">
                  <l-tile-layer :url="url" :attribution="attribution" />
                  <l-geo-json
                    v-if="show"
                    :geojson="geojson"
                    :options="options"
                    :options-style="styleFunction"
                  />
                </l-map>
              </div>
            </v-card>

            <!-- <v-toolbar-title class="text-center my-3">
              Building permit-wise Chart of various wards
            </v-toolbar-title> -->
            <v-card elevation="4" outlined shaped>
              <apexchart
                type="bar"
                height="350"
                class="spacing-playground pa-4"
                :options="permitChartOptions"
                :series="series"
              ></apexchart>
            </v-card>
            <v-divider></v-divider>

            <v-card elevation="4" outlined shaped>
              <apexchart
                type="bar"
                height="350"
                :options="storeyChartOptions"
                :series="series1"
                class="spacing-playground pa-4"
              ></apexchart>
            </v-card>
            <v-divider></v-divider>

            <v-card elevation="4" outlined shaped>
              <apexchart
                type="bar"
                height="350"
                :options="yearChartOptions"
                :series="series2"
                class="spacing-playground pa-4"
              ></apexchart>
            </v-card>
            <v-divider></v-divider>

            <v-card elevation="4" outlined shaped>
              <apexchart
                type="bar"
                height="350"
                :options="typologyChartOptions"
                :series="series3"
                class="spacing-playground pa-4"
              ></apexchart>
            </v-card>
            <v-divider></v-divider>

            <v-card elevation="4" outlined shaped>
              <apexchart
                type="bar"
                height="350"
                :options="buildingCategoryChartOptions"
                :series="series4"
                class="spacing-playground pa-4"
              ></apexchart>
            </v-card>
            <v-divider></v-divider>
          </v-card>
        </v-card>
      </v-flex>
    </div>
  </v-layout>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

import redicon from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow

  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 40], // the same for the shadow
});

let RedIcon = L.icon({
  iconUrl: icon, // redicon here
  shadowUrl: iconShadow,
  iconSize: [25, 41], // size of the icon
  shadowSize: [25, 41], // size of the shadow

  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  shadowAnchor: [9, 40], // the same for the shadow
});

L.Marker.prototype.options.icon = DefaultIcon;

export default {
  name: "ResultAnalysis",
  components: {
    LMap,
    LTileLayer,
    LGeoJson,
  },
  data() {
    return {
      basePath: process.env.VUE_APP_BASE_URL,
      status: true,
      isPostAuthorized: true,
      isFormLoading: false,
      valid: false,
      requiredDropdown(propertyType) {
        return (v) => v || `${propertyType} is required`;
      },
      successfulMessage: "",
      snackbar: {
        show: false,
        message: this.sucessfulMessage,
        color: "success",
      },
      show: true,
      enableTooltip: true,
      zoom: 11,
      center: L.latLng(28.539856291720323, 82.00828711109587),
      geojson: null,
      fillColor: "#4eaae6",
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      color: { color: "#3F3C3C" },
      wardName: [],
      building: {
        ward: [],
        building_permit: false,
        selectedYear: [],
        selectedStorey: [],
        selectedTypology: [],
        selectedIrregularity: [],
        selectedAreaOfBuilding: [],
        selectedTypeOfRoof: [],
        selectedBuildingCategory: [],
      },

      storey: [1, 2, 3, 4],
      year: ["1990-2050", "2050-2060", "2060-2070", "2070-2077"],
      typology: [],
      selectedTypology: [],
      irregularity: [],
      selectedIrregularity: [],
      areaOfBuilding: [
        "less than 300 sqft.",
        "300-500 sqft.",
        "500-750 sqft.",
        "750-1000 sqft.",
        "more than 1000 sqft.",
      ],
      selectedAreaOfBuilding: [],
      typeOfRoof: [],
      selectedTypeOfRoof: [],
      buildingCategory: [],
      selectedBuildingCategory: [],
      reportTable: [],
      headers: [
        {
          text: "Description",
          align: "start",
          sortable: false,
          value: "Description",
        },
        { text: "Number/Length", value: "Number/Length" },
        { text: "Density", value: "Density" },
      ],
      roofChartOptions: {
        title: "Building roof-type-wise Chart of various wards",
        height: 600,
        width: 800,
      },

      series: [
        {
          data: [],
        },
      ],

      permitChartOptions: {
        title: {
          text: "Building permit-wise Chart of various wards (Simta Rural Municipality)",
          align: "center",
          floating: true,
          style: {
            fontSize:18,
            fontWeight: 130,
          },
          offsetY: -7,
        },
        chart: {
          type: "bar",
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "10%",
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "15px",
            fontWeight: 130,
            colors: ["#000000"],
          },
          offsetY: -28,
        },
        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          labels: {
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 200,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            maxHeight: 120,
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },

        tooltip: {
          theme: "dark",
          x: {
            show: false,
          },
          y: {
            title: {
              formatter: function () {
                return "";
              },
            },
          },
        },
      },

      series1: [],

      storeyChartOptions: {
        title: {
          text: "Building storey-wise Chart of various wards (Simta Rural Municipality)",
          align: "center",
          floating: true,
          style: {
            fontSize:18,
            fontWeight: 130,
          },
          offsetY: -7,
        },
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "10%",
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "15px",
            fontWeight: 130,
            colors: ["#000000"],
          },
          offsetY: -28,
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },

      series2: [],

      yearChartOptions: {
        title: {
          text: "Building year-wise Chart of various wards (Simta Rural Municipality)",
          align: "center",
          floating: true,
          style: {
            fontSize:18,
            fontWeight: 130,
          },
          offsetY: -7,
        },
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: false,
            columnWidth: "10%",
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "15px",
            fontWeight: 130,
            colors: ["#000000"],
          },
          offsetY: -28,
        },

        colors: [
          "#33b2df",
          "#546E7A",
          "#d4526e",
          "#13d8aa",
          "#A5978B",
          "#2b908f",
          "#f9a3a4",
          "#90ee7e",
          "#f48024",
          "#69d2e7",
        ],

        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },

      series3: [],

      typologyChartOptions: {
        title: {
          text: "Building typology-wise Chart of various wards (Simta Rural Municipality)",
          align: "center",
          floating: true,
          style: {
            fontSize:18,
            fontWeight: 130,
          },
          offsetY: -7,
        },
        colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
          '#f48024', '#69d2e7'],
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: false,
            columnWidth: "10%",
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "15px",
            fontWeight: 130,
            colors: ["#000000"],
          },
          offsetY: -28,
        },

        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },

      series4: [],

      buildingCategoryChartOptions: {
        title: {
          text: "Building category-wise Chart of various wards (Simta Rural Municipality)",
          align: "center",
          floating: true,
          style: {
            fontSize:18,
            fontWeight: 130,
          },
          offsetY: -7,
        },
        chart: {
          type: "bar",
          height: 430,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: false,
            columnWidth: "10%",
            vertical: true,
            dataLabels: {
              position: "top",
            },
          },
        },

        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "15px",
            fontWeight: 130,
            colors: ["#000000"],
          },
          offsetY: -28,
        },

        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },

        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        tooltip: {
          shared: true,
          intersect: false,
        },
      },

      // series: [
      //   {
      //     data: [400, 230],
      //   },
      // ],
      // chartOptions: {
      //   chart: {
      //     type: "bar",
      //     height: 380,
      //   },
      //   plotOptions: {
      //     bar: {
      //       barHeight: "100%",
      //       distributed: true,
      //       horizontal: true,
      //       dataLabels: {
      //         position: "bottom",
      //       },
      //     },
      //   },
      //   // colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e',
      //   //   '#f48024', '#69d2e7'
      //   // ],
      //   colors: ["#33b2df", "#546E7A"],
      //   dataLabels: {
      //     enabled: true,
      //     textAnchor: "start",
      //     style: {
      //       colors: ["#fff"],
      //     },
      //     formatter: function (val, opt) {
      //       return opt.w.globals.labels[opt.dataPointIndex] + ":  " + val;
      //     },
      //     offsetX: 0,
      //     dropShadow: {
      //       enabled: true,
      //     },
      //   },
      //   stroke: {
      //     width: 1,
      //     colors: ["#fff"],
      //   },
      //   xaxis: {
      //     categories: ["नक्सा पास भएको", "नक्सा पास नभएको"],
      //   },
      //   yaxis: {
      //     labels: {
      //       show: false,
      //     },
      //   },
      //   title: {
      //     text: "Custom DataLabels",
      //     align: "center",
      //     floating: true,
      //   },
      //   subtitle: {
      //     text: "Building permit-wise Chart of various wards",
      //     align: "center",
      //   },
      //   tooltip: {
      //     theme: "dark",
      //     x: {
      //       show: false,
      //     },
      //     y: {
      //       title: {
      //         formatter: function () {
      //           return "";
      //         },
      //       },
      //     },
      //   },
      // },
    };
  },
  created() {
    this.selectBoxApi();
  },
  mounted() {
    setTimeout(function () {
      window.dispatchEvent(new Event("resize"));
    }, 250);
  },
  computed: {
    isLoading() {
      if (this.wardName.length === 0) {
        // all drop-down values not initiated from api
        return true;
      } else if (this.isFormLoading) {
        return true;
      }
      return false;
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          weight: 2,
          color: "#a8d8f7",
          opacity: 1,
          fillColor: fillColor,
          color: "#1c7dfc",
          fillOpacity: 0.5,
        };
      };
    },
    onEachFeatureFunction() {
      if (!this.enableTooltip) {
        return () => {};
      }
      return (feature, layer) => {
        if (feature.properties.houseowner) {
          layer.bindTooltip("<div>Name:" + feature.properties.houseowner + "</div>", {
            permanent: false,
            sticky: true,
          });
        }
      };
    },
  },
  methods: {
    OnStructuralChartDataReady(chart, google) {
      const saveUrl = `api/bids/report/building-search`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        const options = {
          title: "Chart",
          is3D: true,
        };
        console.log(response.data.permitChartData);
        chart.draw(google.visualization.arrayToDataTable(response.data.permitChartData), options);
      });
    },
    selectBoxApi() {
      const obj = this;
      const wardUrl = "api/bids/select-box/ward";
      VueAxios.get(wardUrl)
        .then((response) => {
          obj.wardName = response.data;
          console.log(obj.buildingPermanent);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const typologyUrl = "api/bids/select-box/building-structure";
      VueAxios.get(typologyUrl)
        .then((response) => {
          obj.typology = response.data;
          console.log(obj.buildingPermanent);
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const categoryUrl = "api/bids/select-box/building-category";
      VueAxios.get(categoryUrl)
        .then((response) => {
          obj.buildingCategory = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const roofUrl = "api/bids/select-box/building-ceiling-material";
      VueAxios.get(roofUrl)
        .then((response) => {
          obj.typeOfRoof = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });

      const mapUrl = "api/map";
      VueAxios.get(mapUrl)
        .then((response) => {
          obj.geojson = response.data;
        })
        .catch((error) => {
          console.log(`api error:${error}`);
        });
    },
    save() {
      if (this.building.building_permit) {
        L.Marker.prototype.options.icon = DefaultIcon;
      } else {
        L.Marker.prototype.options.icon = DefaultIcon;
      }

      const obj = this;

      this.isFormLoading = true;

      const saveUrl = `api/bids/report/building-search`;
      VueAxios.post(saveUrl, this.building, {
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          if (this.building.ward.length) {
            this.series[0].data = response.data.permitChartData.datas;
            this.permitChartOptions = {
              xaxis: {
                categories: response.data.permitChartData.ward,
              },
            };
          }

          if (this.building.selectedStorey.length) {
            this.series1 = response.data.storeyChartData;
            this.storeyChartOptions = {
              xaxis: {
                categories: response.data.storeyChartData[0].ward,
              },
            };
          }

          if (this.building.selectedYear.length) {
            this.series2 = response.data.yearChartData;
            this.yearChartOptions = {
              xaxis: {
                categories: response.data.yearChartData[0].ward,
              },
            };
          }

          if (this.building.selectedTypology.length) {
            this.typologyChartOptions = {
              colors: ["#CA3E47"],
            };
            this.series3 = response.data.typologyChartData;
            this.typologyChartOptions = {
              xaxis: {
                categories: response.data.typologyChartData[0].ward,
              },
            };
          }

          if (this.building.selectedBuildingCategory.length) {
            this.series4 = response.data.buildingCategoryChartData;
            this.buildingCategoryChartOptions = {
              xaxis: {
                categories: response.data.buildingCategoryChartData[0].ward,
              },
            };
          }

          this.isFormLoading = false;
          obj.geojson = response.data.mapdata;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    getBuildingExcelBackup() {},
  },
};
</script>
