import Vue from "vue";
import Selectbox from "@/modules/home/Selectbox.vue";
import InventoryMap from "@/modules/home/InventoryMap.vue";
import PhotographsUpload from "@/modules/home/PhotographsUpload.vue";
import Card from "@/modules/home/Card.vue";
import PhotoCard from "@/modules/home/PhotoCard.vue";
import Gallery from "@/modules/home/Gallery.vue";
import InfrastructurePhotoCard from "@/modules/home/InfrastructurePhotoCard.vue";
import GeneralInformation from "@/modules/BIDS/inventory/GeneralInformation.vue";
import ChartMap from "@/modules/BIDS/inventory/ChartMap.vue";
import BidReport from "@/modules/home/BidReport.vue";

Vue.component("selectbox", Selectbox);
Vue.component("inventory-map", InventoryMap);
Vue.component("photographs-upload", PhotographsUpload);
Vue.component("card", Card);
Vue.component("photo-card", PhotoCard);
Vue.component("gallery", Gallery);
Vue.component("infrastructure-photo-card", InfrastructurePhotoCard);
Vue.component("general-information", GeneralInformation);
Vue.component("bid-report", BidReport);
Vue.component("chart-map", ChartMap);
