<template>
  <v-app>
    <router-view> </router-view>
  </v-app>
</template>
<style>
.layout.wrap {
  background: #eee;
}
.layout.wrap a {
  text-decoration: none;
}
.map {
  height: 400px;
}
.logoutdiv {
  background: #337ab7;
  width: fit-content;
  padding: 2px 35px;
  border-radius: 0px 0px 20px 20px;
  margin-top: -24px;
  color: #fff;
}
.md2 {
  /* max-width: fit-content !important; */
  padding: 10px 22px;
  border-right: 1px solid #ddd;
}

/* .v-data-table table thead {
  background: #1b5693;
}
.v-data-table table thead th {
  color: #fff !important;
  font-size: 14px;
} */

/* .v-application--wrap {
  min-height: 100%;
} */

.content {
  border: 1px solid #ddd;
  padding: 20px;
}
.v-stepper__step--active {
  background: #497dc7;
}
.v-stepper__content {
  padding: 0 20px !important; /* Set some breathing gap for all the forms in stepper content */
}
a {
  text-decoration: none !important;
}

.left-logo {
  text-align: right !important;
  float: right;
  align-self: flex-end;
  margin-left: -96px;
}
</style>
<script>
export default {
  name: "App"

  //   beforeCreate: function () {
  //   if (!this.$session.exists()) {
  //     this.$router.push('/login');
  //   }
  // },
  // methods: {
  //   logout: function () {
  //     this.$session.destroy();
  //     this.$router.push('/login');
  //   }
  // }
};
</script>
