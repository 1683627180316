<template>
  <div>
    <v-card class="mb-12" color="sober" height="auto">
      <loading :active.sync="isLoading" :can-cancel="true" :is-full-page="false"></loading>
      <v-app id="inspire">
        <v-form class="sober" v-model="valid">
          <v-container class="content">
            <v-row>
              <div class="col-md-9 map">
                <l-map :zoom="zoom" :center="center" v-resize="onResize" :value="value">
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-marker :lat-lng="marker" :icon="defaultIcon"></l-marker>
                  <l-geo-json :geojson="geojson" :options="options"></l-geo-json>
                </l-map>
              </div>
            </v-row>
          </v-container>
        </v-form>
      </v-app>
    </v-card>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";

import L from "leaflet";
import { LMap, LTileLayer, LMarker, LGeoJson } from "vue2-leaflet";

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson
  },
  props: {
    value: {
      type: [String]
    }
    // coordinates: {
    //   type: [Object, Array]
    // },
  },
  name: "Login",
  data() {
    const myMarkerIcon = L.icon({
      iconUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png",
      shadowUrl: "https://unpkg.com/leaflet@1.6.0/dist/images/marker-shadow.png",
      iconSize: [25, 41], // size of the icon
      shadowSize: [25, 41], // size of the shadow

      iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
      shadowAnchor: [9, 41], // the same for the shadow
      popupAnchor: [-3, -76] // point from which the po
    });
    return {
      isLoading: false,
      valid: false,
      required(propertyType) {
        return v => (v && v.length > 0) || `${propertyType} is required`;
      },
      requiredDropdown(propertyType) {
        return v => v || `${propertyType} is required`;
      },

      zoom: 11,
      center: L.latLng(27.692149607288425, 86.02294921875001),
      url: "http://{s}.tile.osm.org/{z}/{x}/{y}.png",
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      defaultIcon: myMarkerIcon,
      marker: L.latLng(27.692149607288425, 86.02294921875001),

      geojson: {
        //     type: "FeatureCollection",
        // features: [
        //     {
        //         type: "Feature",
        //         geometry: {
        //             type: "Point",
        //             coordinates: [
        //                 23,
        //                 34
        //             ]
        //         },
        //         properties: {
        //             id: 1
        //         }
        //     },
        //     {
        //         type: "Feature",
        //         geometry: {
        //             type: "Point",
        //             coordinates: [
        //                85.4298,
        //                 27.6710
        //             ]
        //         },
        //         properties: {
        //             id: 5
        //         }
        //     },
        //     {
        //         type: "Feature",
        //         geometry: {
        //             type: "Point",
        //             coordinates: [
        //                 86.2646484375,
        //                 27.44979032978400113051975495181977748870849609375
        //             ]
        //         },
        //         properties: {
        //             "id": 7
        //         }
        //     }
        // ]
      },
      options: {
        style(feature) {
          switch (feature.properties.party) {
            case "Republican":
              return { color: "#04316a" };
            case "Democrat":
              return { color: "#006fb9" };
            case "Dolakha2":
              return { color: "#000" };
            case "Dolakha-ward":
              return { color: "#3F3C3C" };
            default:
              return { color: "#228b22" };
          }
        }
      }
    };
  },
  //   created() {
  //     this.getFiscalYear();
  //     this.constructionType();
  //   },
  mounted() {
    setTimeout(function() {
      //debugger;
      window.dispatchEvent(new Event("resize"));
      // this.marker = L.latLng(26.4525, 87.2718);
    }, 250);
  },
  watch: {
    value: {
      handler: function() {
        this.geojson = this.value;
        console.log(this.geojson);
        // debugger;
      }
    }
    // deep: true
  },
  methods: {
    // getDate() {},
    // getFiscalYear() {
    //   const fiscalUrl = "api/fiscal-year";
    //   VueAxios.get(fiscalUrl, {
    //     headers: {
    //       "X-Requested-With": "XMLHttpRequest",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`
    //     }
    //   })
    //     .then(response => {
    //       this.fiscalyear = response.data.data;
    //     })
    //     .catch(() => {});
    // },
    // constructionType() {
    //   const constructionurl = "api/construction-type";
    //   VueAxios.get(constructionurl, {
    //     headers: {
    //       "X-Requested-With": "XMLHttpRequest",
    //       Authorization: `Bearer ${localStorage.getItem("token")}`
    //     }
    //   })
    //     .then(response => {
    //       this.constructiontype = response.data;
    //     })
    //     .catch(() => {});
    // },
    // add(e) {
    //   // console.log(e.latlng);
    //   this.marker = L.latLng(e.latlng.lat, e.latlng.lng);
    //   this.registration.latitude = e.latlng.lat;
    //   this.registration.longitude = e.latlng.lng;
    // },
    // changeMarker() {
    //   this.marker = L.latLng(this.registration.latitude, this.registration.longitude);
    // },
    save() {
      // start the loading spinner
      this.isLoading = true;
      console.log(this.registration);
      const url = "api/registration";
      VueAxios.post(
        url,
        this.registration,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${localStorage.getItem("token")}`
          }
        },
        { timeout: 10000 }
      ).then(response => {
        this.isLoading = false;
        if (response.error != null) {
          this.message = response.error;
        } else {
          // this.token = response.data.access_token;
          // console.log(response.data.access_token);
          // localStorage.setItem('token', response.data.access_token);
          console.log(localStorage.getItem("token"));
          this.$router.push({ name: "home" });
        }
      });
    }
  }
};
</script>
<style scoped>
/* #styled-input {
  height: 30px;
  font-size: 20pt;
} */

.styled-input label[for] {
  height: 30px;
  font-size: 20pt;
}

.row,
.col {
  height: 58px;
}

.custom-text-field,
.v-text-field,
.v-text-field--enclosed,
.v-input__slot {
  padding: 0;
}
.v-text-field__details {
  margin: 0;
  padding: 0;
}
.map {
  width: 300px;
  height: 280px;
}
</style>
