var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('nav',[_c('v-toolbar',{staticClass:"primary",attrs:{"app":"","dark":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),(_vm.is_bps_access == 1)?_c('v-toolbar-title',{staticClass:"headline text-uppercase"},[_c('img',{attrs:{"src":require('@/assets/images/nepal-govt.png'),"width":"60px"}}),_vm._v(" भवन निर्माण अनुमति प्रकृया ")]):_vm._e(),_c('router-link',{staticClass:"white--text font-weight-thin text-xl-h5",attrs:{"to":{ name: 'inventorydashboard' }}},[_c('img',{attrs:{"src":require('@/assets/images/nepal-govt.png'),"width":"60px"}}),_vm._v(" Building & Infrastructure Inventory ")]),_c('v-spacer'),_c('v-menu',{attrs:{"transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_vm._l((_vm.getMenu.filter(function (item) { return item.name === 'report'; })),function(item){return _c('v-btn',_vm._g(_vm._b({key:item.link,staticClass:"primary right m-2 subtitle-1",attrs:{"large":"","depressed":"","to":{ name: item.link }}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])}),_vm._l((_vm.getMenu.filter(function (item) { return item.name === 'dashboard'; })),function(item){return _c('v-btn',_vm._g(_vm._b({key:item.link,staticClass:"primary right m-2 subtitle-1",attrs:{"large":"","depressed":"","to":{ name: item.link }}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(item.title)+" ")])})]}}])}),_vm._l((_vm.getMenu.filter(function (item) { return item.name === 'application_status'; })),function(item){return _c('v-btn',{key:item.link,staticClass:"hidden-sm-and-down",attrs:{"text":""}},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: item.link }}},[_vm._v("Application Status")])],1)}),_vm._l((_vm.getMenu.filter(function (item) { return item.name === 'municipality'; })),function(item){return _c('v-btn',{key:item.link,staticClass:"hidden-sm-and-down",attrs:{"text":""}},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: item.link }}},[_vm._v("Municipality")])],1)}),_vm._l((_vm.getMenu.filter(function (item) { return item.name === 'consultancy'; })),function(item){return _c('v-btn',{key:item.link,staticClass:"hidden-sm-and-down",attrs:{"text":""}},[_c('router-link',{staticClass:"link",attrs:{"to":{ name: item.link }}},[_vm._v("Consultancy")])],1)}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([(_vm.is_bps_access == 1)?{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mx-4",attrs:{"size":"36","dark":""}},on),[_c('v-icon',{attrs:{"large":""},on:{"click":function($event){return _vm.onClick()}}},[_vm._v("mdi-email")])],1)]}}:null],null,true),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[(_vm.is_bps_access == 1)?_c('v-list',_vm._l((_vm.messages),function(message){return _c('v-list-item',{key:message.registration.reg_id,attrs:{"to":{
                name: 'application_tab',
                params: { id: message.reg_id }
              }}},[_c('v-list-item-avatar',_vm._g({attrs:{"size":"24","dark":""}},_vm.on),[_c('v-icon',{attrs:{"medium":""}},[_vm._v("mdi-email")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("File of "+_vm._s(message.registration.houseowner_name_np)+" ")]),_c('v-list-item-subtitle',[_c('strong',[_vm._v(" Message from "+_vm._s(message.created_by.username)+": "+_vm._s(message.message)+" ")])])],1)],1)}),1):_vm._e()],1)],1),_c('v-spacer'),_c('v-menu',{attrs:{"open-on-hover":"","close-on-content-click":false,"nudge-width":200,"offset-y":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"mx-4 info",attrs:{"size":"36","dark":""}},on),[_vm._v(" "+_vm._s(_vm.usernameInitials)+" ")])]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',_vm._g({staticClass:"info",attrs:{"size":"36","dark":""}},_vm.on),[_vm._v(" "+_vm._s(_vm.usernameInitials)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.userFullName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"error",attrs:{"dark":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)],1)],1)],2),_c('v-navigation-drawer',{staticClass:"primary accent-4",attrs:{"app":"","left":"","dark":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('div',{staticClass:"pa-2"},[_c('v-btn',{staticClass:"error",attrs:{"block":""},on:{"click":_vm.logout}},[_vm._v("Logout")])],1)]},proxy:true}]),model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-flex',[_c('v-flex',{staticClass:"justify-start"},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}),_c('img',{staticClass:"mx-9 my-3",attrs:{"src":require('@/assets/images/nepal-govt.png'),"width":"100px"}})],1),(_vm.is_bps_access == 1)?_c('v-list-item-title',{staticClass:"text-white mx-9"},[_vm._v(" भवन निर्माण अनुमति प्रकृया ")]):_c('v-list-item-title',{staticClass:"text-white mx-9"},[_vm._v(" Building Inventory System ")])],1),_c('v-divider',{attrs:{"color":"white"}}),_c('v-list',_vm._l((_vm.getMenu),function(item){return _c('v-list-item',{key:item.link,attrs:{"to":{ name: item.link }}},[_c('v-list-item-icon',[_c('v-icon',_vm._g({},_vm.on),[_vm._v(" mdi-"+_vm._s(item.icon)+" ")])],1),_vm._v(" "+_vm._s(item.title)+" ")],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }