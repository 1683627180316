<template>
  <div class="mar-btm">
    <v-toolbar color="primary" dark>
      <v-toolbar-title> Building Details </v-toolbar-title>
    </v-toolbar>
    <v-simple-table class="sober elevation-2">
      <thead>
        <tr class="">
          <th class="font-size">Name</th>
          <th class="font-size">Actions</th>
        </tr>
      </thead>
      <tbody >
        <tr v-for="item in nibedanBibarans" :key="item.name" >
          <td class="font-size">{{ item.name }}</td>
          <td class="font-size">
            <router-link
              :to="{
                name: 'inventorytabbedelements',
                params: { id: item.id, regId: regId, sn: item.sn },
              }"
              >{{ actionButtonText }}</router-link
            >
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  name: "NibedanBibaran",
  props: {
    regId: {
      type: Number,
    },
    // isAddEditAuthorized: {
    //   type: Boolean
    // }
  },
  computed: {
    actionButtonText() {
      if (this.isAddEditAuthorized) {
        return "Add/Edit";
      }
      return "View";
    },
  },
  data() {
    return {
      user_role_id: localStorage.getItem("auth_user_role_id"),
      registrationStatus: [],
      isAddEditAuthorized: true,
      nibedanBibarans: [
        {
          id: 1,
          name: " General Information",
          nameEng: "generalInfo",
          action: "Add/Edit",
          status: "",

          sn: 1,
        },
        {
          id: 2,
          name: "Architectural Information",
          nameEng: "architecturalInformation",
          action: "Add/Edit",
          status: "",
          sn: 2,
        },
        {
          id: 3,
          name: "Structural Information",
          nameEng: "structuralInformation",
          action: "Add/Edit",
          status: "",
          sn: 3,
        },
        {
          id: 4,
          name: "Building Occupancy",
          nameEng: "buildingOccupancy",
          action: "Add/Edit",
          status: "",
          sn: 4,
        },
        {
          id: 5,
          name: "Photographs Upload",
          nameEng: "photographsUpload",
          action: "Add/Edit",
          status: "",
          sn: 5,
        },
      ],
    };
  },
  mounted() {
    // const statusUrl = `api/registration/${this.regId}/status`;
    const temp = this.nibedanBibarans;
    temp.forEach((item) => {
      this.nibedanBibarans[item.sn - 1].status = this.registrationStatus[item.nameEng];
    });
  },
};
</script>

<style scoped>
.font-size {
  font-size: 1.2rem !important;
}
.mar-btm {
  margin-bottom: 25px;
}
</style>
