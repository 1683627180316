<template>
  <div id="app">
    <v-container-fluid grid-list-md text-xs-center>
      <v-fade-transition mode="out-in">
        <!-- <v-row>
          <v-col
            v-for="n in 4"
            :key="n"
            class="d-flex child-flex"
            cols="3"
          >
            <v-img
              :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
              :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
              height="250"
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
                >
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
        </v-row> -->

        <v-row>
          <v-col cols="4">
            <v-card :to="{ name: 'infrastructuredashboard' }">
              <v-img
                :src="require('@/assets/images/simtabridge.jpg')"
                height="250"
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6 justify-center">
                <v-btn class="ma-2" outlined color="primary">
                  Infrastructure Inventory System
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card :to="{ name: 'infrastructureBridge', params: { id: 'road', regId: 0 }  }">
              <v-img
                :src="require('@/assets/images/bridge.png')"
                height="250"
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6 justify-center">
                <v-btn class="ma-2" outlined color="primary">
                  Bridge Inventory System
                </v-btn>
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="4">
            <v-card :to="{ name: 'inventorydashboard' }">
              <v-img
                :src="require('@/assets/images/city.png')"
                height="250"
                class="grey darken-4"
              ></v-img>
              <v-card-title class="text-h6 justify-center">
                <v-btn class="ma-2" outlined color="primary"> Building Inventory System </v-btn>
              </v-card-title>
            </v-card>
          </v-col>
        </v-row>
      </v-fade-transition>
      <v-divider class="mx-4"></v-divider>
      <br />
      <v-simple-table class="sober">
        <template v-slot:default>
          <thead class="elevation-1 header">
            <tr class="registrationtable">
              <th
                class="text-uppercase text-white text-lg-h6"
                v-for="p in applicationHeader"
                :key="p"
              >
                {{ p }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              class="registrationtable"
              v-for="applicationStatusList in applicationStatus"
              :key="applicationStatusList.id"
            >
              <td class="registrationtable py-1 px-4">
                <p v-html="applicationStatusList['Description']"></p>
              </td>
              <td class="registrationtable">{{ applicationStatusList["Number/Length"] }}</td>
              <td class="registrationtable">{{ applicationStatusList["Density"] }}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <v-divider class="mx-4"></v-divider>
      <br /><br />
      <v-layout row wrap>
        <v-flex xs12 md6 mb-6>
          <v-card class="mx-auto" max-width="800">
            <v-card-text>
              <div class="text--primary">
                <apexchart
                  type="bar"
                  height="400"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <v-flex xs12 md6>
          <v-card class="mx-auto" max-width="800">
            <v-card-text>
              <div class="text--primary">
                <apexchart
                  type="bar"
                  height="400"
                  :options="chartOptions2"
                  :series="series2"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>

        <v-flex xs12 md6 mb-6>
          <v-card class="mx-auto" max-width="800">
            <v-card-text>
              <div class="text--primary">
                <apexchart
                  type="bar"
                  height="400"
                  :options="chartOptions3"
                  :series="series3"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
        <!-- <v-flex xs12 md6>
          <v-card class="mx-auto" max-width="800">
            <v-card-text>
              <div class="text--primary">
                <apexchart
                  type="bar"
                  height="400"
                  :options="chartOptions4"
                  :series="series4"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex> -->
        <v-flex xs12 md6>
          <v-card class="mx-auto" max-width="800">
            <v-card-text>
              <div class="text--primary">
                <apexchart
                  type="bar"
                  height="400"
                  :options="chartOptions5"
                  :series="series5"
                ></apexchart>
              </div>
            </v-card-text>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container-fluid>
  </div>
</template>
<script>
import { VueAxios } from "@/plugins/axios";
export default {
  data() {
    return {
      applicationStatus: "",
      applicationHeader: "",
      series: [
        {
          data: [],
        },
      ],
      series1: [
        {
          data: [],
        },
      ],
      series2: [
        {
          data: [],
        },
      ],
      series3: [
        {
          data: [],
        },
      ],
      series4: [
        {
          data: [],
        },
      ],
      series5: [
        {
          data: [],
        },
      ],

      chartOptions: {},
      chartOptions1: {},
      chartOptions2: {},
      chartOptions3: {},
      chartOptions4: {},
      chartOptions5: {},

      is_bps_access: window.localStorage.getItem("is_bps_access"),
      is_bids_access: window.localStorage.getItem("is_bids_access"),
      reveal: false,
    };
  },
  created() {
    this.getBuildingStorey();
    this.getBuildingStructure();
    this.getBuildingPermit();
    this.getBuildingAge();
    this.getBuildingUse();
  },
  mounted() {
    const url = `api/bids/report/dashboard-table`;
    VueAxios.get(url)
      .then((response) => {
        this.applicationStatus = response.data.data;
        this.applicationHeader = Object.keys(this.applicationStatus[0]);
        console.log(this.applicationHeader);
      })
      .catch((error) => {
        console.log(`api error:${error}`);
      });
  },

  methods: {
    getBuildingStorey() {
      const colors = ["#0277bd"];
      this.chartOptions = {
        xaxis: {
          categories: ["1 Storey", "2 Storey", "3 Storey", "More than 4"],
          labels: {
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 200,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 200,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          title: {
            text: "Number of Building",
            style: {
              fontSize: "15px",
              fontWeight: 700,
            },
            offsetX: -5,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "30",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontWeight: 200,
            colors: ["#000000"],
          },
          offsetY: -20,
        },
        title: {
          text: "Building Storey wise chart",
          align: "center",
          floating: true,
          style: {
            fontSize: "20px",
            fontWeight: 700,
          },
          offsetY: -8,
        },
        legend: {
          onItemClick: {
            toggleDataSeries: true,
          },
        },
      };

      VueAxios.get(`api/bids/report/dashboard-building-storey`).then((response) => {
        const building_storey = response.data;
        this.series = [
          {
            data: building_storey,
          },
        ];
      });
    },

    getBuildingAge() {
      const colors = ["#0277bd"];
      this.chartOptions3 = {
        xaxis: {
          categories: ["1 Storey", "2 Storey", "3 Storey", "More than 4"],
          labels: {
            style: {
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          title: {
            text: "Number of Building",
            style: {
              fontSize: "15px",
              fontWeight: 600,
            },
            offsetX: -5,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "30",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontWeight: 200,
            colors: ["#000000"],
          },
          offsetY: -20,
        },
        title: {
          text: "Building Age chart",
          align: "center",
          floating: true,
          style: {
            fontSize: "20px",
            fontWeight: 700,
          },
          offsetY: -8,
        },
      };

      VueAxios.get(`api/bids/report/dashboard-building-age`).then((response) => {
        const building_age = response.data;
        this.series3 = [
          {
            data: building_age,
          },
        ];
        this.chartOptions3 = {
          xaxis: {
            categories: [
              "Before 2050 BS",
              "2050 BS - 2060 BS",
              "2060 BS - 2070 BS",
              "2070 BS - Present",
            ],
          },
        };
      });
    },

    getBuildingStructure() {
      const colors = [];
      this.chartOptions5 = {
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            minHeight: 190,
            maxHeight: 130,
            trim: true,
            fontSize: "20px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 400,
            cssClass: "apexcharts-xaxis-label",
            formatter: function (val) {
              return val + "...";
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          title: {
            text: "Structures",
            style: {
              fontSize: "15px",
              fontWeight: 700,
            },
            offsetX: -5,
          },
        },
        title: {
          text: "Building Structure wise chart",
          align: "center",
          floating: true,
          style: {
            fontSize: "20px",
            fontWeight: 700,
          },
          offsetY: -8,
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "30",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontWeight: 200,
            colors: ["#000000"],
          },
          offsetY: -20,
        },
        stroke: {
          show: true,
          width: 1,
          colors: ["#fff"],
        },
        legend: {
          show: false,
        },
      };

      VueAxios.get(`api/bids/report/dashboard-building-structure`).then((response) => {
        const building_structure = response.data.datas;
        const building_structure_types = response.data.types;
        this.series5 = [
          {
            data: building_structure,
          },
        ];
        this.chartOptions5 = {
          xaxis: {
            categories: building_structure_types,
          },
        };
      });
    },

    getBuildingUse() {
      const colors = [];
      this.chartOptions4 = {
        xaxis: {
          categories: [],
          labels: {
            show: true,
            rotate: -45,
            rotateAlways: true,
            minHeight: 190,
            maxHeight: 130,
            trim: true,
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          title: {
            text: "Number of Building",
            style: {
              fontSize: "20px",
              fontWeight: 700,
            },
            offsetX: -5,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "30",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontWeight: 200,
            colors: ["#000000"],
          },
          offsetY: -20,
        },
        title: {
          text: "Building Use chart",
          align: "center",
          floating: true,
          style: {
            fontSize: "20px",
            fontWeight: 700,
          },
        },
      };

      VueAxios.get(`api/bids/report/dashboard-building-use`).then((response) => {
        const building_age = response.data.datas;
        const building_age_type = response.data.types;

        this.series4 = [
          {
            data: building_age,
          },
        ];
        this.chartOptions4 = {
          xaxis: {
            categories: building_age_type,
          },
        };
      });
    },

    getBuildingPermit() {
      const colors = [
        "#33b2df",
        "#546E7A",
        "#d4526e",
        "#13d8aa",
        "#A5978B",
        "#2b908f",
        "#f9a3a4",
        "#90ee7e",
        "#f48024",
        "#69d2e7",
      ];
      this.chartOptions2 = {
        xaxis: {
          categories: ["No. of Buildings with Permit", "No. of Buildings without Permit"],
          labels: {
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
        },
        yaxis: {
          labels: {
            show: true,
            style: {
              fontSize: "15px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              cssClass: "apexcharts-xaxis-label",
            },
          },
          title: {
            text: "Number of Building",
            style: {
              fontSize: "15px",
              fontWeight: 700,
            },
            offsetX: -5,
          },
        },
        plotOptions: {
          bar: {
            barHeight: "100%",
            vertical: true,
            distributed: true,
            columnWidth: "30",
            dataLabels: {
              position: "top",
            },
          },
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            return val;
          },
          style: {
            fontSize: "12px",
            fontWeight: 200,
            colors: ["#000000"],
          },
          offsetY: -20,
        },
        title: {
          text: "Building Permit wise chart",
          align: "center",
          floating: true,
          style: {
            fontSize: "20px",
            fontWeight: 700,
          },
          offsetY: -8,
        },
      };

      VueAxios.get(`api/bids/report/dashboard-building-permit`).then((response) => {
        const building_permit = response.data;
        this.series2 = [
          {
            data: building_permit,
          },
        ];
      });
    },
  },
};
</script>

<style>
.v-sheet--offset {
  top: -24px;
  position: relative;
}
.bps {
  margin-left: 30px;
}

.registrationtable {
  font-size: 1.2rem !important;
}

.header {
  background: #0277bd;
}

.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.layout.wrap {
  background: #fff;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>
